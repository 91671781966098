import React, { Component } from 'react'
import { Field } from 'redux-form'
import TextInput from './textInput'

class TextFormatInput extends Component {
  render() {
    if (this.props.source) {
      return <Field {...this.props} label={this.props.label} component={TextInput} name={this.props.source} />
    } else {
      return <TextInput {...this.props} />
    }
  }
}

export default TextFormatInput
