import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import { DisabledInput, maxLength, NumberInput, required, TextInput } from 'react-admin'

import { DateSingleInput, DictionaryInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'

export const SeasonsForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DateSingleInput label="Start" source="start" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DateSingleInput label="End" source="end" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <NumberInput fullWidth label="Position" source="position" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DisabledInput fullWidth label="Season" source="season" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DisabledInput fullWidth label="Year" source="year" />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DictionaryInput label="Dictionary" sources={['ldeId', 'optaId', 'managerId']} />
        </Paper>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default SeasonsForm
