import React from 'react'
import { Edit } from 'react-admin'
import SponsoringsForm from './form'

const SponsoringsTitle = ({ record }) => {
  return <span>{`Sponsorings: ${record.nombre}`}</span>
}

export const SponsoringsEdit = (props) => (
  <Edit undoable={false} title={<SponsoringsTitle />} {...props}>
    <SponsoringsForm {...props} isEdit={true} />
  </Edit>
)

export default SponsoringsEdit
