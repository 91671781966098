import React from 'react'
import { Edit } from 'react-admin'

import CompetitionsForm from './form'

export const TeamsEdit = (props) => (
  <Edit undoable={false} {...props}>
    <CompetitionsForm {...props} />
  </Edit>
)

export default TeamsEdit
