import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  CloneButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  Responsive,
  SimpleList,
  TextField,
  TextInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  SelectInput,
  ReferenceInput,
  Pagination,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { MetadataInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import { withStyles } from '@material-ui/core/styles'

import ImageVideoField from '../videos/commons/ImageListField'

const styles = () => ({
  breakWord: {
    wordBreak: 'break-all',
    minWidth: '200px',
    display: 'inline-block',
  },
})

const VideosFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12}>
          <TextInput label="Search" source="q" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectInput
            fullWidth
            alwaysOn
            allowEmpty
            source="state"
            choices={[
              {
                id: 'published',
                name: 'published',
              },
              {
                id: 'draft',
                name: 'draft',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput
            source="category"
            label="Category"
            reference={getResource('assets', 'videocategories')}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={99}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput source="clientUuid" label="Clients" reference={getResource('transversal', 'clientuuids')} alwaysOn allowEmpty fullWidth>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} alwaysOn>
          <MetadataInput source="metadatas" label="Metadatas" alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => <ListActions {...props} filters={<VideosFilter />} />

export const VideoPremiumList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Video Premium" filter={{ isPrivate: 1 }} {...props}>
      <Responsive
        medium={
          <SimpleList
            leftAvatar={(record) => <ImageVideoField record={record} {...props} />}
            primaryText={(record) => <span className={props.classes.breakWord}>{record.name}</span>}
            secondaryText={(record) => record.category && `Category: ${record.category.name}`}
            tertiaryText={(record) => `State: ${record.state}`}
          />
        }
        large={
          <Datagrid>
            <ImageVideoField label="Image" />
            <FunctionField
              source="name"
              label="Name"
              className={props.classes.breakWord}
              render={(record) => (
                <Link className={props.classes.breakWord} to={`${props.basePath}/${record.id}`}>
                  {record.name}
                </Link>
              )}
            />
            <TextField source="category.name" label="Category" sortable={false} />
            <DateField source="createdAt" label="Created At" />
            <DateField source="publishedAt" label="Published At" />
            <TextField source="state" label="State" />
            <ArrayField source="videoLanguages" label="Available in" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="language" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="markets" label="Markets" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="market" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="clientUuids" label="Clients" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default withStyles(styles)(VideoPremiumList)
