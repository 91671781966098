import React, { Component } from 'react'
import PersonPluginEdit from './personPluginEdit'
import Plugin from '../plugin'

class personPlugin extends Component {
  render() {
    return <Plugin {...this.props} name="personPlugin" component={PersonPluginEdit} />
  }
}

export default personPlugin
