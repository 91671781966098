import { Button, Chip, FormControl, InputLabel, Menu, MenuItem, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useState } from 'react'
import { ReferenceField } from 'react-admin'

import { getResource } from '../../../config/resources'

const styles = {
  rowBalls: {
    display: 'flex',
    width: '100%',
  },
  selectedBalls: {
    flex: '1 1 auto',
    '& > div': {
      marginBottom: '4px',
      marginRight: '4px',
    },
  },
  action: {
    '& > button': {
      padding: 0,
    },
  },
}

const ChipBall = ({ record, onDelete }) => {
  return <Chip label={`${record.name} - ${record.version}`} onDelete={onDelete} />
}

const Balls = ({ classes, label, input, choices = false }) => {
  const { value, onChange } = input
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDelete = (index) => {
    let newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  const handleAdd = (ball) => {
    let newValue = [...value]
    newValue.push({
      ball: ball,
    })
    onChange(newValue)
    setAnchorEl(null)
  }

  const handleClickAdd = (event) => {
    setAnchorEl(event.currentTarget)
  }

  let buttonDisabled = false
  if (value) {
    if (value.length >= choices.length) {
      buttonDisabled = true
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <div className={classes.rowBalls}>
        <div className={classes.selectedBalls}>
          {value &&
            value.map((item, key) => {
              return (
                <ReferenceField
                  source="ball.id"
                  reference={getResource('competiciones', 'balls')}
                  basePath={getResource('competiciones', 'balls')}
                  linkType={false}
                  record={item}
                  key={key}
                >
                  <ChipBall onDelete={() => handleDelete(key)} />
                </ReferenceField>
              )
            })}
        </div>
        <div className={classes.action}>
          <Button
            onClick={handleClickAdd}
            disabled={buttonDisabled}
            variant="contained"
            size="small"
            color="primary"
            aria-label="Add"
            aria-owns={anchorEl ? `menu-${input.name}` : null}
            aria-haspopup="true"
          >
            <AddIcon size="small" />
          </Button>
          <Menu id={`menu-${input.name}`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {choices &&
              choices.map((item, key) => {
                if (!value.some((element) => element.ball.id === item.id)) {
                  return (
                    <MenuItem onClick={() => handleAdd(item)} key={key}>
                      {`${item.name} - ${item.version}`}
                    </MenuItem>
                  )
                } else {
                  return false
                }
              })}
          </Menu>
        </div>
      </div>
    </FormControl>
  )
}

export default withStyles(styles)(Balls)
