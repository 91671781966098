import Grid from '@material-ui/core/Grid'
import React from 'react'
import { DisabledInput, maxLength, required, TextInput, LongTextInput } from 'react-admin'

import LanguagesNav from '../../../../components/languagesNav'
import { DndBlockEditor } from '../../../../components/inputs'
import { ImagePlugin, TablePlugin, TextPlugin } from '../../../../components/inputs/dndBlockEditor/plugins'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Name" source="name" />
      </Grid>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth label="SEO Title" source="title" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput fullWidth label="SEO Description" source="seoDescription" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <DndBlockEditor
          label="Content"
          onChange={(value) => null}
          name="contentBlocks"
          plugins={{
            image: {
              plugin: ImagePlugin,
              options: {
                name: 'Bloque imagen',
                removeClean: true,
                reference: true,
                readOlny: true,
              },
            },
            text: {
              plugin: TextPlugin,
              options: {
                name: 'Bloque texto',
                removeClean: true,
              },
            },
            table: {
              plugin: TablePlugin,
              options: {
                name: 'Bloque tabla',
                removeClean: true,
              },
            },
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
