import React from 'react'
import Grid from '@material-ui/core/Grid'
import { List, SimpleList, Filter, TextField, Responsive, TextInput, EditButton, Datagrid } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { DateField, ColorField } from '../../../components/field'

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <Grid container alwaysOn>
      <Grid item xs={12}>
        <TextInput source="q" label="Search" alwaysOn fullWidth />
      </Grid>
    </Grid>
  </Filter>
)

const Actions = (props) => {
  return <ListActions {...props} filters={<CategoriesFilter />} />
}

export const CategoriesList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Categories" {...props}>
    <Datagrid>
      <ColorField source="color" label="Color" />
      <TextField source="name" label="Name" />
      <DateField source="createdAt" />
      <TableRowList>
        <EditButton />
      </TableRowList>
    </Datagrid>
  </List>
)

export default CategoriesList
