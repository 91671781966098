import React from 'react'
import { List, Datagrid, TextField, EditButton, Filter, TextInput } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'

const BrandSectorsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Nombre" source="name" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<BrandSectorsFilter />} />
}

export const BrandSectorsList = (props) => {
  return (
    <List bulkActions={false} title="Digital Assets" {...props} actions={<Actions />}>
      <Datagrid>
        <TextField source="name" label="Name" />
        <TableRowList>
          <EditButton />
        </TableRowList>
      </Datagrid>
    </List>
  )
}

export default BrandSectorsList
