import styled from 'styled-components'
import theme from '../../../../config/theme'

export const BackgroundGreyStyled = styled.div`
  background: ${theme.palette.grey[300]};
`

export const LabelStyled = styled.div`
  width: 100;
  font-family: ${theme.typography.fontFamily};
  padding-left: 10px;
  margin: 10px 0;
  font-size: 0.9rem;
`
