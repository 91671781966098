import { Grid } from '@material-ui/core'
import React from 'react'
import { maxLength, required, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin'
import { getResource } from '../../../../config/resources'
import { LanguagesNav } from '../../../../components'
import { ImageInput, MetadataInput, RichTextInput } from '../../../../components/inputs'

const metaDataBlockOptions = [
  { type: 'match', name: 'Match' },
  { type: 'person', name: 'Player' },
  { type: 'team', name: 'Team' },
]

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput
          label="Category"
          source="category.id"
          reference={getResource('layouts', 'beyond-stats-categories')}
          validate={[required()]}
          perPage={100}
        >
          <SelectInput />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BooleanInput label="Active" source="active" />
      </Grid>
      <Grid item xs={12}>
        <MetadataInput source="metadatas" label="Metadata" types={metaDataBlockOptions} />
      </Grid>

      <Grid item xs={12}>
        <TextInput fullWidth source="title" label="Title" validate={[required(), maxLength(255)]} />
      </Grid>

      <Grid item xs={12}>
        <RichTextInput fullWidth source="description" label="Description" validate={[required()]} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageInput label="Image" source="image" options={{ remove: true }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageInput
          label="Video"
          source="video"
          options={{
            remove: true,
            type: 'videos',
            filters: {
              state: 'published',
            },
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
