import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'

import { getResource } from '../../../../config/resources'
import MetadataInput from '../../metadataInput/metadata'

const SEASON = 'season'
const COMPETITION = 'competition'
const SUBSCRIPTION = 'subscription'
const ROUND = 'round'
const GAMEWEEK = 'gameweek'
const MATCH = 'match'

class MatchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: null,
      perPage: 50,
    }
  }

  onChange(metadatas, type) {
    const { onChange } = this.props.input ? this.props.input : this.props
    const newMetadata = this.resetSelects(metadatas, type)
    onChange(newMetadata)
  }

  resetSelects(metadatas, type) {
    let typeToRemove = []
    switch (type) {
      case SEASON:
        typeToRemove = [ROUND, SUBSCRIPTION, GAMEWEEK, MATCH]
        break
      case COMPETITION:
        typeToRemove = [ROUND, SUBSCRIPTION, GAMEWEEK, MATCH]
        break
      case SUBSCRIPTION:
        typeToRemove = [ROUND, GAMEWEEK, MATCH]
        break
      case ROUND:
        typeToRemove = [GAMEWEEK, MATCH]
        break
      case GAMEWEEK:
        typeToRemove = [MATCH]
        break
    }

    return _.differenceWith(metadatas, typeToRemove, (arrVal, othVal) => {
      return arrVal.type === othVal
    })
  }

  getFromMetadata(type) {
    const { value } = this.props.input ? this.props.input : this.props
    const selectedItem = _.find(value, (item) => {
      return item.type === type
    })
    return selectedItem ? selectedItem.value : null
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    const { sourceMatch } = this.props
    const season = this.getFromMetadata(SEASON)
    const competition = this.getFromMetadata(COMPETITION)
    const subscription = this.getFromMetadata(SUBSCRIPTION)
    const round = this.getFromMetadata(ROUND)
    const gameweek = this.getFromMetadata(GAMEWEEK)

    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <MetadataInput
            value={value}
            onChange={(metadatas) => this.onChange(metadatas, SEASON)}
            label="Season"
            type={SEASON}
            reference={getResource('competiciones', 'seasons')}
            filter={{ showInWeb: 1 }}
            sort={{
              field: 'id',
              order: 'ASC',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetadataInput
            value={value}
            onChange={(metadatas) => this.onChange(metadatas, COMPETITION)}
            label="Competition"
            type={COMPETITION}
            reference={getResource('competiciones', 'competitions')}
            sort={{
              field: 'id',
              order: 'ASC',
            }}
          />
        </Grid>
        {season && competition && (
          <Grid item xs={12} sm={6} md={4}>
            <MetadataInput
              optionText="competitionName"
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, SUBSCRIPTION)}
              label="Subscription"
              type={SUBSCRIPTION}
              reference={getResource('competiciones', 'subscriptions')}
              filter={{
                season: season,
                competition: competition,
              }}
              sort={{
                field: 'id',
                order: 'ASC',
              }}
            />
          </Grid>
        )}
        {season && competition && subscription && (
          <Grid item xs={12} sm={6} md={4}>
            <MetadataInput
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, ROUND)}
              label="Round"
              type={ROUND}
              reference={getResource('competiciones', 'rounds')}
              filter={{
                subscription: subscription,
              }}
              sort={{
                field: 'id',
                order: 'ASC',
              }}
            />
          </Grid>
        )}
        {season && competition && subscription && round && (
          <Grid item xs={12} sm={6} md={4}>
            <MetadataInput
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, GAMEWEEK)}
              label="Gameweek"
              type={GAMEWEEK}
              reference={getResource('competiciones', 'gameweeks')}
              filter={{
                subscription: subscription,
                round: round,
              }}
              sort={{
                field: 'id',
                order: 'ASC',
              }}
            />
          </Grid>
        )}
        {season && competition && subscription && round && gameweek && (
          <Grid item xs={12} sm={6} md={4}>
            <MetadataInput
              value={value}
              onChange={(metadatas) => this.onChange(metadatas, MATCH)}
              label="Match"
              type={MATCH}
              fullWidth
              optionText="shortName"
              source={sourceMatch ? sourceMatch : null}
              reference={getResource('competiciones', 'matches')}
              filter={{
                subscription: subscription,
                round: round,
                gameweek: gameweek,
              }}
              sort={{
                field: 'id',
                order: 'ASC',
              }}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

export default MatchInput
