import React, { Component } from 'react'
import { Field } from 'redux-form'
import LayoutInput from './layoutInput'

class DateTimeInput extends Component {
  render() {
    if (this.props.source) {
      return <Field component={LayoutInput} {...this.props} name={this.props.name ? this.props.name : this.props.source} />
    } else {
      return <LayoutInput {...this.props} />
    }
  }
}

export default DateTimeInput
