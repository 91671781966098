import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { MatchInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'
import { competitions } from '../../../resources/competiciones/utils/constants'

const StandingFairplayFilter = (props) => {
  return (
    <Filter {...props} fullWith>
      <MatchInput
        alwaysOn
        sourceSeason="season"
        sourceCompetition="competition"
        sourceSubscription="subscription"
        sourceRound="round"
        sourceGameweek="id"
        sourceMatch={false}
        filterCompetition={{ id: [competitions.laligaSantander.id, competitions.laliga123.id, competitions.ligaFemenina.id] }}
      />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<StandingFairplayFilter />} />
}

export const StandingFairplayList = (props) => {
  return (
    <List bulkActions={false} filter={{ standingLive: true }} title="StandingFairplay" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => (
              <div>
                <ReferenceField
                  label="Season"
                  source="round.id"
                  reference={getResource('competiciones', 'rounds')}
                  basePath={getResource('competiciones', 'rounds')}
                  linkType={false}
                  record={record}
                  allowEmpty
                >
                  <TextField source="subscription.season.name" />
                </ReferenceField>
                <ReferenceField
                  label="Competition"
                  source="round.id"
                  reference={getResource('competiciones', 'rounds')}
                  basePath={getResource('competiciones', 'rounds')}
                  linkType={false}
                  record={record}
                  allowEmpty
                >
                  <TextField source="subscription.competition.name" />
                </ReferenceField>
                <ReferenceField
                  label="Subscription"
                  source="round.id"
                  reference={getResource('competiciones', 'rounds')}
                  basePath={getResource('competiciones', 'rounds')}
                  linkType={false}
                  record={record}
                  allowEmpty
                >
                  <TextField source="subscription.competitionName" />
                </ReferenceField>
                <ReferenceField
                  label="Round"
                  source="round.id"
                  reference={getResource('competiciones', 'rounds')}
                  basePath={getResource('competiciones', 'rounds')}
                  linkType={false}
                  record={record}
                  allowEmpty
                >
                  <TextField source="name" />
                </ReferenceField>
              </div>
            )}
            tertiaryText={(record) => <DateField source="date" label="Date" record={record} />}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Season" source="round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
              <TextField source="subscription.season.name" />
            </ReferenceField>
            <ReferenceField label="Competition" source="round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
              <TextField source="subscription.competition.name" />
            </ReferenceField>
            <ReferenceField label="Subscription" source="round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
              <TextField source="subscription.competitionName" />
            </ReferenceField>
            <ReferenceField label="Round" source="round.id" reference={getResource('competiciones', 'rounds')} linkType={false} sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <DateField source="date" label="Date" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default StandingFairplayList
