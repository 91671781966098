import React from 'react'

const Camera = ({ color = '#111111' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path
          id="iconCamera_a"
          d="M17.474 2.523h-1.872l-.624-1.248C14.61.55 14.419.027 13.73.027H6.24c-.689 0-.917.595-1.247 1.248l-.624 1.248H2.496A2.496 2.496 0 0 0 0 5.02v9.985a2.496 2.496 0 0 0 2.496 2.496h14.978a2.496 2.496 0 0 0 2.497-2.496V5.02a2.497 2.497 0 0 0-2.497-2.497zM9.985 15.005a4.993 4.993 0 1 1 0-9.986 4.993 4.993 0 0 1 0 9.986zm0-8.737a3.744 3.744 0 1 0 0 7.489 3.744 3.744 0 0 0 0-7.49z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(2 3)">
          <mask id="iconCamera_b" fill={color}>
            <use xlinkHref="#a" />
          </mask>
          <use fill={color} fillRule="nonzero" xlinkHref="#iconCamera_a" />
          <g fill={color} mask="url(#iconCamera_b)">
            <path d="M-42-42h99v99h-99z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Camera
