import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import LanguagesNav from '../../../../components/languagesNav'
import { DisabledInput, TextInput, required, maxLength, SelectInput } from 'react-admin'
import { RichTextInput, ImageInput } from '../../../../components/inputs'

const styles = (theme) => ({})

export const CategoriesForm = ({ ...props }) => (
  <LanguagesNav {...props} romeveDelete>
    <Grid container>
      <Grid item xs={12}>
        <SelectInput
          label="Type"
          source="type"
          choices={[
            { id: 'experience', name: 'Experience' },
            { id: 'ultimate', name: 'Ultimate' },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth label="Hashtag" source="hashtag" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth label="Instagram" source="instagram" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput source="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          source="asset"
          label="Video"
          options={{
            type: 'videos',
            remove: true,
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default withStyles(styles)(CategoriesForm)
