import styled from 'styled-components'

export const MatchFieldStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 5px;
  }
`
