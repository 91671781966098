import React from 'react'
import { Edit } from 'react-admin'

import GenuineForm from '../utils/form'

const GenuineTitle = ({ record }) => {
  return <span>{`Operador: ${record.nombre}`}</span>
}

export const GenuineEdit = (props) => (
  <Edit undoable={false} title={<GenuineTitle />} {...props}>
    <GenuineForm genuine {...props} />
  </Edit>
)

export default GenuineEdit
