import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'

import { ImageInput, RichTextInput } from '../../../../inputs'

const styles = (theme) => ({
  row: {
    marginBottom: '10px',
  },
  label: {
    marginBottom: '10px',
    display: 'block',
    fontSize: '0.75rem',
  },
})

class PersonPluginEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: {
        fullname: null,
        career: null,
        role: null,
        image: null,
        content: null,
      },
      image: null,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      let pluginAnswere = {
        fullname: value.fullname ? value.fullname : null,
        career: value.career ? value.career : null,
        role: value.role ? value.role : null,
        content: value.content ? value.content : null,
        image: value.image ? value.image : null,
      }
      this.setState({
        pluginAnswere: pluginAnswere,
      })
    }
  }

  handleChange(key, value) {
    const { onChange } = this.props

    let pluginAnswere = { ...this.state.pluginAnswere }
    pluginAnswere[key] = value
    this.setState({ pluginAnswere })
    onChange(pluginAnswere)
  }

  render() {
    const { classes } = this.props
    const { fullname, career, role, content, image } = this.state.pluginAnswere

    return (
      <div>
        <div className={classes.row}>
          <TextField error={!fullname} label="Full Name" value={fullname} onChange={(e) => this.handleChange('fullname', e.target.value)} fullWidth />
        </div>
        <div className={classes.row}>
          <TextField label="Role" value={role} onChange={(e) => this.handleChange('role', e.target.value)} fullWidth />
        </div>
        <div className={classes.row}>
          <TextField label="Career" value={career} onChange={(e) => this.handleChange('career', e.target.value)} fullWidth />
        </div>
        <div className={classes.row}>
          <ImageInput
            label="Image"
            value={image}
            onChange={(value) => this.handleChange('image', value.id)}
            options={{
              remove: true,
            }}
          />
        </div>
        <div className={classes.row}>
          <RichTextInput label="Description" value={content} onChange={(value) => this.handleChange('content', value)} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(PersonPluginEdit)
