import _ from 'lodash'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { ColorPicker } from '../../../../../../components/inputs'

const styles = () => ({
  marginBottom: {
    marginBottom: '16px',
  },
})

class Management extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubgroupsManagement: false,
    }
  }

  handleShowSubgroupsManagementSection() {
    this.setState({ showSubgroupsManagement: !this.state.showSubgroupsManagement })
  }

  handleAddSubgroup = () => {
    let qualify = this.props.input ? this.props.input.value : this.props.value
    let newQualify = qualify ? [...qualify] : null
    const newEmptySubgroup = {
      color: '#000000',
      colorApp: '#000000',
      start: 1,
      end: 1,
      name: [
        {
          name: '',
          locale: 'es',
        },
        {
          name: '',
          locale: 'en',
        },
      ],
      shortname: [
        {
          name: '',
          locale: 'es',
        },
        {
          name: '',
          locale: 'en',
        },
      ],
    }
    if (newQualify) {
      newQualify.push(newEmptySubgroup)
    } else {
      newQualify = []
      newQualify.push(newEmptySubgroup)
    }

    if (this.props.input) {
      this.props.input.onChange(newQualify)
    } else {
      this.props.onChange(newQualify)
    }
  }

  handleDeleteSubgroup = (index) => {
    let qualify = this.props.input ? this.props.input.value : this.props.value
    const newQualify = qualify.filter((subgroup, subgroupIndex) => subgroupIndex !== index)
    if (this.props.input) {
      this.props.input.onChange(newQualify)
    } else {
      this.props.onChange(newQualify)
    }
  }

  onChange = (name, value) => {
    const qualify = this.props.input ? this.props.input.value : this.props.value
    let newQualify = _.cloneDeep(qualify)
    _.set(newQualify, name, value)

    if (this.props.input) {
      this.props.input.onChange(newQualify)
    } else {
      this.props.onChange(newQualify)
    }
  }

  render() {
    const { value, classes } = this.props

    const data = this.props.input ? this.props.input.value : value

    return (
      <>
        <Button color="primary" variant="contained" onClick={() => this.handleShowSubgroupsManagementSection()} className={classes.marginBottom}>
          Subgroups Management {this.state.showSubgroupsManagement ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={this.state.showSubgroupsManagement}>
          <Paper>
            {data ? (
              data.map((subgroup, subgroupIndex) => {
                return (
                  <Grid container key={subgroupIndex}>
                    <Grid container item lg={10}>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          label="Start Position"
                          value={subgroup.start}
                          onChange={(event) => this.onChange(`[${subgroupIndex}].start`, event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <TextField
                          label="End Position"
                          value={subgroup.end}
                          onChange={(event) => this.onChange(`[${subgroupIndex}].end`, event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <ColorPicker label="Color Web" value={subgroup.color} onChange={(color) => this.onChange(`[${subgroupIndex}].color`, color)} />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <ColorPicker label="Color App" value={subgroup.colorApp} onChange={(color) => this.onChange(`[${subgroupIndex}].colorApp`, color)} />
                      </Grid>
                      {subgroup.name.map((locale, index) => (
                        <Grid key={locale.locale} item xs={12} lg={5}>
                          <TextField
                            label={`Subgroup name ${locale.locale}`}
                            value={locale.name}
                            onChange={(event) => this.onChange(`[${subgroupIndex}].name[${index}].name`, event.target.value)}
                          />
                        </Grid>
                      ))}
                      {subgroup.shortname.map((locale, index) => (
                        <Grid key={locale.locale} item xs={12} lg={5}>
                          <TextField
                            label={`Subgroup shortname ${locale.locale}`}
                            value={locale.name}
                            onChange={(event) => this.onChange(`[${subgroupIndex}].shortname[${index}].name`, event.target.value)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item lg={2}>
                      <Button onClick={() => this.handleDeleteSubgroup(subgroupIndex)}>
                        <RemoveCircleOutline />
                        <span style={{ marginLeft: '8px' }}>Remove</span>
                      </Button>
                    </Grid>
                  </Grid>
                )
              })
            ) : (
              <span>No subgroups defined yet</span>
            )}
            <Button color="primary" onClick={this.handleAddSubgroup}>
              <AddCircleOutline /> <span style={{ marginLeft: '8px' }}>Add Subgroup</span>
            </Button>
          </Paper>
        </Collapse>
      </>
    )
  }
}

export default withStyles(styles)(Management)
