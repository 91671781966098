import React, { Component } from 'react'
import { RichTextInput } from '../../../../inputs'

class textPluginEdit extends Component {
  render() {
    return (
      <RichTextInput
        value={this.props.value && this.props.value.content ? this.props.value.content : ''}
        onChange={(value) => this.props.onChange({ content: value })}
      />
    )
  }
}

export default textPluginEdit
