import _get from 'lodash/get'
import lodashMemoize from 'lodash/memoize'

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args))

function validURL(str) {
  var res = str.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return res !== null
}

export const linkInputValidate = memoize((sourceTarget) => (value, values) => {
  const isInternalLink = _get(values, sourceTarget) === 'internal'

  if (isInternalLink) {
    return value && value.startsWith('/') ? undefined : 'Invalid link'
  } else {
    return validURL(value) ? undefined : 'Invalid link'
  }
})
