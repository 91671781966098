import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, FormLabel, Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { GET_LIST, Pagination } from 'react-admin'
import { Link } from 'react-router-dom'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'

const styles = (theme) => ({
  listBroadcasts: {
    width: '100%',
    display: 'table',
    marginBottom: theme.spacing.unit,
  },
  rowBroadcasts: {
    display: 'table-row',
  },
  celBroadcasts: {
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  btnBroadcasts: {
    display: 'table-cell',
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    width: '100px',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  leftIcon: {
    marginRight: '10px',
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
})

export class ListBroadcast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      broadcasts: [],
      page: 1,
      perPage: 5,
    }
  }
  componentDidMount() {
    const { idMatch } = this.props
    const { page, perPage } = this.state

    this.getBroadcasts(idMatch, page, perPage)
  }

  getBroadcasts(idMatch, page, perPage) {
    dataProvider(GET_LIST, getResource('operadores', 'broadcasts'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: { page: page, perPage: perPage },
      filter: {
        match: idMatch,
      },
    }).then((response) => {
      this.setState({
        broadcasts: response.data,
        page: page,
        perPage: perPage,
        total: response.total,
      })
    })
  }

  render() {
    const { label, classes } = this.props
    const { broadcasts } = this.state

    return (
      <div>
        <FormLabel>{label ? label : 'Difusiones'}</FormLabel>
        <div className={classes.listBroadcasts}>
          {broadcasts &&
            broadcasts.map((item) => {
              return (
                <div className={classes.rowBroadcasts} key={item.key}>
                  <div className={classes.celBroadcasts}>
                    <div>{item.broadcaster.name}</div>
                    {item.broadcastCountries && (
                      <div>
                        {item.broadcastCountries.map((each) => (
                          <Chip label={each.country} className={classes.chip} key={each.id} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={classes.btnBroadcasts}>
                    <Link to={`/${getResource('operadores', 'broadcasts')}/${item.id}`} target="_blank">
                      <Button size="small" color="primary">
                        <AddIcon className={classes.leftIcon} />
                        Edit
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            })}
        </div>
        {broadcasts.length > 0 && (
          <Pagination
            setPage={(page) => this.getBroadcasts(this.props.idMatch, page, this.state.perPage)}
            setPerPage={(perPage) => this.getBroadcasts(this.props.idMatch, 1, perPage)}
            total={this.state.total}
            perPage={this.state.perPage}
            page={this.state.page}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ListBroadcast)
