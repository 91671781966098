import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import RelatedNew from './relatedNew'
import { InputStyled, ListStyled } from './style'

const styles = {
  card: {
    backgroundColor: 'white',
  },
  media: {
    objectFit: 'cover',
  },
}

class NewRelatedNewsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: [],
      choose: [],
    }
  }

  componentDidMount() {
    let onChange = (list) => {
      this.setState({
        choose: list,
        value: this.props.value,
      })
    }

    let filters = {
      recomendedRelatedNews: this.props.slug,
    }
    this.getRelatedNews(onChange, filters)
  }

  getRelatedNews(onChange, filters) {
    const { setFetching } = this.props
    setFetching(true)
    dataProvider(GET_LIST, getResource('news', 'noticias'), {
      sort: {
        field: 'title',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 6 },
      filter: filters,
    })
      .then((response) => {
        onChange(response.data)
      })
      .finally(() => setFetching(false))
  }

  handleClick(item, index) {
    const { value } = this.state
    const { onChange } = this.props
    let list = [...value]

    const listItemIndex = _.findIndex(list, { id: item.id })
    if (listItemIndex >= 0) {
      list.splice(listItemIndex, 1)
    } else {
      list.push(item)
    }

    this.setState(
      {
        value: list,
      },
      () => {
        onChange(list)
      }
    )
  }
  handleChange = _.debounce((text) => {
    let onChange = (list) => {
      this.setState({
        choose: list,
        value: this.props.value,
      })
    }

    this.getRelatedNews(onChange, { q: text })
  }, 200)

  render() {
    const { choose, value } = this.state
    return (
      <FormControl variant="outlined">
        <InputLabel>Choose related news</InputLabel>
        <InputStyled>
          <SearchIcon />
          <input onChange={(e) => this.handleChange(e.target.value)} placeholder="Search…" />
        </InputStyled>
        <ListStyled>
          {_.map(choose, (item, index) => {
            return (
              <Grid key={item.id} item xs={12} sm={6} md={4}>
                <RelatedNew
                  selected={_.find(value, { id: item.id })}
                  title={item.title}
                  shortDescription={item.shortDescription}
                  asset={item.asset}
                  onClick={() => this.handleClick(item, index)}
                />
              </Grid>
            )
          })}
        </ListStyled>
      </FormControl>
    )
  }
}

export default withStyles(styles)(NewRelatedNewsForm)
