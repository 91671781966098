import styled from 'styled-components'

export const DialogTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseButtonStyled = styled.div`
  margin: 10px;
`
