import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ContainerStyled = styled.div``

export const LabelStyled = styled.label`
  font-size: 13px;
  margin-bottom: 16px;
  display: block;
`

export const TextareaStyled = styled.div`
  position: relative;
  padding: 20px;
  background-color: ${colors.grey[100]};
  & textarea {
    resize: none;
    width: 100%;
    height: 85px;
    padding: 20px;
    border: solid 1px ${colors.grey[500]}
    box-sizing: border-box;
  }
`
