import React, { Component } from 'react'
import _ from 'lodash'
import { ContainerStyled, LabelStyled, TextareaStyled, ListStyled } from './style'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import { AutocompleteInput } from '../../inputs'
import { GET_LIST } from 'react-admin'
import { getResource } from '../../../config/resources'

import { dataProvider } from '../../../providers'

const styles = (theme) => ({
  chip: {
    margin: '4px',
  },
})

class Textarea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      list: [],
      reference: [],
      isFetching: false,
    }
  }

  getReference(reference, filters, func) {
    this.setState({
      isFetching: true,
    })
    dataProvider(GET_LIST, reference, {
      sort: {
        field: 'name',
        order: 'asc',
      },
      filter: filters,
      pagination: {},
    })
      .then((response) => {
        func(response.data)
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        })
      })
  }

  handleOnChange() {
    if (this.props.input) {
      this.props.input.onChange(this.state.list)
    } else {
      this.props.onChange(this.state.list)
    }
  }

  handleOnTextChange(e) {
    this.setState({ text: e.target.value })
  }

  handleTagSelected = (tag) => {
    const { value } = this.props.input ? this.props.input : this.props
    if (!_.find(value, { name: tag })) {
      var list = value ? _.clone(value) : []

      const item = {
        name: tag,
      }

      list.push(item)

      this.setState(
        {
          text: '',
          list: list,
        },
        () => this.handleOnChange()
      )
    }
  }

  handleonDelete(index) {
    const { value } = this.props.input ? this.props.input : this.props
    let list = _.clone(value)
    _.pullAt(list, [index])
    this.setState(
      {
        list: list,
      },
      () => this.handleOnChange()
    )
  }

  handleFilter = _.debounce((value) => {
    if (value) {
      const { filter } = this.props
      this.getReference(getResource('assets', 'tags'), { ...filter, q: value }, (list) => {
        this.setState({
          reference: list,
        })
      })
    }
  }, 800)

  render() {
    const { classes, label } = this.props
    const { value } = this.props.input ? this.props.input : this.props
    return (
      <ContainerStyled>
        <FormControl>
          <ListStyled>
            <AutocompleteInput
              label={label}
              allowAnyText
              isFetching={this.state.isFetching}
              value={this.state.list}
              choices={this.state.reference}
              onChange={(value) => this.handleTagSelected(value)}
              optionText={'name'}
              optionValue={'name'}
              setFilter={(value) => this.handleFilter(value)}
              translateChoice={false}
              placeholder={'Write tags...'}
              fullWidth
            />

            {value &&
              value.map((item, index) => {
                return <Chip key={index} label={item.name} onDelete={() => this.handleonDelete(index)} className={classes.chip} />
              })}
          </ListStyled>
        </FormControl>
      </ContainerStyled>
    )
  }
}

export default withStyles(styles)(Textarea)
