import { AppBar, Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'

import LanguagesNav from '../../../../components/languagesNav'
import Events from './events'
import Lineups from './lineups'

const styles = (theme) => {
  return {
    tabs: {
      boxShadow: 'none',
    },
    containerTab: {
      width: '100%;',
    },
  }
}

export class MatchcenterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabActive: 0,
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      tabActive: value,
    })
  }

  render() {
    const { props } = this
    const { classes, record } = this.props
    const { tabActive } = this.state

    return (
      <LanguagesNav {...props} disabledToolbar={true}>
        <AppBar className={classes.tabs} position="static" color="default">
          <Tabs value={tabActive} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" fullWidth>
            <Tab label="Lineups" />
            <Tab label="Events" />
          </Tabs>
        </AppBar>
        <div className={classes.containerTab}>
          {tabActive === 0 && <Lineups match={record} />}
          {tabActive === 1 && <Events match={record} />}
        </div>
      </LanguagesNav>
    )
  }
}

export default withStyles(styles)(MatchcenterForm)
