import React, { Component } from 'react'
import GalleryPluginEdit from './galleryPluginEdit'
import Plugin from '../plugin'

class VideoPlugin extends Component {
  render() {
    return <Plugin {...this.props} name="imagePlugin" component={GalleryPluginEdit} />
  }
}

export default VideoPlugin
