import React from 'react'
import { List, Datagrid, TextField, BooleanField, ReferenceField, ImageField, EditButton, Responsive, SimpleList } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'
import { getResource } from '../../../config/resources'

const Actions = (props) => {
  return <ListActions {...props} />
}

const SocialMediaProfilesList = (props) => {
  return (
    <List title="RRSS" bulkActions={false} {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                source="image"
                reference={getResource('assets', 'images')}
                basePath={getResource('assets', 'images')}
                linkType={false}
                record={record}
                allowEmpty
              >
                <ImageField mini source="asset.fileDir" title="Image RRSS" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
              <ImageField source="asset.fileDir" title="Image RRSS" />
            </ReferenceField>
            <TextField source="name" />
            <BooleanField source="active" label="Active" />
            <TextField source="positionApp" label="Position App" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default SocialMediaProfilesList
