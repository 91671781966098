import React from 'react'
import { List, Datagrid, TextField, EditButton, Filter, TextInput, Responsive, SimpleList } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'

const RolesFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<RolesFilters />} />
}

const RolesList = (props) => (
  <List bulkActions={false} title="Roles" actions={<Actions />} {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="slug" label="Slug" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default RolesList
