import TextField from '@material-ui/core/TextField'
import moment from 'moment-timezone'
import React, { Component } from 'react'

class TimeInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: '',
      disabledTime: false,
      disabledNotDate: false,
    }
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    const { disabledTime = false, date } = this.props

    let initialTime = value ? moment(value).tz('Europe/Madrid').format('HH:mm') : ''
    this.setState({
      time: initialTime,
      disabledTime: disabledTime,
      disabledNotDate: !date,
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { value } = this.props.input ? this.props.input : this.props
    const nextValue = nextProps.input ? nextProps.input.value : nextProps.value

    if (this.props.date !== nextProps.date && this.props.date != null) {
      this.changeTime(null, this.props.date)
    }

    if (value !== nextValue) {
      let initialTime = nextValue ? moment(nextValue).tz('Europe/Madrid').format('HH:mm') : ''
      if (nextValue === '') {
        initialTime = ''
      }
      this.setState({ time: initialTime })
    }
    if (this.props.disabledTime !== nextProps.disabledTime) {
      this.setState({ disabledTime: nextProps.disabledTime })
    }

    return true
  }

  changeTime(value, date) {
    const { input, onChange } = this.props
    let newTime = null
    this.setState(
      {
        time: value,
      },
      () => {
        this.setState({ disabledNotDate: !date })
      }
    )

    if (value) {
      var arrayValue = value.split(':')

      let time = moment(date).tz('Europe/Madrid')
      time.hour(arrayValue[0]).minute(arrayValue[1]).seconds(0)
      newTime = time.tz('UTC').toISOString(true)
    }

    if (this.props.source) {
      input.onChange(newTime)
    } else {
      onChange(newTime)
    }
  }

  render() {
    const { input, value, onChange, date, ...rest } = this.props

    return (
      <TextField
        type="time"
        value={this.state.time}
        margin="normal"
        disabled={this.state.disabledTime || this.state.disabledNotDate}
        onChange={(event) => this.changeTime(event.target.value, date)}
        {...rest}
      />
    )
  }
}

export default TimeInput
