export const CREATE_IMAGE = 'CREATE_IMAGE'
export const MY_UPDATE_MANY = 'MY_UPDATE_MANY'
export const MY_CREATE_MANY = 'MY_CREATE_MANY'
export const CLONE = 'CLONE'
export const PATCH = 'PATCH'
export const GET = 'GET'
export const GET_MIDDLEWARE = 'GET_MIDDLEWARE'
export const POST_MANY = 'POST_MANY'
export const RESIZE = 'RESIZE'
export const CREATE_SH = 'CREATE_SH'
export const GET_ONE_FOOTBALL = 'GET_ONE_FOOBALL'
export const POST_ONE_FOOTBALL = 'POST_ONE_FOOBALL'
export const DELETE_ONE_FOOTBALL = 'DELETE_ONE_FOOTBALL'
export const GET_ONE_WSC_VIDEO = 'GET_ONE_WSC_VIDEO'
export const POST_ONE_WSC_VIDEO = 'POST_ONE_WSC_VIDEO'
