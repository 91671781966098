import React, { Component, Fragment } from 'react'
import { FormDataConsumer } from 'react-admin'
import _ from 'lodash'
import Careers from './Careers'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => {
  return {
    paper: {
      position: 'absolute',
      width: '90vw',
      maxWidth: theme.breakpoints.values.md,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
    },
    closeButton: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      '&:hover': {
        color: theme.palette.error.dark,
      },
    },
  }
}

class CareersInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { props } = this
    const { classes } = this.props
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData) return null
          return (
            <Fragment>
              <Button variant="contained" color="primary" fullWidth disabled={_.get(formData, props.source) === undefined} onClick={this.handleOpen}>
                Career
              </Button>
              <Modal open={this.state.open} onClose={this.handleClose}>
                <Paper className={classes.paper}>
                  <IconButton className={classes.closeButton} onClick={this.handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <Careers idPerson={_.get(formData, props.source)} closeModal={() => this.handleClose()} />
                </Paper>
              </Modal>
            </Fragment>
          )
        }}
      </FormDataConsumer>
    )
  }
}

export default withStyles(styles)(CareersInput)
