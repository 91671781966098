import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'

import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import { ContainerStyled } from './style'

class ClientUuidsSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clients: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.getClients()
  }

  getClients() {
    const { isEdit } = this.props
    const { onChange } = this.props.input ? this.props.input : this.props

    this.setState({ loading: true })

    dataProvider(GET_LIST, getResource('transversal', 'clientuuids'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {},
      filter: {},
    }).then(({ data }) => {
      if (!isEdit) {
        onChange(data.filter((client) => client.defaultChecked))
      }
      this.setState({ clients: data, loading: false })
    })
  }

  handleOnChange(e) {
    if (this.props.input) {
      this.props.input.onChange(e.target.value)
    } else {
      this.props.onChange(e.target.value)
    }
  }

  render() {
    const { meta, label, ...rest } = this.props
    const { clients, loading } = this.state
    const { value, onChange } = this.props.input ? this.props.input : this.props

    return (
      <ContainerStyled>
        <FormControl error={meta.error && meta.touched} {...rest}>
          <InputLabel>{label}</InputLabel>
          <FormGroup row>
            {loading ? (
              <LinearProgress style={{ width: '100%' }} />
            ) : (
              clients.map((client) => (
                <FormControlLabel
                  key={client.id}
                  control={
                    <Checkbox
                      checked={_.find(value, { id: client.id })}
                      color={'primary'}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        let newValue = value ? [...value] : []
                        if (isChecked) {
                          newValue.push(client)
                          onChange(newValue)
                        } else {
                          _.remove(newValue, (n) => {
                            return n.id === client.id
                          })
                          onChange(newValue)
                        }
                      }}
                      value={client}
                    />
                  }
                  label={client.name}
                  disabled={!!clients.length === 0}
                />
              ))
            )}
          </FormGroup>
          {meta.error && meta.touched && (
            <FormHelperText>
              <div>{meta.error}</div>
            </FormHelperText>
          )}
        </FormControl>
      </ContainerStyled>
    )
  }
}

export default ClientUuidsSelector
