import React, { Component } from 'react'
import moment from 'moment-timezone'
import { GET_LIST, Responsive } from 'react-admin'
import { Link } from 'react-router-dom'
import { dataProvider } from '../../../../../providers'
import { getResource, getLink } from '../../../../../config/resources'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  tableCell: {
    verticalAlign: 'middle !important',
  },
  card: {
    background: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
  },
})

class Sponsorings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sponsorings: [],
      page: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    const { idBrand } = this.props
    this.getSponsorings(idBrand, 1, 10)
  }

  getSponsorings(idBrand, page, perPage) {
    dataProvider(GET_LIST, getResource('digitalAssets', 'sponsorings'), {
      sort: {
        field: 'startedAt',
        order: 'DESC',
      },
      pagination: {
        page: page,
        perPage: perPage,
      },
      filter: {
        brand: idBrand,
      },
    })
      .then(({ data }) => {
        this.setState({
          sponsorings: data,
          page: page,
          perPage: perPage,
        })
      })
      .catch((error) => {
        // showNotification(error.message, 'error');
      })
      .finally(() => {
        // fetchEnd()
      })
  }

  formatDate(time) {
    return moment(time).tz('Europe/Madrid').format('L')
  }

  render() {
    const { classes, idBrand } = this.props
    const { sponsorings } = this.state

    return (
      <Grid container>
        <Grid item xs={12}>
          <Responsive
            small={
              <div>
                {sponsorings &&
                  sponsorings.map((item, key) => (
                    <Card className={classes.card} key={key}>
                      <CardContent>
                        <Typography component="h3">
                          <Link to={`/${getLink('digitalAssets', 'sponsorings')}/${item.id}`} target="_blank">
                            {item.digitalAsset.name}
                          </Link>
                        </Typography>
                        <Typography component="p">{item.digitalAsset.price}</Typography>
                        <Typography component="p">{`${this.formatDate(item.startedAt)} - ${this.formatDate(item.endAt)}`}</Typography>
                      </CardContent>
                    </Card>
                  ))}
              </div>
            }
            medium={
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Digital Asset</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sponsorings &&
                    sponsorings.map((item, key) => (
                      <TableRow key={key}>
                        <TableCell className={classes.tableCell}>
                          <Link to={`/${getLink('digitalAssets', 'sponsorings', { id: item.id })}`} target="_blank">
                            {item.digitalAsset.name}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography variant="body1">{item.digitalAsset.price}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography variant="body1">{this.formatDate(item.startedAt)}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography variant="body1">{this.formatDate(item.endAt)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            }
          />
        </Grid>
        <Grid item xs={12} align="right">
          <Link to={`/${getLink('digitalAssets', 'sponsorings', { query: { filter: { brand: idBrand } } })}`} target="_blank">
            <Button variant="contained" color="primary" size="small" aria-label="All">
              All
            </Button>
          </Link>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Sponsorings)
