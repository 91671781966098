import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ContainerStyled = styled.div`
  margin-top: 10px;
`

export const NoContentStyled = styled.div`
  padding: 40px;
  text-align: center;
  background-color: white;
`

export const BlocksListStyled = styled.div``

export const PluginContainerStyled = styled.div`
  display: flex;
  margin-bottom: 29px;
  &:last-child {
    margin-bottom: 0px;
  }
`

export const PluginStyled = styled.div`
  flex-grow: 1;
  background-color: ${colors.grey[50]};
  border: solid 1px;
  border: solid 1px ${(props) => (props.highlight ? colors.primary.main : colors.grey[300])};
`

export const PluginOptions = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  & > button {
    margin-bottom: 10px;
  }
`
