import styled from 'styled-components'
import { keyframes } from 'styled-components'
import theme from '../../config/theme'

const open = keyframes`
  from {
    width: 0px;
  }

  to {
    width: 200px;
  }
`

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const ListContainerStyled = styled.div`
  min-height: 100%;
  width: 29px;
  top: 0px;
  right: 0px;
  position: absolute;
  cursor: ${(props) => (!props.open ? 'pointer' : 'default')};
  z-index: ${(props) => (props.open ? 99999999 : 10)};
  & > div {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    z-index: ${(props) => (props.open ? '5' : '1')};
    background-color: ${(props) => (props.open ? theme.palette.primary.main : theme.palette.grey[50])};
    border: solid 1px ${(props) => (props.open ? 'transparent' : theme.palette.secondary.main)};
    padding: 10px;
  }
  & > div:hover {
    background-color: ${(props) => (props.open ? theme.palette.primary.main : theme.palette.grey[200])};
  }
  & .menu-icon {
    fill: ${(props) => (props.open ? theme.palette.primary.contrastText : 'inherit')};
    cursor: pointer;
  }
`

export const ListStyled = styled.ul`
  height: 100%;
  color: white;
  flex-grow: 1;
  padding: 0px;
  transition: all 0.3s;
  animation: ${open} 0.3s;
  animation-fill-mode: forwards;
  list-style: none;
  margin: 0px;
  box-sizing: border-box;
  & li {
    white-space: nowrap;
    padding-top: 0px;
    padding-bottom: 0px;
    & a {
      color: ${theme.palette.primary.contrastText};
      font-size: 13px;
      font-weight: normal;
      padding: 0px;
    }
  }
`

export const ItemListStyled = styled.li`
  padding: 10px;
  opacity: 0;
  animation: ${appear} 0.3s 0.3s;
  animation-fill-mode: forwards;
`

export const BackStyled = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #0000005e;
  z-index: 9999999;
`
