import React, { Component } from 'react'
import { GET_LIST, Pagination } from 'react-admin'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

const styles = (theme) => ({
  table: {
    borderSpacing: '0px 0px',
  },
  cellButton: {
    textAlign: 'right',
  },
})

class TeamManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: null,
      page: 1,
      perPage: 5,
      total: null,
    }
  }

  componentDidMount() {
    const { idClub } = this.props
    const { page, perPage } = this.state
    this.listTeams(idClub, page, perPage)
  }

  listTeams(idClub, page, perPage) {
    dataProvider(GET_LIST, getResource('competiciones', 'teams'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: { page: page, perPage: perPage },
      filter: {
        club: idClub,
      },
    }).then((response) => {
      this.setState({
        teams: response.data,
        total: response.total,
        page: page,
        perPage: perPage,
      })
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Teams</TableCell>
              <TableCell variant="head" className={classes.cellButton}>
                <Link to={`/${getResource('competiciones', 'teams')}/create`} target="_blank">
                  <Button mini variant="contained" color="primary" size="small" aria-label="Add">
                    Add
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.teams &&
              this.state.teams.map((item, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="body1">{item.selectorName}</Typography>
                    </TableCell>
                    <TableCell className={classes.cellButton}>
                      <Link to={`/${getResource('competiciones', 'teams')}/${item.id}`} target="_blank">
                        <Button variant="contained" color="primary" size="small" aria-label="Edit">
                          Edit
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <Pagination
          setPage={(page) => this.listTeams(this.props.idClub, page, this.state.perPage)}
          setPerPage={(perPage) => this.listTeams(this.props.idClub, this.state.page, perPage)}
          total={this.state.total}
          perPage={this.state.perPage}
          page={this.state.page}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(TeamManagement)
