import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SimpleList, TextInput, TextField, BooleanField } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'

const MetadataOthersFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12}>
          <TextInput source="q" label="Search" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<MetadataOthersFilter />} />
}

export const MetadataOthersList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Images" {...props}>
      <Responsive
        small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => record.description} />}
        medium={
          <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="description" label="Description" />
            <BooleanField source="multimedia" label="Multimedia" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default MetadataOthersList
