import Grid from '@material-ui/core/Grid'
import React from 'react'
import { maxLength, required, TextInput, SelectInput, ReferenceInput, NumberInput, BooleanInput } from 'react-admin'
import { getResource } from '../../../../config/resources'

import { LayoutInput, DateSingleInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'

export const ImagesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput label="Section" source="section" reference={getResource('layouts', 'sections')} validate={[required()]}>
          <SelectInput fullWidth optionValue="slug" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <NumberInput fullWidth label="Priority" source="priority" validate={[required()]} step={1} defaultValue={0} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BooleanInput label="Is Brand Day" source="isBrandDay" />
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <DateSingleInput label="Start at" fullWidth source="startAt" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DateSingleInput label="End at" fullWidth source="endAt" />
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <LayoutInput
          source="containers"
          label="Layout"
          options={{
            sections: [
              { id: '1', name: 'Content', areas: [100] },
              { id: '31', name: 'Content + Sidebar', areas: [75, 25] },
              { id: '13', name: 'Sidebar + Content', areas: [25, 75] },
            ],
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ImagesForm
