import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { dataProvider } from '../../../providers'

const styles = () => ({
  fullWidth: {
    width: '100%',
  },
})

class CategorySelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      category: '',
      subcategory: null,
      categoriesList: [],
      subcategoriesList: [],
    }
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    this.getCategories(
      {
        parent: 'firstLevel',
      },
      (data) => {
        this.setState(
          {
            categoriesList: data,
          },
          () => {
            this.selectCategory()
          }
        )
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props.input ? this.props.input : this.props
    const { value: prevValue } = prevProps.input ? prevProps.input : prevProps

    if (!prevValue && value) {
      this.selectCategory()
    }
  }

  selectCategory() {
    const { value } = this.props.input ? this.props.input : this.props

    if (value) {
      if (value.parent) {
        const idParent = value.parent.id
        this.getCategories(
          {
            parent: idParent,
          },
          (data) => {
            if (value.parent) {
              this.setState({
                category: _.findIndex(this.state.categoriesList, function (item) {
                  return item.id === value.parent.id
                }),
                subcategory: _.findIndex(data, function (item) {
                  return item.id === value.id
                }),
                subcategoriesList: data,
              })
            } else {
              this.setState({
                category: _.findIndex(this.state.categoriesList, function (item) {
                  return item.id === value.id
                }),
                subcategory: null,
                subcategoriesList: data,
              })
            }
          }
        )
      } else {
        this.setState({
          category: _.findIndex(this.state.categoriesList, function (item) {
            return item.id === value.id
          }),
        })
      }
    }
  }

  getCategories(filter, func) {
    const { resource } = this.props
    dataProvider(GET_LIST, resource, {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: filter ? filter : {},
    }).then((response) => {
      func(response.data)
    })
  }

  handleCategory(value) {
    this.setState(
      {
        category: value,
        subcategory: null,
      },
      () => {
        this.getCategories(
          {
            parent: this.state.categoriesList[value].id,
          },
          (data) => {
            this.setState(
              {
                subcategoriesList: data,
              },
              () => this.onChange()
            )
          }
        )
      }
    )
  }

  handleSubcategory(value) {
    this.setState(
      {
        subcategory: value,
      },
      () => this.onChange()
    )
  }

  onChange() {
    const { onChange } = this.props.input ? this.props.input : this.props

    if (this.state.subcategory) onChange(this.state.subcategoriesList[this.state.subcategory])
    else onChange(this.state.categoriesList[this.state.category])
  }

  render() {
    const { meta, classes } = this.props
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={meta.touched && meta.error}>
            <InputLabel htmlFor="input-category">Category</InputLabel>
            <Select
              value={this.state.category}
              onChange={(event) => this.handleCategory(event.target.value)}
              inputProps={{
                id: 'input-subcategory',
              }}
            >
              {_.map(this.state.categoriesList, (item, index) => (
                <MenuItem key={item.id} value={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        </Grid>
        {this.state.subcategoriesList && this.state.subcategoriesList.length > 0 && (
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.fullWidth} error={meta.touched && meta.error}>
              <InputLabel htmlFor="input-subcategory">Subcategory</InputLabel>
              <Select
                value={this.state.subcategory}
                onChange={(event) => this.handleSubcategory(event.target.value)}
                inputProps={{
                  id: 'input-subcategory',
                }}
              >
                <MenuItem>
                  <em></em>
                </MenuItem>
                {_.map(this.state.subcategoriesList, (item, index) => (
                  <MenuItem key={item.id} value={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(CategorySelector)
