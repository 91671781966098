import { Button, Grid, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import _get from 'lodash/get'
import React from 'react'
import { maxLength, required, TextInput } from 'react-admin'
import { arrayMove, List } from 'react-movable'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize } from 'redux-form'

import { BoolExternalLinkInput, ColorPicker, LinkInput } from '../../../../components/inputs'
import { linkInputValidate } from '../../../../components/inputs/linkInput/validate'
import { withFormActiveLanguage } from '../../../../components/languagesNav/formActiveLanguageContext'

const styles = (theme) => ({
  row: {
    position: 'relative',
    background: theme.palette.grey[50],
    marginBottom: theme.spacing.unit,
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing.unit,
    '& button': {
      textTransform: 'capitalize',
      paddingLeft: theme.spacing.unit,
      '& svg': {
        fontSize: 20,
        marginRight: theme.spacing.unit / 2,
      },
    },
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    zIndex: 1,
  },
  drag: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const FORM = 'record-form'
const selector = formValueSelector(FORM)
const ListQuickAccess = ({ source, hideColor = false, classes, field, change, formLanguageData }) => {
  const { formActiveLanguage } = formLanguageData
  const quickAccessItems = field(source)

  const handleClickAdd = () => {
    let newQuickAccessItems = quickAccessItems ? [...quickAccessItems] : []
    if (!newQuickAccessItems) {
      newQuickAccessItems = []
    }
    newQuickAccessItems.push({
      label: '',
      color: undefined,
      target: 'internal',
      url: '',
    })
    change(FORM, source, newQuickAccessItems)
  }

  const handleRemove = (index) => {
    let newQuickAccessItems = [...quickAccessItems]
    newQuickAccessItems.splice(index, 1)
    change(FORM, source, newQuickAccessItems)
  }

  const handleChangeOrder = ({ oldIndex, newIndex }) => {
    let newQuickAccessItems = [...quickAccessItems]
    newQuickAccessItems = arrayMove(newQuickAccessItems, oldIndex, newIndex)
    change(FORM, source, newQuickAccessItems)
  }

  return (
    <>
      <div className={classes.toolbar}>
        <Button color="primary" variant="contained" size="small" onClick={handleClickAdd}>
          <AddIcon />
          Add
        </Button>
      </div>
      {quickAccessItems && (
        <List
          lockVertically
          values={quickAccessItems}
          onChange={handleChangeOrder}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ index, props, isDragged }) => (
            <div {...props} style={{ ...props.style, zIndex: 1 }} className={classes.row}>
              <Button className={classes.deleteButton} variant="fab" color="secondary" aria-label="Remove" onClick={() => handleRemove(index)} mini>
                <DeleteIcon fontSize={20} />
              </Button>
              <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
                <DragHandleIcon />
              </div>

              <Grid container alignItems="center">
                <Grid item xs={12} md={3}>
                  <BoolExternalLinkInput label="External link" source={`${source}[${index}].target`} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <LinkInput
                    label="Url"
                    source={`${source}[${index}].url`}
                    internalLink={_get(quickAccessItems, `[${index}].target`) === 'internal'}
                    validate={[required(), linkInputValidate(`${source}[${index}].target`)]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={hideColor ? 12 : 8}>
                  <TextInput fullWidth label="Label" source={`${source}[${index}].label`} validate={[required(), maxLength(45)]} />
                </Grid>
                {!hideColor && (
                  <Grid item xs={12} md={4}>
                    <ColorPicker label="Color" source={`${source}[${index}].color`} disabled={formActiveLanguage !== 'es'} clearable fullWidth />
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        />
      )}
    </>
  )
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(withFormActiveLanguage(ListQuickAccess))
