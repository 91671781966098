import React from 'react'
import Grid from '@material-ui/core/Grid'
import { slugifyFiler } from './../../../../config/utils'
import { TextFormatInput, AutocompleteInput } from '../../../inputs'
import { Field, change } from 'redux-form'
import { maxLength, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin'
import { getResource } from '../../../../config/resources'
import MatchInput from './matchInput'
import _ from 'lodash'

const format = (text) => {
  return slugifyFiler(text ? text : '')
}

const SelectQueryParam = ({ ...props }) => {
  const { choices, input, source, dispatch, autocomplete, name, ...rest } = props
  const { value, onChange } = props.input
  const newOnChange = (inputValue) => {
    const nameSource = _.replace(source, '.id', '')
    if (!inputValue || !_.find(choices, { id: inputValue })) dispatch(change('modalConfig-form', `${nameSource}`, null))
    else {
      dispatch(change('modalConfig-form', `${nameSource}.value`, _.find(choices, { id: inputValue }).slug))
      dispatch(change('modalConfig-form', `${nameSource}.name`, name))
      onChange(inputValue)
    }
  }

  const newInput = { ...input }
  newInput.onChange = newOnChange

  const newProps = { ...props }

  newProps.input = newInput

  if (autocomplete) return <AutocompleteInput {...newProps} />
  return <SelectInput {...newProps} />
}

const RenderInputs = ({ record, input, ...rest }) => {
  const inputs = input.value
  return _.map(inputs, (input, index) => {
    if (input.name === 'competitionSlug')
      return (
        <Grid item xs={12} md={6}>
          <FormDataConsumer>
            {({ dispatch }) => (
              <ReferenceInput
                allowEmpty
                fullWidth
                label="Competition"
                source={`config.queryParams[${index}].id`}
                reference={getResource('competiciones', 'competitions')}
                filter={{ showInCalendar: 1 }}
              >
                <SelectQueryParam dispatch={dispatch} name="competitionSlug" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Grid>
      )
    if (input.name === 'categorySlug')
      return (
        <Grid item xs={12} md={6}>
          <FormDataConsumer>
            {({ dispatch }) => (
              <ReferenceInput allowEmpty fullWidth label="Category" source={`config.queryParams[${index}].id`} reference={getResource('news', 'categories')}>
                <SelectQueryParam dispatch={dispatch} name="categorySlug" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Grid>
      )
    if (input.name === 'teamSlug')
      return (
        <Grid item xs={12} md={6}>
          <FormDataConsumer>
            {({ dispatch }) => (
              <ReferenceInput
                allowEmpty
                fullWidth
                label="Team"
                source={`config.queryParams[${index}].id`}
                reference={getResource('competiciones', 'teams')}
                filter={{ type: 'main' }}
                onChange={(value) => {
                  dispatch(change('modalConfig-form', `config.queryParams[${index}].name`, input.name))
                }}
              >
                <SelectQueryParam dispatch={dispatch} index={index} name="teamSlug" autocomplete />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Grid>
      )

    if (input.name === 'playerSlug')
      return (
        <Grid item xs={12} md={6}>
          <FormDataConsumer>
            {({ dispatch }) => (
              <ReferenceInput
                allowEmpty
                fullWidth
                label="Player"
                source={`config.queryParams[${index}].id`}
                reference={getResource('competiciones', 'people')}
                filter={{ role: 1 }}
                onChange={(value) => {
                  dispatch(change('modalConfig-form', `config.queryParams[${index}].name`, input.name))
                }}
              >
                <SelectQueryParam dispatch={dispatch} index={index} autocomplete />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </Grid>
      )
    if (input.name === 'matchSlug')
      return (
        <Grid item xs={12} md={12}>
          <FormDataConsumer>
            {({ dispatch }) => (
              <MatchInput
                source={`config.queryParams[${index}]`}
                onChange={(value) => {
                  dispatch(change('modalConfig-form', `config.queryParams[${index}].name`, input.name))
                }}
              />
            )}
          </FormDataConsumer>
        </Grid>
      )
    return (
      <Grid item xs={12} md={6}>
        <FormDataConsumer>
          {({ dispatch }) => (
            <TextFormatInput
              key={index}
              fullWidth
              label={input.name}
              source={`config.queryParams[${index}].id`}
              validate={[maxLength(255)]}
              onChange={(value) => {
                dispatch(change('modalConfig-form', `config.queryParams[${index}].name`, input.name))
              }}
              options={{
                format,
              }}
            />
          )}
        </FormDataConsumer>
      </Grid>
    )
  })
}

const queryParamsForm = ({ ...props }) => {
  return (
    <Grid container>
      <Field name="queryParams" component={RenderInputs} />
    </Grid>
  )
}

export default queryParamsForm
