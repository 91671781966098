import styled from 'styled-components'

import theme from '../../../config/theme'

export const DateGlobalStyled = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 8px;
  z-index: 10;
  & * {
    box-sizing: border-box;
  }
  & label {
    top: -14px;
    left: -3px;
    position: absolute;
    transform: scale(0.75);
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: ${theme.typography.fontFamily};
    line-height: 10;
  }
  & > div {
    margin-top: 5px;
    & input {
      line-height: 20px;
      padding: 9px 11px 7px;
      font-size: 1rem;
      font-family: ${theme.typography.fontFamily};
      font-weight: normal;
    }
  }
`
export const SelectsGlobalStyled = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-top: -5px;
  max-width: 190px;
  & select {
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 20px;
    font-size: 0.9em;
  }
`
