import React from 'react'
import { Create } from 'react-admin'

import PromisesForm from './form'

export const PromisesCreate = (props) => (
  <Create {...props}>
    <PromisesForm {...props} />
  </Create>
)

export default PromisesCreate
