import React from 'react'
import { Create } from 'react-admin'

import CategoriesForm from './form'

export const CategoriesCreate = (props) => (
  <Create {...props}>
    <CategoriesForm {...props} />
  </Create>
)

export default CategoriesCreate
