import React from 'react'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { DELETE_ONE_FOOTBALL, GET_ONE_FOOTBALL, POST_ONE_FOOTBALL } from '../../../../../providers/dataProvider/types'
import { Card, CardHeader, CardContent, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { showNotification, FormDataConsumer } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

const styles = (theme) => {
  return {
    button: {
      marginLeft: '-12px',
    },
    warning: {
      color: theme.status.warning,
    },
    error: {
      color: theme.status.error,
    },
    success: {
      color: theme.status.success,
    },
    requestBg: {
      backgroundColor: 'white',
    },
  }
}

class OneFootball extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPublished: false,
      selectedNew: null,
    }
  }
  componentDidMount() {
    dataProvider(GET_ONE_FOOTBALL, getResource('news', 'onefootball'), {
      id: this.props.id,
      language: this.props.lang,
    })
      .then(({ data }) => {
        this.setState({
          selectedNew: data ? data[0] : null,
          isPublished: data.length > 0 ? true : false,
        })
      })
      .catch((error) => console.log(error))
  }

  handlePost = () => {
    const { id, showNotification, lang } = this.props

    dataProvider(POST_ONE_FOOTBALL, getResource('news', 'onefootball'), {
      id: id,
      language: lang,
      data: {},
    })
      .then(({ data }) => {
        showNotification(`${data.request} request send successfully`, 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
  }

  handleDelete = () => {
    const { id, showNotification, lang } = this.props
    dataProvider(DELETE_ONE_FOOTBALL, getResource('news', 'onefootball'), {
      id: id,
      language: lang,
    })
      .then(({ data }) => {
        showNotification(`${data.request} request send successfully`, 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
  }

  render() {
    const { classes } = this.props
    const { isPublished, selectedNew } = this.state

    return (
      <FormDataConsumer>
        {({ formData }) => (
          <Card>
            <CardHeader
              title="One Football"
              subheader={!isPublished || (selectedNew?.request === 'DELETE' && selectedNew?.status === 'unpublished') ? 'Unpublished' : ''}
            />
            <CardContent>
              {selectedNew && selectedNew.status !== 'unpublished' ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Card className={classes.requestBg}>
                      <CardHeader title={`Request: ${selectedNew.request}`} />
                      <CardContent>
                        <Typography variant="body1">
                          <b>Created At:</b> {new Date(selectedNew.createdAt).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1">
                          <b>Updated At:</b> {new Date(selectedNew.updatedAt).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1">
                          <b>Status:</b>{' '}
                          <span
                            className={selectedNew.status === 'pending' ? classes.warning : selectedNew.status === 'error' ? classes.error : classes.success}
                          >
                            {selectedNew.status}
                          </span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              <Grid item xs={12}>
                {!isPublished || (selectedNew.request === 'DELETE' && selectedNew.status === 'unpublished') ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={formData.status !== 'published' ? true : false}
                    onClick={() => this.handlePost()}
                  >
                    Publish
                  </Button>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handlePost}
                      disabled={formData.status !== 'published' || selectedNew.status !== 'published' ? true : false}
                    >
                      Update
                    </Button>
                    <Button onClick={this.handleDelete} disabled={formData.status !== 'published' || selectedNew.status !== 'published' ? true : false}>
                      Delete
                    </Button>
                  </div>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </FormDataConsumer>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles)
)

export default enhance(OneFootball)
