import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { SelectInput, ReferenceInput, BooleanInput } from 'react-admin'
import { getResource } from '../../../../config/resources'

const DigitalAssetForm = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <BooleanInput label="Full Width" source="config.fullWidth" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ReferenceInput label="Digital Asset" source="config.digitalAsset" reference={getResource('digitalAssets', 'digital-assets')} fullWidth>
          <SelectInput optionText="name" optionValue="slug" />
        </ReferenceInput>
      </Grid>
    </Grid>
  )
}

export default DigitalAssetForm
