import React from 'react'
import _ from 'lodash'
import { FunctionField } from 'react-admin'
import { StateStyled } from './style'

const MatchStatusField = ({ source, ...rest }) => {
  return (
    <FunctionField
      {...rest}
      render={(record) => {
        return <StateStyled status={_.get(record, source)}></StateStyled>
      }}
    />
  )
}

export default MatchStatusField
