import React from 'react'
import LanguagesNav from '../../../../components/languagesNav'
import RuleForm from './ruleForm'

export const ChannelMatchesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <RuleForm form="record-form" />
  </LanguagesNav>
)

export default ChannelMatchesForm
