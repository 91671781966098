import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import VideocamIcon from '@material-ui/icons/Videocam'
import _ from 'lodash'
import React from 'react'
import Dropzone from 'react-dropzone'
import slugify from 'slugify'

import { ConfirmationDialog } from '../../'
import API from '../../../config/api'
import { getResource } from '../../../config/resources'
import dataProvider from '../../../providers'
import httpClient from '../../../providers/dataProvider/httpClient'
import { CREATE_IMAGE } from '../../../providers/dataProvider/types'
import Cropper from './cropper'
import { CircularProgressContainerStyled, CropperStyled, EmptyStyled, LinkToFileStyled } from './style'

const { Aborter, BlockBlobURL, ContainerURL, ServiceURL, StorageURL, AnonymousCredential, uploadBrowserDataToBlockBlob } = require('@azure/storage-blob')

const styles = (theme) => ({
  dropzone: {
    width: '100%',
    maxWidth: '100%',
    height: '200px',
    backgroundColor: theme.palette.grey[300],
    border: `dashed 2px ${theme.palette.grey[700]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s',
    fontSize: '16px',
    '& span': {
      display: 'none',
      color: theme.palette.grey[700],
    },
    '& span.default': { display: 'block' },
  },
  dropzoneReject: {
    border: `solid 2px ${theme.palette.states.error}`,
    '& span.default': { display: 'none' },
    '& span.reject': { display: 'block' },
  },
  dropzoneAccept: {
    border: `solid 2px ${theme.palette.primary.main}`,
    '& span.default': { display: 'none' },
    '& span.accept': { display: 'block' },
  },
  iconFile: {
    fontSize: '51px',
    color: theme.palette.grey[400],
  },
})

const thumbsContainer = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  objectFit: 'contain',
}

class DropzoneWithPreview extends React.Component {
  constructor() {
    super()
    this.state = {
      fetching: false,
      modalFetching: false,
      file: null,
      upload: null,
    }

    this.reader = new FileReader()
  }

  getDefaultProps() {
    return {
      accept: 'image/*',
    }
  }

  onDrop(file) {
    if (file.length > 0) {
      if (this.isVideo(file[0].type)) {
        this.uploadVideo(file)
      } else {
        const { cropperOptions } = this.props
        const myFile = file[0]
        if (myFile)
          this.setState({
            fetching: true,
          })
        if (cropperOptions) {
          this.setState({
            image: URL.createObjectURL(myFile),
            type: file[0].type,
          })
        } else {
          this.getAsset(myFile)
        }
      }
    }
  }

  createMetadataAsset = (id, func) => {
    let url = `${API.assets.url}/videos/metadata-asset?id=` + id
    if (this.props.isPremium) {
      url += '&isPrivate=true'
    }
    httpClient(url, {
      method: 'GET',
    })
      .then((response) => {
        func(response.json)
      })
      .catch((e) => {})
  }

  getSasToken = (func) => {
    let url = `${API.assets.url}/videos/shared-token`
    if (this.props.isPremium) {
      url += '?isPrivate=true'
    }
    httpClient(url, { method: 'GET' })
      .then((response) => {
        func(response.json)
      })
      .catch((e) => {})
  }

  getAssetKey = (func) => {
    let url = `${API.assets.url}/videos/create-asset`
    if (this.props.isPremium) {
      url += '?isPrivate=true'
    }
    httpClient(url, { method: 'POST' })
      .then((response) => {
        func(response.json)
      })
      .catch((e) => {})
  }

  uploadVideo = (file) => {
    const { onChange } = this.props.input ? this.props.input : this.props
    const { onChangeProp } = this.props
    const account = this.props.isPremium ? process.env.REACT_APP_STORAGE_PREMIUM_ACCOUNT_KEY : process.env.REACT_APP_STORAGE_ACCOUNT_KEY
    const containerName = ''
    let sasToken = ''
    let asset = ''
    let assetIdParent = ''
    let size = 0
    this.setState({
      fetching: true,
      upload: 0,
    })
    this.getSasToken((token) => {
      sasToken = '?' + token.sas
      this.getAssetKey((response) => {
        asset = response.path
        assetIdParent = response.assetId
        const anonymousCredential = new AnonymousCredential()
        const pipeline = StorageURL.newPipeline(anonymousCredential)
        const serviceURL = new ServiceURL(`https://${account}.blob.core.windows.net` + asset + '/' + sasToken, pipeline)
        const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName)
        var fileName = this.normalizeName(file[0].name)
        size = file[0].size
        const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, fileName)
        var options = {
          blobHTTPHeaders: { blobContentType: file[0].type },
          progress: (progressEvent) => {
            this.setState({
              upload: Math.round((progressEvent.loadedBytes * 100) / size),
            })
          },
        }
        uploadBrowserDataToBlockBlob(Aborter.none, file[0], blockBlobURL, options).then(() => {
          this.createMetadataAsset(assetIdParent, ({ jobId, assetId, ...rest }) => {
            this.setState(
              {
                fetching: false,
              },
              () => {
                onChange({ assetId: assetId, jobId: jobId, fileDir: file[0].preview })
                if (onChangeProp) onChangeProp([{ assetId: assetId, jobId: jobId, fileDir: file[0].preview }])
              }
            )
          })
        })
      })
    })
  }

  getAsset(file) {
    const { base64 } = this.props
    const { onChange } = this.props.input ? this.props.input : this.props
    this.reader.onloadend = () => {
      if (base64) {
        onChange(this.reader.result)
        this.setState({
          error: null,
          fetching: false,
          image: null,
          modalFetching: null,
        })
      } else {
        this.setAsset(this.reader.result)
      }
    }
    if (base64) {
      this.reader.readAsDataURL(file)
    } else {
      this.reader.readAsArrayBuffer(file)
    }
    return {
      ...file,
      preview: URL.createObjectURL(file),
    }
  }

  setAsset(asset) {
    const { onChange } = this.props.input ? this.props.input : this.props
    this.setState({
      modalFetching: true,
    })
    return dataProvider(CREATE_IMAGE, getResource('assets', 'assets'), {
      data: asset,
    })
      .then((response) => {
        onChange(response.data)
        this.setState({
          error: null,
          fetching: false,
          image: null,
          modalFetching: null,
        })
      })
      .catch((error) => {
        this.setState({
          error: error,
          fetching: false,
          image: null,
          modalFetching: null,
        })
      })
  }

  handleImageLoaded(image) {
    const { naturalWidth, naturalHeight } = image
    const { cropperOptions } = this.props
    if (cropperOptions) {
      const { imageMinWidth, imageMinHeight } = cropperOptions
      if (imageMinWidth > naturalWidth || imageMinHeight > naturalHeight) {
        this.setState({
          error: `The minimum sizes are (${imageMinWidth}, ${imageMinHeight})`,
          image: null,
          fetching: false,
        })
      }
    }
  }

  isImage(type) {
    if (!type) return false
    return type.match(/^image.*$/) != null
    //return(type.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  isVideo(type) {
    if (!type) return false
    return type.match(/^video.*$/) != null
    //return(type.match(/\.(mp4|avi|mov)$/) != null);
  }

  normalizeName = (value) => {
    slugify.extend({ '/': '-' })
    return slugify(value, {
      replacement: '-',
      remove: /[*+/~\\,()'"¿?¡!:@&]/g,
      lower: true,
    })
  }

  render() {
    const { classes, label, meta, cropperOptions, accept, choices, source, isRequired, ...rest } = this.props
    const { value, onChange } = this.props.input ? this.props.input : this.props
    const { upload } = this.state
    const myValue = choices ? _.get(_.find(choices, { id: value }), source) : value

    return (
      <FormControl className={classes.formControl} error={(meta.error && meta.touched) || this.state.error} {...rest}>
        <InputLabel>{label}</InputLabel>
        <div className="dropzone">
          <Dropzone
            accept={accept}
            multiple={false}
            onDrop={this.onDrop.bind(this)}
            className={classes.dropzone}
            acceptClassName={classes.dropzoneAccept}
            rejectClassName={classes.dropzoneReject}
          >
            {this.state.fetching && (
              <EmptyStyled>
                <CircularProgressContainerStyled upload={upload || upload === 0 ? upload + '%' : ''}>
                  <CircularProgress size={50} />
                </CircularProgressContainerStyled>
              </EmptyStyled>
            )}
            {!this.state.fetching && !myValue && (
              <EmptyStyled>
                <span className="default">Drop File to upload, or click to select one</span>
                <span className="accept">Drop the file</span>
                <span className="reject">Wrong file</span>
              </EmptyStyled>
            )}
            {!this.state.fetching && myValue && (
              <aside style={thumbsContainer}>
                {((myValue.headers && myValue.headers.ContentType && this.isImage(myValue.headers.ContentType)) ||
                  !myValue.headers ||
                  !myValue.headers.length) && <img src={myValue.fileDir} style={img} alt="" />}
                {myValue.headers && myValue.headers.ContentType && this.isVideo(myValue.headers.ContentType) && <VideocamIcon className={classes.iconFile} />}
                {myValue.headers && myValue.headers.ContentType && !this.isVideo(myValue.headers.ContentType) && !this.isImage(myValue.headers.ContentType) && (
                  <InsertDriveFileIcon className={classes.iconFile} />
                )}
              </aside>
            )}
          </Dropzone>
          {myValue && (
            <LinkToFileStyled target="_blank" href={myValue.fileDir}>
              {myValue.fileDir}
            </LinkToFileStyled>
          )}
          {cropperOptions && (
            <ConfirmationDialog
              title="Cropper"
              isFetching={this.state.modalFetching}
              open={this.state.image}
              fullWidth={false}
              maxWidth="md"
              dialogActions={false}
              onCancel={() =>
                this.setState(
                  {
                    image: null,
                    fetching: false,
                    modalFetching: false,
                  },
                  () => {
                    onChange(null)
                  }
                )
              }
            >
              <CropperStyled>
                <Cropper
                  cropperOptions={cropperOptions}
                  value={this.state.image}
                  onSubmit={(file) => this.setAsset(file)}
                  onImageLoaded={(image) => this.handleImageLoaded(image)}
                  type={this.state.type}
                />
              </CropperStyled>
            </ConfirmationDialog>
          )}
        </div>
        {((meta.error && meta.touched) || this.state.error) && (
          <FormHelperText>
            {meta.error}
            <div>{this.state.error}</div>
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default withStyles(styles)(DropzoneWithPreview)
