import React from 'react'
import { TextInput, required, maxLength, ReferenceInput, SelectInput } from 'react-admin'
import { getResource } from '../../../../config/resources'
import Grid from '@material-ui/core/Grid'
import LanguagesNav from '../../../../components/languagesNav'

export const ImagesForm = ({ ...props }) => (
  <LanguagesNav {...props} removeDelete>
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput perPage={100} label="Parent" source="parent.id" reference={getResource('news', 'categories')} fullWidth>
          <SelectInput source="name" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput perPage={100} label="Digital asset" source="digitalAsset" reference={getResource('digitalAssets', 'digital-assets')} fullWidth>
          <SelectInput source="name" />
        </ReferenceInput>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ImagesForm
