import styled from 'styled-components'
import ReactCrop from 'react-image-crop'

import { colors } from '../../../config/theme'

export const EmptyStyled = styled.div`
  padding: 20px;
`

export const CropperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`

export const CropperContentStyled = styled.div`
  padding: 20px 0px;
`

export const LinkToFileStyled = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  color: ${colors.primary.main};
  display: block;
`

export const CircularProgressContainerStyled = styled.div`
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    content: '${(props) => props.upload}';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }
`

export const ReactCropStyled = styled(ReactCrop)`
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  .ReactCrop__drag-handle {
    height: 20px;
    width: 20px;
    &.ord-n {
      margin-top: -10px;
      margin-left: -10px;
    }
    &.ord-e {
      margin-top: -10px;
      margin-right: -10px;
    }
    &.ord-s {
      margin-bottom: -10px;
      margin-left: -10px;
    }
    &.ord-w {
      margin-top: -10px;
      margin-left: -10px;
    }
    &.ord-ne {
      margin-top: -10px;
      margin-right: -10px;
    }
    &.ord-nw {
      margin-top: -10px;
      margin-left: -10px;
    }
    &.ord-se {
      margin-bottom: -10px;
      margin-right: -10px;
    }
    &.ord-sw {
      margin-bottom: -10px;
      margin-left: -10px;
    }
  }
`
