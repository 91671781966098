import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, showNotification, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { DictionaryInput } from '../../../../../../components/inputs'

import { getResource } from '../../../../../../config/resources'
import { dataProvider } from '../../../../../../providers'

const styles = (theme) => {
  return {
    border: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      position: 'relative',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.1)',
      top: '0px',
      left: '0px',
    },
    itemDefault: {
      width: '100%',
      minHeight: '200px',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
}

class FormRound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      round: null,
      loadingRound: false,
    }
  }

  componentDidMount() {
    const { round } = this.props
    this.setState({ round: round })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.round === null && nextProps.round !== null) {
      this.setState({ round: nextProps.round })
    }
    return true
  }

  handleClickSaveRound() {
    const { fetchStart, fetchEnd, showNotification } = this.props
    const { round } = this.state

    if (round.name) {
      this.setState({
        loadingRound: true,
      })
      fetchStart()
      dataProvider(UPDATE, getResource('competiciones', 'rounds'), {
        id: round.id,
        data: round,
      })
        .then((response) => {
          showNotification('Elements updated', 'success')

          this.setState({
            loadingRound: false,
            round: response.data,
          })
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  handleChangeInput = (name) => (event) => {
    let round = this.state.round

    round[name] = event.target.value
    this.setState({
      round: round,
    })
  }
  handleChangeSwitch = (name) => (event) => {
    let round = { ...this.state.round }

    round[name] = event.target.checked
    this.setState({
      round: round,
    })
  }

  handleChangeDictionaries(values) {
    let round = { ...this.state.round, ...values }

    this.setState({ round: round })
  }

  render() {
    const { classes } = this.props
    const { round } = this.state

    if (this.state.round) {
      return (
        <Grid container className={classes.border}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h2" headlineMapping="h2">
              Round
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <TextField
              id="name"
              label="Name *"
              margin="normal"
              fullWidth={true}
              error={round.name ? false : true}
              value={round.name}
              onChange={this.handleChangeInput('name')}
            />
            <TextField id="slug" label="Slug" margin="normal" fullWidth={true} disabled={true} value={round.slug} />
            <div className={classes.row}>
              <FormControl>
                <InputLabel htmlFor="round-status">Status</InputLabel>
                <Select
                  value={round.status}
                  onChange={this.handleChangeInput('status')}
                  inputProps={{
                    name: 'status',
                    id: 'round-status',
                  }}
                >
                  <MenuItem value="visible">Visible</MenuItem>
                  <MenuItem value="hidden">Hidden</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Switch checked={round.standingLive} onChange={this.handleChangeSwitch('standingLive')} color="primary" />}
                label="Standing Live"
              />
            </div>
            <DictionaryInput
              label="Dictionary"
              sources={['performId', 'optaId']}
              data={round}
              onChange={(values) => this.handleChangeDictionaries(values)}
              isControlled
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField id="type" label="Type" margin="normal" fullWidth={true} disabled={true} value={round.type} />
            <TextField id="groups" label="Goups" margin="normal" fullWidth={true} disabled={true} value={round.groups ? 'Yes' : 'No'} />
            <Button onClick={() => this.handleClickSaveRound()} fullWidth={true} variant="contained" color="primary" size="small" aria-label="Save">
              Save
            </Button>
          </Grid>
          {this.state.loadingRound && (
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
        </Grid>
      )
    } else {
      return (
        <Grid container className={classes.border}>
          <Grid item xs={12}>
            <div className={classes.itemDefault} />
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          </Grid>
        </Grid>
      )
    }
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(FormRound)
