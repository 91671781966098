import _ from 'lodash'
import React from 'react'

import AreaList from './areaList'
import { ModuleGridItemStyled } from './style'

export default class ServiceCommandUnit extends React.Component {
  render() {
    const { subItems, type, id, sections, ...rest } = this.props
    const { indexSection } = this.props
    const areasPercentage = _.find(sections, { id: type }).areas
    const typeList = type.toString().split('')
    return (
      <ModuleGridItemStyled>
        {_.map(typeList, (item, index) => {
          const list = index + 1
          return (
            <AreaList
              {...rest}
              key={list}
              indexModule={list}
              type={`${indexSection}/${list}`}
              subItems={subItems[parseInt(list)] ? subItems[parseInt(list)] : []}
              typeArea={type}
              area={areasPercentage[index]}
              numArea={index + 1}
            />
          )
        })}
      </ModuleGridItemStyled>
    )
  }
}
