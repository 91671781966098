import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'

import { ListActions } from '../../../components'

const NationalitiesFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<NationalitiesFilters />} />
}

export const NationalitiesList = (props) => {
  return (
    <List bulkActions={false} title="Nationalities" {...props} actions={<Actions />}>
      <Datagrid>
        <TextField source="id" label="ISO" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  )
}

export default NationalitiesList
