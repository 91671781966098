import React from 'react'
import { Create } from 'react-admin'

import ExperienceForm from './form'

export const ExperienceCreate = (props) => (
  <Create {...props}>
    <ExperienceForm {...props} />
  </Create>
)

export default ExperienceCreate
