import React from 'react'
import { List, ListItem, ListItemText, Collapse, Button, Grid, withStyles } from '@material-ui/core'
import { BooleanInput, maxLength, required, TextInput } from 'react-admin'
import { DateTimeInput, ImageInput } from '../../../../../../../components/inputs'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = (theme) => ({
  row: {
    position: 'relative',
    background: theme.palette.grey[50],
    marginBottom: theme.spacing.unit,
    display: 'flex',
  },
  deleteButton: {
    marginRight: '16px',
  },
  drag: {
    flex: '0 0 35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const FORM = 'record-form'
const selector = formValueSelector(FORM)

class DrawItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  handleRemove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.handleRemove(this.props.index)
  }

  render() {
    const { field, source, classes, isDragged } = this.props

    return (
      <List>
        <ListItem button onClick={this.handleClick}>
          <div className={classes.drag} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
            <DragHandleIcon />
          </div>
          <ListItemText primary={field(`${source}.title`)} />
          <Button className={classes.deleteButton} variant="fab" color="secondary" aria-label="Remove" onClick={this.handleRemove} mini>
            <DeleteIcon fontSize={12} />
          </Button>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <div className={classes.row}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <TextInput fullWidth source={`${source}.title`} label="Draw Item Title" validate={[required(), maxLength(255)]} />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <TextInput fullWidth source={`${source}.buttonTitle`} label="Button Title" validate={[required(), maxLength(255)]} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput fullWidth source={`${source}.buttonLink`} label="Button Link" validate={[required()]} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DateTimeInput label="Started At" source={`${source}.startDate`} date time validate={[required()]} />
              </Grid>
              <Grid item xs={12}>
                <DateTimeInput label="Ends At" source={`${source}.endDate`} date time validate={[required()]} />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <ImageInput label="Draw Image XL" source={`${source}.image`} validate={[required()]} />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput label="Draw Image Mobile" source={`${source}.imageMobile`} validate={[required()]} />
                </Grid>
                <Grid item xs={12}>
                  <BooleanInput label="Active" source={`${source}.active`} validate={[required()]} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </List>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(DrawItem)
