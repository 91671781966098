import get from 'lodash/get'
import React, { Component } from 'react'

import { colors } from '../../../config/theme'
import { StateFieldStyled } from './style'

class StateField extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { record, state } = this.props

    if (!record) return null
    const valueState = state ? get(record, state) : null
    let stateColor = 'transparent'
    let stateColorBorder = 'transparent'
    switch (valueState) {
      case 'published':
        stateColor = colors.states.successLight
        stateColorBorder = colors.states.success
        break
      case 'draft':
        stateColor = colors.states.warningLight
        stateColorBorder = colors.states.warning
        break
      case 'hidden':
        stateColor = colors.states.errorLight
        stateColorBorder = colors.states.error
        break
      default:
    }

    return <StateFieldStyled {...this.props} empty={this.state.value} stateColor={stateColor} stateColorBorder={stateColorBorder} />
  }
}

export default StateField
