import React from 'react'
import { connect } from 'react-redux'
import { Typography, CircularProgress, Button, Grid, Collapse, Select, MenuItem } from '@material-ui/core'
import _ from 'lodash'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { GET_LIST, showNotification, UPDATE } from 'react-admin'
import { ConfirmationDialog } from '../../../../../components'

const guestModeStates = [
  { state: 'guest', name: 'On' },
  { state: 'mandatory', name: 'Off' },
]

class GuestMode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      openList: false,
      loading: false,
      fetching: false,
      guestMode: null,
    }
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleSaveData = this.handleSaveData.bind(this)
  }

  componentDidMount() {
    this.getGuestmode()
  }

  handleModalOpen() {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose() {
    this.getGuestmode()
    this.setState({
      isModalOpen: false,
    })
  }

  handleGuestMode(event, section, device) {
    const copyGuestMode = { ...this.state.guestMode }
    if (section === 'loginMode') {
      copyGuestMode.contentJson.loginMode[device] = event.target.value
    }
    this.setState({ guestMode: copyGuestMode })
  }

  handleSaveData() {
    this.setState({ fetching: true })
    let data = { ...this.state.guestMode }
    data.contentJson = JSON.stringify(data.contentJson)
    dataProvider(UPDATE, getResource('transversal', 'globaldatas'), {
      id: this.state.guestMode.id,
      data: data,
    })
      .then(({ data }) => {
        this.setState({ fetching: false, guestMode: data, isModalOpen: false })
        this.props.showNotification('Guest mode state updated', 'success')
      })
      .catch((error) => {
        this.setState({ error: true, fetching: false, isModalOpen: false })
        this.props.showNotification(error.message, 'error')
      })
  }

  getGuestmode = () => {
    this.setState({ loading: true })
    dataProvider(GET_LIST, getResource('transversal', 'globaldatas'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      filter: {
        name: 'APP_CONFIG',
      },
      pagination: { page: 1, perPage: 20 },
    }).then((res) => {
      this.setState({ guestMode: res.data[0], loading: false })
    })
  }

  handleClick = () => {
    this.setState({
      openList: !this.state.openList,
    })
  }
  render() {
    return (
      <>
        <div style={{ margin: '29px' }}>
          {this.state.loading ? (
            <div style={{ display: 'flex' }}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                Loading Guest mode
              </Typography>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => this.handleClick()}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px' }}>
                Guest Mode
              </Typography>
              <div style={{ marginLeft: '16px' }}>{this.state.openList ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
          )}
          {this.state.guestMode && this.state.openList && (
            <Collapse in={this.state.openList} timeout={350}>
              <Grid item xs={12} lg={8}>
                <div style={{ opacity: this.state.loading ? 0 : 1, transition: 'opacity 350ms' }}>
                  <Grid item xs={12} lg={8}>
                    <Typography>IOS:</Typography>
                    <Select
                      value={this.state.guestMode?.contentJson?.loginMode?.ios}
                      onChange={(event) => this.handleGuestMode(event, 'loginMode', 'ios')}
                      fullWidth
                    >
                      {_.map(guestModeStates, (item) => (
                        <MenuItem key={item.state} value={item.state}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Typography>Android:</Typography>
                    <Select
                      value={this.state.guestMode?.contentJson?.loginMode?.android}
                      onChange={(event) => this.handleGuestMode(event, 'loginMode', 'android')}
                      fullWidth
                    >
                      {_.map(guestModeStates, (item) => (
                        <MenuItem key={item.state} value={item.state}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={this.state.loading || this.state.fetching}
                      onClick={() => this.handleModalOpen()}
                    >
                      {this.state.fetching ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Collapse>
          )}
        </div>
        <ConfirmationDialog
          title="Descargo de responsabilidad:"
          open={this.state.isModalOpen}
          maxWidth="sm"
          onCancel={this.handleModalClose}
          onSuccess={this.handleSaveData}
          isFetching={this.state.fetching}
        >
          <Typography>
            Esto modifica y permite habilitar o no el flujo de GUEST en toda la App Oficial. IMPORTANTE antes de hacer ningún cambio confirma con el equipo de
            Producto de LALIGA.
          </Typography>
        </ConfirmationDialog>
      </>
    )
  }
}

const enhance = connect(null, {
  showNotification,
})

export default enhance(GuestMode)
