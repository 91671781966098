import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ContainerStyled = styled.div`
  background-color: ${colors.common.white};
`

export const VideoProviderContainerStyled = styled.div`
  padding: 10px;
  display: flex;
  align-items: flex-end;
`
export const VideoProviderLabelStyled = styled.div`
  margin-left: 5px;
  padding: 10px;
  font-size: 13px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
`
