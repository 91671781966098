import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import React, { Component } from 'react'
import { DELETE, fetchEnd, fetchStart, FormDataConsumer, GET_LIST, GET_ONE, ReferenceInput, showNotification, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change } from 'redux-form'

import { ConfirmationDialog } from '../../../../../components'
import { AutocompleteInput, DateSingleInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import { dataProvider } from '../../../../../providers'
import SubgroupManagementInput from './SubgroupManagementInput'

const styles = (theme) => {
  return {
    trashIcon: {
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.error.dark,
        cursor: 'pointer',
      },
    },
    textAlignRight: {
      textAlign: 'right',
    },
    marginRight: {
      marginRight: '10px',
    },
    marginBottom: {
      marginBottom: '16px',
    },
  }
}

class Subscriptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCompetition: null,
      idSeasonOpen: null,
      subscriptions: null,
      teams: null,
      teamSelected: null,
      rounds: null,
      gameweeks: null,
      dialogOpen: false,
      showTeamsSection: false,
    }
  }

  componentDidMount() {
    const { idSeasonOpen, idCompetition, idSubscriptionOpen } = this.props

    this.setState({
      idCompetition: idCompetition,
      idSeasonOpen: idSeasonOpen,
      idSubscription: idSubscriptionOpen,
    })
    if (idSeasonOpen !== null) {
      this.listSubscription(idSubscriptionOpen)
      this.listRounds(idSubscriptionOpen)
    }
  }

  listSubscription(idSubscription) {
    dataProvider(GET_ONE, getResource('competiciones', 'subscriptions'), {
      id: idSubscription,
    }).then(({ data }) => {
      let subscription = { ...data }
      if (subscription.currentGameweek) {
        this.listGameweeks(subscription.currentGameweek.round.id)
      }
      this.setState({ subscriptions: subscription })
    })
  }

  listRounds(idSubscription) {
    dataProvider(GET_LIST, getResource('competiciones', 'rounds'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        subscription: idSubscription,
      },
    }).then(({ data }) => {
      this.setState({
        rounds: data,
      })
    })
  }

  listGameweeks(idRound) {
    dataProvider(GET_LIST, getResource('competiciones', 'gameweeks'), {
      sort: {
        field: 'week',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        round: idRound,
      },
    }).then(({ data }) => {
      this.setState({
        gameweeks: data,
      })
    })
  }

  handleShowTeamSection() {
    this.setState({ showTeamsSection: !this.state.showTeamsSection })
  }

  handleDeleteTeam(index) {
    let subscriptions = this.state.subscriptions
    subscriptions.subscriptionTeams.splice(index, 1)

    this.setState({ subscriptions: subscriptions })
  }

  handleAddTeam(teamSelected) {
    const { resetTeamSelected } = this.props
    let { subscriptions } = this.state

    if (_.findIndex(subscriptions.subscriptionTeams, ['team.id', parseInt(teamSelected)]) === -1) {
      dataProvider(GET_ONE, getResource('competiciones', 'teams'), {
        id: teamSelected,
      }).then(({ data }) => {
        subscriptions.subscriptionTeams.unshift({
          team: data,
        })

        this.setState({
          subscriptions: subscriptions,
        })

        resetTeamSelected()
      })
    } else {
      resetTeamSelected()
    }
  }

  handleChange = (name) => (event) => {
    let subscriptions = { ...this.state.subscriptions }
    const value = event?.target?.value ? event?.target.value : event

    if (_.get(subscriptions, name) !== value) {
      _.set(subscriptions, name, value)
      if (name === 'currentGameweek.round.id') {
        _.set(subscriptions, 'currentGameweek.id', null)
        if (value) {
          this.listGameweeks(value)
        }
      }
      this.setState({ subscriptions: subscriptions })
    }

    if (name === 'currentApp') {
      this.setState({
        subscriptions: {
          ...subscriptions,
          currentApp: event.target.checked,
        },
      })
    }
  }

  handleChangeDate(name, value) {
    let subscriptions = this.state.subscriptions
    subscriptions[name] = value
    this.setState({ subscriptions: subscriptions })
  }

  saveSubscriptions = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(UPDATE, getResource('competiciones', 'subscriptions'), {
      id: this.state.subscriptions.id,
      data: this.state.subscriptions,
    })
      .then((response) => {
        showNotification('Elements updated', 'success')
        // this.props.handleClose()
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  deleteSubscription = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(DELETE, getResource('competiciones', 'subscriptions'), {
      id: this.props.idSubscriptionOpen,
    })
      .then((response) => {
        this.props.handleClose()
        showNotification('Elements deleted', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  handleAddSubgroup = () => {
    let subscriptions = this.state?.subscriptions ? this.state.subscriptions : []
    const newEmptySubgroup = {
      color: '#000000',
      colorApp: '#000000',
      start: 1,
      end: 1,
      name: [
        {
          name: '',
          locale: 'es',
        },
        {
          name: '',
          locale: 'en',
        },
      ],
      shortname: [
        {
          name: '',
          locale: 'es',
        },
        {
          name: '',
          locale: 'en',
        },
      ],
    }
    if (subscriptions.qualify !== null) {
      subscriptions.qualify.push(newEmptySubgroup)
    } else {
      subscriptions.qualify = []
      subscriptions.qualify.push(newEmptySubgroup)
    }
    this.setState({ subscriptions: subscriptions })
  }

  handleDeleteSubgroup = (index) => {
    let subscriptions = this.state.subscriptions
    subscriptions.qualify.splice(index, 1)
    this.setState({ subscriptions: subscriptions })
  }

  handleChangeColorSubgroup = (color, name, subgroupIndex) => {
    let subscriptions = this.state.subscriptions
    subscriptions.qualify[subgroupIndex][name] = color
    this.setState({ subscriptions: subscriptions })
  }

  render() {
    const { classes } = this.props
    const { subscriptions, rounds, gameweeks, showTeamsSection } = this.state

    return (
      <div>
        {subscriptions && (
          <Grid container justify="flex-start">
            <Grid item xs={12} className={classes.textAlignRight}>
              <Button variant="contained" className={classes.marginRight} onClick={() => this.setState({ dialogOpen: true })}>
                Delete
              </Button>
              <Button variant="contained" color="primary" onClick={this.saveSubscriptions}>
                Save
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="competitionName"
                label="Competition Name"
                value={subscriptions.competitionName}
                fullWidth={true}
                onChange={this.handleChange('competitionName')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateSingleInput label="Date Ini" value={subscriptions.dateIni} onChange={(value) => this.handleChangeDate('dateIni', value)} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateSingleInput label="Date End" value={subscriptions.dateEnd} onChange={(value) => this.handleChangeDate('dateEnd', value)} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="opta-standing">Opta Standing</InputLabel>
                <Select
                  value={subscriptions.optaStanding}
                  onChange={this.handleChange('optaStanding')}
                  fullWidth
                  inputProps={{
                    name: 'optaStanding',
                    id: 'opta-standing',
                  }}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="opta-standing">Current Round</InputLabel>
                <Select
                  value={subscriptions.currentGameweek ? subscriptions.currentGameweek.round.id : null}
                  onChange={this.handleChange('currentGameweek.round.id')}
                  fullWidth
                  inputProps={{
                    name: 'currentGameweek.round.id',
                    id: 'current-round',
                  }}
                >
                  {rounds &&
                    rounds.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="opta-standing">Current Gameweek</InputLabel>
                <Select
                  value={subscriptions.currentGameweek ? subscriptions.currentGameweek.id : null}
                  onChange={this.handleChange('currentGameweek.id')}
                  fullWidth
                  inputProps={{
                    name: 'currentGameweek.id',
                    id: 'current-gameweek',
                  }}
                >
                  {gameweeks &&
                    gameweeks.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal">
                <InputLabel htmlFor="currentApp">Current in APP</InputLabel>
                <Switch
                  checked={subscriptions.currentApp === true}
                  color="primary"
                  value={subscriptions.currentApp}
                  name="currentApp"
                  onChange={this.handleChange('currentApp')}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {subscriptions && (
          <Button color="primary" variant="contained" onClick={() => this.handleShowTeamSection()} className={classes.marginBottom}>
            Teams {showTeamsSection ? <ExpandLess /> : <ExpandMore />}
          </Button>
        )}
        <Collapse in={showTeamsSection} timeout="auto">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <ReferenceInput label="Team" source="subscriptionTeam" reference={getResource('competiciones', 'teams')}>
                  <AutocompleteInput optionText={(choice) => `${choice.selectorName} ${choice.type ? `(${choice.type})` : ''}`} />
                </ReferenceInput>

                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData &&
                    formData.subscriptionTeam && (
                      <IconButton color="primary" onClick={() => this.handleAddTeam(formData.subscriptionTeam)}>
                        <AddIcon />
                      </IconButton>
                    )
                  }
                </FormDataConsumer>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <List dense={true}>
                {subscriptions &&
                  subscriptions.subscriptionTeams.map((item, key) => {
                    return (
                      <ListItem key={key}>
                        <ListItemText primary={item.team.selectorName} />
                        <ListItemSecondaryAction>
                          <DeleteIcon className={classes.trashIcon} aria-label="Delete" onClick={() => this.handleDeleteTeam(key)} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
        </Collapse>
        {subscriptions && <SubgroupManagementInput value={subscriptions.qualify} onChange={this.handleChange('qualify')} />}
        <ConfirmationDialog
          open={this.state.dialogOpen}
          title="Are you sure?"
          onCancel={() => {
            this.setState({
              dialogOpen: false,
            })
          }}
          onSuccess={() => {
            this.deleteSubscription()
          }}
        />
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      resetTeamSelected: () => dispatch(change('record-form', 'subscriptionTeam', '')),
    })
  ),
  withStyles(styles)
)

export default enhance(Subscriptions)
