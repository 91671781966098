import React from 'react'
import _get from 'lodash/get'
import { Grid } from '@material-ui/core'
import { ColorPicker, MatchInput } from '../../../../../../../components/inputs'
import { required, ReferenceInput, SelectInput, fetchEnd, fetchStart, showNotification, GET_ONE, maxLength, BooleanInput } from 'react-admin'
import { getResource } from '../../../../../../../config/resources'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import compose from 'recompose/compose'
import dataProvider from '../../../../../../../providers'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

class General extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    const { change, id, field } = this.props
    const match = field('match')
    if (id) {
      dataProvider(GET_ONE, getResource('competiciones', 'matches'), {
        id: match,
      }).then(({ data }) => {
        change(FORM, 'tempSeason', _get(data, 'season.id'))
        change(FORM, 'tempCompetition', _get(data, 'competition.id'))
        change(FORM, 'tempSubscription', _get(data, 'subscription.id'))
        change(FORM, 'tempRound', _get(data, 'gameweek.round.id'))
        change(FORM, 'tempGameweek', _get(data, 'gameweek.id'))
      })
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <MatchInput
            validate={[required()]}
            sourceSeason="tempSeason"
            sourceCompetition="tempCompetition"
            sourceSubscription="tempSubscription"
            sourceRound="tempRound"
            sourceGameweek="tempGameweek"
            sourceMatch="match"
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput source="metadataOtherId" reference={getResource('assets', 'metadataothers')}>
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ColorPicker fullWidth label="Color" name="color" source="color" validate={[required(), maxLength(7)]} />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput fullWidth label="Active" source="active" />
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  )
)

export default enhance(General)
