import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

const styles = (theme) => ({
  root: {
    '& tr': {
      backgroundColor: 'transparent',
      '& td': {
        verticalAlign: 'middle',
      },
    },
  },
})

const Dictionary = ({ classes, label, sources = [], data = {}, onChange }) => {
  const handleChange = (source, value) => {
    let newData = { ...data }
    newData[source] = value
    onChange(newData)
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>{label}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sources.map((source) => (
          <TableRow key={source}>
            <TableCell>{source}</TableCell>
            <TableCell>
              <TextField value={data[source]} onChange={(e) => handleChange(source, e.target.value)} fullWidth />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(Dictionary)
