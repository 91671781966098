import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { CREATE, fetchEnd, fetchStart, showNotification, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { ConfirmationDialog, SubForm } from '../../../../../components'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import AssetForm from './assetForm'

const FORM = 'squads-form'
const selector = formValueSelector(FORM)

const styles = (theme) => {
  return {
    ToolbarBotton: {
      marginLeft: '10px',
    },
  }
}

class AddAsset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormFetching: false,
    }
  }

  componentDidMount() {
    const { initialize } = this.props
    initialize(FORM, {
      bMAssetGroup: null,
      asset: null,
    })
  }

  updateBrandMarketAsset(imgData) {
    const { fetchEnd, showNotification, onCreate, id } = this.props
    dataProvider(UPDATE, getResource('digitalAssets', 'brand-market-assets'), {
      id: id,
      data: { ...imgData },
    })
      .then(({ data }) => {
        onCreate()
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
        this.setState({
          isFormFetching: false,
        })
      })
  }

  editBMAsset(imgData) {
    const { fetchStart, fetchEnd, showNotification } = this.props
    let newImage = {
      name: 'Brand Market',
      caption: 'Brand Market',
      asset: imgData.asset,
    }

    fetchStart()
    this.setState({
      isFormFetching: true,
    })
    if (imgData.asset && imgData.asset.id) {
      dataProvider(CREATE, getResource('assets', 'images'), {
        data: newImage,
      })
        .then(({ data }) => {
          const mbAsset = { ...imgData, asset: data.id }
          this.updateBrandMarketAsset(mbAsset)
        })
        .catch((error) => {
          showNotification(error.message, 'error')
          fetchEnd()
          this.setState({
            isFormFetching: false,
          })
        })
    } else {
      const mbAsset = { ...imgData }
      this.updateBrandMarketAsset(mbAsset)
    }
  }

  render() {
    const { slugBMAGKind, isSubmitting, onCancel, open, record } = this.props
    return (
      <ConfirmationDialog
        title="Edit"
        isFetching={isSubmitting || this.state.isFormFetchings}
        open={open}
        fullWidth={false}
        maxWidth="md"
        dialogActions={false}
        onCancel={() => onCancel()}
      >
        <SubForm
          submitOnEnter={false}
          record={record}
          resource={getResource('digitalAssets', 'brand-market-assets')}
          onClose={() => onCancel()}
          form="asset-form"
          saving={this.state.isFormFetching}
          onClickSave={(data) => this.editBMAsset(data)}
        >
          <AssetForm
            slugBMAGKind={slugBMAGKind}
            assetTypeOptions={{
              disabled: true,
            }}
          />
        </SubForm>
      </ConfirmationDialog>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(AddAsset)
