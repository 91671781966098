import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { PluginContainerStyled, PluginStyled } from './style'

class DndItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDragDisabled: false,
    }
  }

  _handleDelete(event) {
    this.props.deteleBlock()
  }

  _handleHighlight(event) {
    this.props.highlightBlock()
  }

  _setDisabled(isDragDisabled) {
    this.setState({
      isDragDisabled: isDragDisabled,
    })
  }

  render() {
    const { children } = this.props
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        setDisabled: (isDragDisabled) => this._setDisabled(isDragDisabled),
        deteleBlock: () => this._handleDelete(),
      })
    )
    return (
      <Draggable draggableId={this.props.id} index={this.props.index} isDragDisabled={this.state.isDragDisabled}>
        {(provided) => (
          <PluginContainerStyled {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
            <PluginStyled>{childrenWithProps}</PluginStyled>
          </PluginContainerStyled>
        )}
      </Draggable>
    )
  }
}

export default DndItem
