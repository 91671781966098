import React, { Component } from 'react'
import TextPluginEdit from './textPluginEdit'
import Plugin from '../plugin'

class TextPlugin extends Component {
  render() {
    return <Plugin name="textPlugin" {...this.props} component={TextPluginEdit} />
  }
}

export default TextPlugin
