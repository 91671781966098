import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'
import { FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin'
import { connect } from 'react-redux'
import { change, FormName } from 'redux-form'

import { getResource } from '../../../config/resources'

const SelectMatch = ({ choices, ...rest }) => {
  const optionRenderer = (choices) => `${choices.homeTeam.selectorName} vs ${choices.awayTeam.selectorName} `

  return <SelectInput choices={choices} optionText={optionRenderer} {...rest} />
}

class MatchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: null,
      ready: {
        subscription: true,
        round: true,
        gameweek: true,
        match: true,
      },
      perPage: 50,
    }
  }

  componentDidMount() {
    this.setState({
      source: {
        season: this.props.sourceSeason,
        competition: this.props.sourceCompetition,
        subscription: this.props.sourceSubscription,
        round: this.props.sourceRound,
        gameweek: this.props.sourceGameweek,
        match: this.props.sourceMatch,
      },
    })
  }

  resetSelects(formName, field) {
    const { source } = this.state
    let ready = { ...this.state.ready }

    switch (field) {
      case 'seasons':
      case 'competition':
        ready.subscription = false
        ready.round = false
        ready.gameweek = false
        ready.match = false
        break
      case 'subscription':
        ready.round = false
        ready.gameweek = false
        ready.match = false
        break
      case 'round':
        ready.gameweek = false
        ready.match = false
        break
      case 'gameweek':
        ready.match = false
        break
      default:
    }

    this.setState(
      {
        ready: ready,
      },
      () => {
        switch (field) {
          case 'seasons':
          case 'competition':
            this.props.changeField(formName, source.subscription, null)
            this.props.changeField(formName, source.round, null)
            this.props.changeField(formName, source.gameweek, null)
            this.props.changeField(formName, source.match, null)
            break
          case 'subscription':
            this.props.changeField(formName, source.round, null)
            this.props.changeField(formName, source.gameweek, null)
            this.props.changeField(formName, source.match, null)
            break
          case 'round':
            this.props.changeField(formName, source.gameweek, null)
            this.props.changeField(formName, source.match, null)
            break
          case 'gameweek':
            this.props.changeField(formName, source.match, null)
            break
          default:
        }
        this.setState({
          ready: {
            subscription: true,
            round: true,
            gameweek: true,
            match: true,
          },
        })
      }
    )
  }

  render() {
    const { source, ready } = this.state

    let seasonFilters = { showInWeb: 1 }
    if (this.props.filterSeason) {
      seasonFilters = { ...seasonFilters, ...this.props.filterSeason }
    }

    if (source === null) return null
    return (
      <FormName>
        {({ form: formName }) => (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              let form = false

              if (formData) {
                form = formData
              } else if (this.props.filterForm) {
                form = this.props.filterForm
              }

              if (!form) return null

              return (
                <Grid container>
                  <Grid item xs={12} sm={6} md={4}>
                    <ReferenceInput
                      allowEmpty
                      alwaysOn
                      onChange={() => this.resetSelects(formName, 'seasons')}
                      label="Season"
                      source={source.season}
                      reference={getResource('competiciones', 'seasons')}
                      perPage={this.state.perPage}
                      validate={this.props.validate}
                      filter={seasonFilters}
                    >
                      <SelectInput allowEmpty fullWidth optionText="name" disabled={this.props.disabledSeason} />
                    </ReferenceInput>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <ReferenceInput
                      allowEmpty
                      alwaysOn
                      onChange={() => this.resetSelects(formName, 'competition')}
                      label="Competition"
                      source={source.competition}
                      reference={getResource('competiciones', 'competitions')}
                      perPage={this.state.perPage}
                      sort={{ field: 'id', order: 'ASC' }}
                      validate={this.props.validate}
                      filter={this.props.filterCompetition}
                    >
                      <SelectInput label="Competition" allowEmpty fullWidth optionText="name" optionValue="id" disabled={this.props.disabledCompetition} />
                    </ReferenceInput>
                  </Grid>

                  {ready.subscription && source.subscription && _.get(form, source.competition) && _.get(form, source.season) && (
                    <Grid item xs={12} sm={6} md={4}>
                      <ReferenceInput
                        allowEmpty
                        alwaysOn
                        onChange={() => this.resetSelects(formName, 'subscription')}
                        label="Subscription"
                        source={source.subscription}
                        reference={getResource('competiciones', 'subscriptions')}
                        filter={{ competition: _.get(form, source.competition), season: _.get(form, source.season) }}
                        perPage={this.state.perPage}
                        validate={this.props.validate}
                      >
                        <SelectInput label="Subscription" allowEmpty fullWidth optionText="competitionName" disabled={this.props.disabledSubscription} />
                      </ReferenceInput>
                    </Grid>
                  )}

                  {ready.round && source.round && _.get(form, source.competition) && _.get(form, source.season) && _.get(form, source.subscription) && (
                    <Grid item xs={12} sm={6} md={4}>
                      <ReferenceInput
                        allowEmpty
                        alwaysOn
                        onChange={() => this.resetSelects(formName, 'round')}
                        label="Round"
                        source={source.round}
                        reference={getResource('competiciones', 'rounds')}
                        filter={{
                          competition: _.get(form, source.competition),
                          season: _.get(form, source.season),
                          subscription: _.get(form, source.subscription),
                        }}
                        perPage={this.state.perPage}
                        validate={this.props.validate}
                      >
                        <SelectInput label="Round" allowEmpty fullWidth optionText="name" disabled={this.props.disabledRound} />
                      </ReferenceInput>
                    </Grid>
                  )}

                  {ready.gameweek &&
                    source.gameweek &&
                    _.get(form, source.competition) &&
                    _.get(form, source.season) &&
                    _.get(form, source.subscription) &&
                    _.get(form, source.round) && (
                      <Grid item xs={12} sm={6} md={4}>
                        <ReferenceInput
                          allowEmpty
                          alwaysOn
                          onChange={() => this.resetSelects(formName, 'gameweek')}
                          label="Game week"
                          source={source.gameweek}
                          reference={getResource('competiciones', 'gameweeks')}
                          filter={{ round: _.get(form, source.round) }}
                          perPage={this.state.perPage}
                          validate={this.props.validate}
                        >
                          <SelectInput label="Game week" allowEmpty fullWidth optionText="name" disabled={this.props.disabledGameweek} />
                        </ReferenceInput>
                      </Grid>
                    )}

                  {ready.match &&
                    source.match &&
                    _.get(form, source.competition) &&
                    _.get(form, source.season) &&
                    _.get(form, source.subscription) &&
                    _.get(form, source.round) &&
                    _.get(form, source.gameweek) && (
                      <Grid item xs={12} sm={6} md={4}>
                        <ReferenceInput
                          allowEmpty
                          alwaysOn
                          label="Match"
                          source={source.match}
                          reference={getResource('competiciones', 'matches')}
                          filter={{ gameweek: _.get(form, source.gameweek) }}
                          perPage={this.state.perPage}
                          validate={this.props.validate}
                        >
                          <SelectMatch label="Match" allowEmpty fullWidth disabled={this.props.disabledMatch} />
                        </ReferenceInput>
                      </Grid>
                    )}
                </Grid>
              )
            }}
          </FormDataConsumer>
        )}
      </FormName>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    filterForm: state.form.filterForm ? state.form.filterForm.values : null,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (form, field, value) => dispatch(change(form, field, value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchInput)
