import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { Filter, List, Responsive, SimpleList, Datagrid, TextField, DateField, EditButton, CloneButton, TextInput, BooleanField } from 'react-admin'
import { ListActions, TableRowList } from '../../../components'

const styles = () => ({
  breakWord: {
    wordBreak: 'break-all',
    minWidth: '200px',
    display: 'inline-block',
  },
})

const WSCFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={9}>
          <TextInput source="title" label="Title" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextInput source="orientation" label="Orientation" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<WSCFilter />} />
}

const WSCList = ({ actions, title, ...rest }) => {
  return (
    <List perPage={25} bulkActions={false} title="WSC" actions={<Actions />} {...rest}>
      <Responsive
        medium={
          <SimpleList
            primaryText={(record) => <span className={rest.classes.breakWord}>{record.title}</span>}
            secondaryText={(record) => <span>Type: {record.type}</span>}
          />
        }
        large={
          <Datagrid>
            <TextField source="title" label="Title" />
            <TextField source="duration" label="Duration" />
            <TextField source="type" label="Type" />
            <TextField source="orientation" label="Orientation" />
            <BooleanField source="isVideoPublic" label="Is video public" />
            <BooleanField source="isVideoPremium" label="Is video premium" />
            <DateField source="createdAt" label="Created At" />
            <DateField source="updatedAt" label="Updated At" />
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default withStyles(styles)(WSCList)
