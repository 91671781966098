import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ContainerStyled = styled.div`
  width: 100%;
`

export const LabelStyled = styled.label`
  font-size: 13px;
  margin-bottom: 16px;
  display: block;
`

export const TextareaStyled = styled.div`
  position: relative;
  margin: 4px;
  & input {
    resize: none;
    width: 100%;
    height: 34px;
    padding: 16px;
    border: solid 1px ${colors.grey[500]};
    box-sizing: border-box;
  }
`

export const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  align-items: flex-end;
`
