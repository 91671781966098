const getCookie = (name) => {
  var nameEQ = name + '='
  const token = document.cookie
  var ca = token.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

const setCookie = (cname, cvalue, exhours = 1) => {
  var d = new Date()
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=.laliga.com'
}

export { getCookie, setCookie }
