import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Fragment } from 'react'
import { ReferenceField, TextField } from 'react-admin'

import { getResource } from '../../../../config/resources'

const styles = (theme) => ({
  title: {
    marginBottom: '8px',
  },
})

const InfoGameweek = ({ classes, record }) => {
  return (
    <Fragment>
      <Grid item xs={6} md={3}>
        <Typography variant="title" color="primary" className={classes.title}>
          Season
        </Typography>
        <ReferenceField
          source="round.id"
          reference={getResource('competiciones', 'rounds')}
          basePath={getResource('competiciones', 'rounds')}
          linkType={false}
          record={record}
        >
          <TextField source="subscription.season.name" fullWidth />
        </ReferenceField>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="title" color="primary" className={classes.title}>
          Competition
        </Typography>
        <ReferenceField
          source="round.id"
          reference={getResource('competiciones', 'rounds')}
          basePath={getResource('competiciones', 'rounds')}
          linkType={false}
          record={record}
        >
          <TextField source="subscription.competition.name" fullWidth />
        </ReferenceField>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="title" color="primary" className={classes.title}>
          Subscription
        </Typography>
        <ReferenceField
          source="round.id"
          reference={getResource('competiciones', 'rounds')}
          basePath={getResource('competiciones', 'rounds')}
          linkType={false}
          record={record}
        >
          <TextField source="subscription.competitionName" fullWidth />
        </ReferenceField>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="title" color="primary" className={classes.title}>
          Round
        </Typography>
        <ReferenceField
          source="round.id"
          reference={getResource('competiciones', 'rounds')}
          basePath={getResource('competiciones', 'rounds')}
          linkType={false}
          record={record}
        >
          <TextField source="name" fullWidth />
        </ReferenceField>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="title" color="primary" className={classes.title}>
          Gameweek
        </Typography>
        <TextField source="name" fullWidth record={record} />
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(InfoGameweek)
