import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import React, { Component, Fragment } from 'react'
import {
  BooleanInput,
  DisabledInput,
  FormDataConsumer,
  GET_ONE,
  LongTextInput,
  maxLength,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

import { AutocompleteInput, DateSingleInput, DictionaryInput, MatchInput, RefereesInput as MatchRefereesInput, TimeInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import SelectTeamsMatch from './SelectTeamsMatch'
import SelectWinner from './SelectWinner'

const styles = () => {
  return {
    gridNoPadding: {
      padding: '0px',
    },
    itemTemperature: {
      display: 'flex',
    },
  }
}

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const changeInput = (value, form, dispatch) => {
  dispatch(change(form, 'note', null))
}

export class MatchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gameweek: null,
      matchFirstLeg: false,
      matchFirstLegInfo: null,
    }
  }

  componentDidMount() {
    const { record } = this.props

    if (record.gameweek) {
      dataProvider(GET_ONE, getResource('competiciones', 'gameweeks'), {
        id: record.gameweek.id,
      }).then((response) => {
        let gameweek = response.data

        dataProvider(GET_ONE, getResource('competiciones', 'subscriptions'), {
          id: gameweek.round.subscription.id,
        }).then((response) => {
          gameweek.round.subscription = response.data
          this.props.addInfoGameWeek(gameweek)
          this.setState({ gameweek: gameweek })
        })
      })
    }

    if (record.matchFirstLeg) {
      this.setState({ matchFirstLeg: true })
    }
  }

  handleChangematchFirstLeg = () => (event) => {
    if (event.target.checked) {
      this.props.openMatchFirstLeg(this.props.idGameweek)
    } else {
      this.props.closeMatchFirstLeg()
    }
    this.setState({ matchFirstLeg: event.target.checked })
  }

  render() {
    const { classes, isEdit, ...props } = this.props
    return (
      <LanguagesNav {...props}>
        <Grid container alignItems="center">
          {isEdit ? (
            this.state.gameweek && (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <TextField label="Season" value={this.state.gameweek.round.subscription.season.name} fullWidth disabled={true} margin="normal" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Competition" value={this.state.gameweek.round.subscription.competition.name} fullWidth disabled={true} margin="normal" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Subscription" value={this.state.gameweek.round.subscription.competitionName} fullWidth disabled={true} margin="normal" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Round" value={this.state.gameweek.round.name} fullWidth disabled={true} margin="normal" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput label="Game Week" source="gameweek.name" fullWidth disabled={true} />
                </Grid>
              </Fragment>
            )
          ) : (
            <Grid item xs={12}>
              <MatchInput
                validate={[required()]}
                sourceSeason="tempSeason"
                sourceCompetition="tempCompetition"
                sourceSubscription="subscription.id"
                sourceRound="tempRound"
                sourceGameweek="gameweek.id"
                sourceMatch={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <DisabledInput fullWidth source="name" label="Name" />
            <DisabledInput fullWidth source="slug" label="Slug" />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateSingleInput label="Date" source="date" />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormDataConsumer>
              {({ formData }) => {
                if (!formData) return null
                return <TimeInput label="Time" date={formData.date} source="time" fullWidth disabled={formData.date ? false : true} />
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferenceInput source="status" reference={getResource('competiciones', 'matchStatus')} validate={[required()]}>
              <SelectInput label="Status" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} className={classes.gridNoPadding}>
            <FormDataConsumer>
              {({ formData }) => {
                if (!formData) return null
                if (formData.tempRound) {
                  return <SelectTeamsMatch idRound={formData.tempRound} />
                } else {
                  return <SelectTeamsMatch idRound={null} />
                }
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormDataConsumer>
              {({ formData }) => {
                if (!formData) return null
                return (
                  <SelectWinner label="Winner Team" source="winnerTeam.id" idHomeTeam={this.props.idHomeTeam} idAwayTeam={this.props.idAwayTeam} fullWidth />
                )
              }}
            </FormDataConsumer>
          </Grid>

          <Grid item xs={12} md={4}>
            <NumberInput fullWidth source="homeScore" label="Home Score" validate={[number(), minValue(0)]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput fullWidth source="awayScore" label="Away Score" validate={[number(), minValue(0)]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormDataConsumer>
              {({ formData }) => {
                if (!formData) return null
                return (
                  <SelectWinner
                    label="Match Winner Team"
                    source="matchWinnerTeam.id"
                    idHomeTeam={this.props.idHomeTeam}
                    idAwayTeam={this.props.idAwayTeam}
                    fullWidth
                  />
                )
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput fullWidth source="homeShootOutScore" label="Home Shoot Out Score" validate={[number(), minValue(0)]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput fullWidth source="awayShootOutScore" label="Away Shoot Out Score" validate={[number(), minValue(0)]} />
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput label="Venue" source="venue.id" reference={getResource('competiciones', 'venues')} validate={[required()]}>
              <AutocompleteInput allowEmpty fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="zone" label="Zone" validate={[maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput fullWidth source="hashtag" label="Hashtag" validate={[maxLength(255)]} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="attemptOfficial" label="Attempt Official" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <NumberInput fullWidth source="attempt" label="Attempt" validate={[number(), minValue(0)]} />
          </Grid>
          <Grid item xs={12}>
            <LongTextInput source="attemptNote" label="Attempt Note" validate={[maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <FormDataConsumer>
              {({ formData, dispatch }) => (
                <Fragment>
                  <BooleanInput
                    source="mark"
                    label="Note"
                    onChange={() => {
                      changeInput(null, FORM, dispatch)
                    }}
                  />
                  {formData && formData.mark && <LongTextInput source="note" label="" />}
                </Fragment>
              )}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12}>
            <MatchRefereesInput source="matchReferees" />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={this.state.matchFirstLeg} onChange={this.handleChangematchFirstLeg()} color="primary" value={true} />}
              label="Match First Leg"
            />
          </Grid>
          {this.state.matchFirstLeg && (
            <Grid item xs={12}>
              <MatchInput
                disabledSeason={true}
                disabledCompetition={true}
                disabledSubscription={true}
                disabledRound={true}
                validate={[required()]}
                sourceSeason="tempSeason"
                sourceCompetition="tempCompetition"
                sourceSubscription="tempSubscription"
                sourceRound="tempRound"
                sourceGameweek="matchFirstLeg.gameweek.id"
                sourceMatch="matchFirstLeg.id"
              />
            </Grid>
          )}
          <Grid xs={12}>
            <Paper>
              <DictionaryInput
                label="Dictionary"
                sources={['performId', 'ldeId', 'quinielaId', 'sportiumId', 'pointsbetId', 'mbetId', 'optaId', 'strendusId', 'rushbetId']}
              />
            </Paper>
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => {
      const idGameweek = selector(state, 'gameweek.id')
      const idHomeTeam = selector(state, 'homeTeam.id')
      const idAwayTeam = selector(state, 'awayTeam.id')

      return { idGameweek, idHomeTeam, idAwayTeam }
    },
    (dispatch) => ({
      addInfoGameWeek: (gameweek) => {
        dispatch(change(FORM, 'tempSeason', gameweek.round.subscription.season.id))
        dispatch(change(FORM, 'tempCompetition', gameweek.round.subscription.competition.id))
        dispatch(change(FORM, 'tempSubscription', gameweek.round.subscription.id))
        dispatch(change(FORM, 'tempRound', gameweek.round.id))
      },
      openMatchFirstLeg(idGameweek) {
        dispatch(
          change(FORM, 'matchFirstLeg', {
            id: null,
            gameweek: {
              id: idGameweek,
            },
          })
        )
      },
      closeMatchFirstLeg() {
        dispatch(change(FORM, 'matchFirstLeg', null))
      },
    })
  ),
  withStyles(styles)
)
export default enhance(MatchForm)
