import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import LanguagesNav from '../../../../components/languagesNav'
import { LongTextInput, TextInput, SelectInput, ReferenceInput, required, maxLength } from 'react-admin'
import { DateTimeInput, RichTextInput } from '../../../../components/inputs'

import { getResource } from '../../../../config/resources'

const styles = (theme) => ({
  label: {
    display: 'block',
    marginBottom: '10px',
    transform: 'translate( -12.5%, 0px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
})

export const EventsForm = ({ ...props }) => (
  <LanguagesNav {...props} romeveDelete>
    <Grid container alignItems="flex-end">
      <Grid item xs={12} md={6}>
        <DateTimeInput label="Start" source="startedAt" date time fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput label="End" source="endAt" date time fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput label="Category" source="category.id" reference={getResource('layouts', 'event-categories')} validate={[required()]} fullWidth>
          <SelectInput />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Name" source="name" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <LongTextInput label="Address" source="address" />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput source="description" label="Description" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default withStyles(styles)(EventsForm)
