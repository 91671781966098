import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import React, { Component } from 'react'
import { FormDataConsumer, ReferenceInput, TextInput } from 'react-admin'
import { Field } from 'redux-form'

import { getResource } from '../../../config/resources'
import { VideoProviderContainerStyled, VideoProviderLabelStyled, ContainerStyled } from './style'

const GetBottomNavigation = ({ choices, input }) => {
  return (
    <Tabs showLabels value={input.value.id} indicatorColor="primary">
      {choices.map((choice) => {
        return <Tab key={choice.id} onClick={() => input.onChange(choice)} selected={choice.id === input.value.id} label={choice.name} value={choice.id} />
      })}
    </Tabs>
  )
}

class ChooseVideoSource extends Component {
  render() {
    return (
      <ReferenceInput source="source" label="Source" reference={getResource('assets', 'sources')}>
        <GetBottomNavigation />
      </ReferenceInput>
    )
  }
}

class AddVideoInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: null,
    }
  }

  handleChange(event) {
    this.setState({
      text: event.target.value,
    })
  }

  render() {
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData) return null
          return (
            <ContainerStyled>
              <Field name={this.props.source} component={ChooseVideoSource} />
              <VideoProviderContainerStyled>
                <TextInput source={this.props.sourceId} />
                {formData.source && (
                  <VideoProviderLabelStyled>
                    <Button
                      target="_blank"
                      href={`${formData.source.urlBase}${formData.providerId}`}
                      color="primary"
                    >{`${formData.source.urlBase}${formData.providerId}`}</Button>
                  </VideoProviderLabelStyled>
                )}
              </VideoProviderContainerStyled>
            </ContainerStyled>
          )
        }}
      </FormDataConsumer>
    )
  }
}

export default AddVideoInput
