import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_ONE, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { ConfirmationDialog, SubForm } from '../../../../../components'
import { ImageInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import VideoForm from '../../../../../resources/assets/videos/form/form'
import {
  FullContainerStyled,
  LeftContainerStyled,
  PluginContainerStyled,
  PrimaryTextStyled,
  RightContainerStyled,
  SecondaryTextStyled,
  TitleStyled,
} from './style'

const styles = (theme) => ({
  description: {
    backgroundColor: 'white',
  },
  chips: {
    margin: '4px',
    marginLeft: '0px',
  },
})

class VideoPlugin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: null,
      modal: false,
    }
  }

  componentDidMount() {
    const { value, options } = this.props
    if (value && value.content) {
      const isReference = options && options.reference
      const asset = isReference ? value.content : value.content.id
      this.getVideo(asset, (data) => {
        this.setState({
          pluginAnswere: data,
        })
      })
    }
  }

  handleChange(pluginAnswere) {
    this.setState({
      pluginAnswere: pluginAnswere,
    })
  }

  getVideo(value, func) {
    return dataProvider(GET_ONE, getResource('assets', 'videos'), {
      id: value,
    }).then((response) => {
      func(response.data)
    })
  }

  handleChangeVideo(content) {
    const isReference = this.props.options && this.props.options.reference
    let pluginAnswere = { ...this.state.pluginAnswere }
    let pluginAnswereReference = { ...this.state.pluginAnswere }
    pluginAnswere = content
    pluginAnswereReference = content.id
    this.handleChange(pluginAnswere)
    if (isReference || this.props.input) {
      this.props.onChange({ content: pluginAnswereReference })
    } else {
      this.props.onChange({ content: pluginAnswere })
    }
  }

  render() {
    const options = {
      type: 'videos',
      ...this.props.options,
    }
    const { readOnly } = options
    const valueName = this.state.pluginAnswere ? this.state.pluginAnswere.name : ''
    const valueDescription = this.state.pluginAnswere ? this.state.pluginAnswere.description : ''

    return (
      <PluginContainerStyled>
        <LeftContainerStyled>
          <ImageInput label="Video" options={options} choose value={this.state.pluginAnswere} onChange={(Video) => this.handleChangeVideo(Video)} fullWidth />
        </LeftContainerStyled>
        <ConfirmationDialog title="Edit video" open={this.state.modal} maxWidth="md" dialogActions={false} onCancel={() => this.setState({ modal: false })}>
          <SubForm
            submitOnEnter={false}
            record={this.state.pluginAnswere}
            resource={getResource('assets', 'videos')}
            onClose={() => this.setState({ modal: false })}
            form="asset-form"
            onSubmit={(data) =>
              this.setState({
                modal: false,
                pluginAnswere: data,
              })
            }
          >
            <VideoForm />
          </SubForm>
        </ConfirmationDialog>
        <RightContainerStyled>
          <PrimaryTextStyled>
            <TitleStyled>{valueName}</TitleStyled>
            {this.state.pluginAnswere && !readOnly && (
              <IconButton onClick={() => this.setState({ modal: true })}>
                <EditIcon />
              </IconButton>
            )}
          </PrimaryTextStyled>
          <SecondaryTextStyled>{valueDescription}</SecondaryTextStyled>
        </RightContainerStyled>
      </PluginContainerStyled>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(VideoPlugin)
