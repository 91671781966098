import React from 'react'
import { Edit } from 'react-admin'

import MetadaOthersForm from './form'

export const MetadaOthersEdit = (props) => (
  <Edit undoable={false} {...props}>
    <MetadaOthersForm {...props} />
  </Edit>
)

export default MetadaOthersEdit
