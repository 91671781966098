import React, { Component } from 'react'
import { Field } from 'redux-form'
import Markets from './markets'

class MarketsInput extends Component {
  render() {
    if (this.props.source) {
      return <Field select={this.props.select} allowAdd={this.props.allowAdd} label={this.props.label} component={Markets} name={this.props.source} />
    } else {
      return <Markets {...this.props} />
    }
  }
}

export default MarketsInput
