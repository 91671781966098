import styled from 'styled-components'

export const DndContainerStyled = styled.div`
  width: 100%;
`

export const ContainerHeaderStyled = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`
