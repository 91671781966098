import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import React from 'react'
import { BooleanInput, required, TextInput, maxLength } from 'react-admin'

import { LanguagesNav } from '../../../../components'
import { ImageInput, RichTextInput } from '../../../../components/inputs'
import MatchSelector from './MatchSelector'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <BooleanInput label="Active" source="active" />
      </Grid>
    </Grid>
    <MatchSelector {...props} />
    <Card>
      <CardHeader title="MATCH STATS" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={8}>
            <TextInput fullWidth source="mainTitle" label="Title" validate={[required(), maxLength(255)]} />
            <RichTextInput fullWidth source="mainDescription" label="Description" />
          </Grid>
          <Grid item xs={12} md={4}>
            <ImageInput
              label="Video"
              source="mainVideo"
              options={{
                type: 'videos',
                filters: {
                  state: 'published',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput label="Cara a cara bajo palos" source="goalkeepersImage" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput label="Construcción del juego" source="gameConstructionImage" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput label="Jugadores más peligrosos" source="dangerousPlayersImage" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput label="Jugadores más físicos" source="physicalPlayersImage" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardHeader title="NEXT GEN STATS" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={8}>
            <TextInput fullWidth source="nextGenStatsTitle" label="Title" />
            <RichTextInput fullWidth source="nextGenStatsDescription" label="Description" />
          </Grid>
          <Grid item xs={12} md={4}>
            <ImageInput
              label="Video"
              source="nextGenStatsVideo"
              options={{
                type: 'videos',
                filters: {
                  state: 'published',
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardHeader title="PLAYER FOCUS" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={8}>
            <TextInput fullWidth source="playerFocusTitle" label="Title" />
            <RichTextInput fullWidth source="playerFocusDescription" label="Description" />
          </Grid>
          <Grid item xs={12} md={4}>
            <ImageInput label="Image player" source="playerFocusPlayerImage" />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageInput label="Player stats" source="playerFocusStatImage" />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageInput
              label="Video"
              source="playerFocusVideo"
              options={{
                type: 'videos',
                filters: {
                  state: 'published',
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </LanguagesNav>
)

export default PageForm
