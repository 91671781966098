import React from 'react'
import { Edit } from 'react-admin'
import PageForm from '../form'

export const PageEdit = (props) => (
  <Edit undoable={false} {...props}>
    <PageForm numItems={1} {...props} />
  </Edit>
)

export default PageEdit
