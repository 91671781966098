import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ImageFieldStyled = styled.div`
  position: relative;
  display: inline-block;
  margin: 0px;
  line-height: 0px;
  padding-left: 24px;
  & > img {
    object-fit: cover;
    max-height: 10rem;
  }
  &:before {
    content: '';
    left: 0px;
    top: 0px;
    position: absolute;
    border: solid 1px transparent;
    border-color: ${(props) => props.stateColorBorder};
    background-color: ${(props) => props.stateColor};
    width: 24px;
    height: 100%;
    box-sizing: border-box;
  }
`
export const InfoStyled = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${colors.common.black};
  color: ${colors.common.white};
  padding: 8px 10px;
  font-size: 11px;
  line-height: 13px;
`

export const EmptyStyled = styled.div`
  height: 86px
  width: 120px
  background-color: ${colors.grey[400]}
`
