import React, { Component } from 'react'
import moment from 'moment-timezone'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { DateSingleInput, TimeInput } from '../index'
import { ContainerStyled, ItemStyled, SpaceStyled, LabelStyled } from './style'
import InputLabel from '@material-ui/core/InputLabel'

class DateTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueDate: null,
      valueTime: null,
      disabledTime: false,
      ready: false,
    }
    this.changeDateTime = this.changeDateTime.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeTime = this.handleChangeTime.bind(this)
  }

  componentDidMount() {
    const { input } = this.props
    this.setState({
      ready: true,
      valueDate: input.value,
      valueTime: input.value,
    })
  }

  handleChangeDate(value) {
    const { valueTime } = this.state
    this.changeDateTime(value, valueTime)
  }
  handleChangeTime(value) {
    const { valueDate } = this.state
    this.changeDateTime(valueDate, value)
  }

  changeDateTime(date, time) {
    const { input } = this.props
    var disabledTime = false
    var value = null
    let newTime, newDate
    if (date) {
      if (time) {
        value = time
        newDate = date
        newTime = time
      } else {
        newDate = moment(date).tz('Europe/Madrid')
        newDate.hour(0)
        newDate.minutes(0)
        newDate.seconds(0)

        newDate = newDate.toISOString(true)
        newTime = newDate
        value = newDate
      }
    } else {
      newDate = null
      newTime = null
      disabledTime = true
    }

    input.onChange(value)
    this.setState(
      {
        valueDate: newDate,
        valueTime: newTime,
        disabledTime: false,
      },
      () => {
        this.setState({
          disabledTime: disabledTime,
        })
      }
    )
  }

  render() {
    const { ready, valueDate, valueTime, disabledTime } = this.state
    const { meta, label, date, labelDate = 'Date', time, labelTime = 'Hour', input, ...rest } = this.props

    if (!ready) return null
    return (
      <FormControl fullWidth error={meta && meta.touched && meta.error}>
        <InputLabel>{label}</InputLabel>
        <ContainerStyled>
          {date && (
            <ItemStyled>
              <DateSingleInput label={labelDate} value={valueDate} onChange={this.handleChangeDate} block />
            </ItemStyled>
          )}
          {date && time && <SpaceStyled />}
          {time && (
            <ItemStyled>
              <TimeInput
                label={labelTime}
                value={valueTime}
                date={valueDate}
                onChange={this.handleChangeTime}
                disabledTime={disabledTime}
                defaultValue="00:00"
                fullWidth
              />
            </ItemStyled>
          )}
        </ContainerStyled>
        {meta && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )
  }
}

export default DateTime
