export const states = {
  published: 'published',
  draft: 'draft',
  hidden: 'hidden',
}

export const choicesStates = [
  {
    id: states.published,
    name: 'Published',
  },
  {
    id: states.draft,
    name: 'Draft',
  },
  {
    id: states.hidden,
    name: 'Hidden',
  },
]
