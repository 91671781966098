import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'

const PostionsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<PostionsFilter />} />
}

const PositionsList = (props) => (
  <List bulkActions={false} title="Positions" {...props} actions={<Actions />}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="femaleName" label="Female Name" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default PositionsList
