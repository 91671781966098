import { accountId, getTokenPopup, getTokenRedirect, myMSALObj } from '.'
import { graphConfig, loginRequest, tokenRequest } from './authConfig'

// Helper function to call MS Graph API endpoint
// using authorization bearer token scheme
export async function callMSGraph({ endpoint, accessToken, callback, method = 'GET' }) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method,
    headers: headers,
  }

  console.log('request made to Graph API at: ' + new Date().toString()) // eslint-disable-line

  await fetch(endpoint, options)
    .then((response) => response.json())
    .then((response) => callback(response, endpoint))
    .catch((error) => console.error(error))
}

export async function seeProfile() {
  const currentAcc = myMSALObj.getAccountByHomeId(accountId)
  if (currentAcc) {
    const response = await getTokenPopup(loginRequest, currentAcc).catch((error) => {
      console.error(error)
    })
    callMSGraph({ endpoint: graphConfig.graphMeEndpoint, accessToken: response.accessToken })
  }
}

export async function getGroups() {
  const currentAcc = myMSALObj.getAccountByHomeId(accountId)
  if (currentAcc) {
    const response = await getTokenPopup(tokenRequest, currentAcc).catch((error) => {
      console.error(error)
    })
    callMSGraph({ endpoint: graphConfig.graphGroupsEndpoint, accessToken: response.accessToken })
  }
}

export async function seeProfileRedirect() {
  const currentAcc = myMSALObj.getAccountByHomeId(accountId)
  if (currentAcc) {
    const response = await getTokenRedirect(loginRequest, currentAcc).catch((error) => {
      console.error(error)
    })
    callMSGraph({ endpoint: graphConfig.graphMeEndpoint, accessToken: response.accessToken })
  }
}

export async function getMemberOfRedirect() {
  const currentAcc = myMSALObj.getActiveAccount()
  if (currentAcc) {
    const response = await getTokenRedirect(tokenRequest, currentAcc).catch((error) => {
      console.error(error)
    })

    let nextLink = graphConfig.graphMemberOfEndpoint
    let allGroups = []
    do {
      await callMSGraph({
        endpoint: nextLink,
        accessToken: response.accessToken,
        callback: (response) => {
          let groups = response.value
            ?.filter((group) => group['@odata.type'] === '#microsoft.graph.group')
            ?.map((group) => {
              return group.displayName?.toLowerCase()
            })
            ?.filter((group) => group?.includes('webligacms'))

          if (process.env.REACT_APP_APP_ENV === 'pre') {
            groups = groups?.filter((group) => group.includes('_pre_'))?.map((group) => group.replace('_pre_', '_'))
          }

          nextLink = response['@odata.nextLink'] || false

          allGroups = [...allGroups, ...groups]
        },
      })
    } while (nextLink)

    return allGroups
  }
}

export async function getGroupsRedirect() {
  const currentAcc = myMSALObj.getActiveAccount()
  if (currentAcc) {
    const response = await getTokenRedirect(tokenRequest, currentAcc).catch((error) => {
      console.error(error)
    })
    let groups
    await callMSGraph({
      endpoint: graphConfig.graphGroupsEndpoint,
      accessToken: response.accessToken,
      callback: (response) => {
        groups = response.value?.map((group) => group.displayName)
      },
    })
    return groups
  }
}
