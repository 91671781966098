import React, { Component } from 'react'
import _ from 'lodash'
import { GET_LIST, UPDATE } from 'react-admin'
import { getResource } from '../../../../../config/resources'
import { dataProvider } from '../../../../../providers'
import AddRound from './AddRound'
import EditRound from './editRound'
import { ListItemsStyled, RowItemStyled } from './styles'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DownIcon from '@material-ui/icons/ArrowDownward'
import OrderIcon from '@material-ui/icons/SwapVerticalCircle'

const styles = (theme) => {
  return {
    table: {
      borderSpacing: '0px 0px',
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      '& tr td': {
        verticalAlign: 'middle',
      },
    },
    cellButton: {
      textAlign: 'right',
      paddingRight: '12px !important',
      width: '75px;',
    },
    orderIcon: {
      fontSize: '23px',
      opacity: 0.6,
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        color: theme.palette.primary.dark,
      },
    },
    cellIcon: {
      fontSize: '30px',
      opacity: 0.6,
      color: theme.palette.grey[600],
      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
  }
}

class Competition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCompetition: null,
      idSeasonOpen: null,
      rounds: null,
      roundSelected: null,
      idRoundOpen: null,
    }
  }

  componentDidMount() {
    const { idSeasonOpen, idCompetition, idSubscriptionOpen } = this.props

    this.setState({
      idCompetition: idCompetition,
      idSeasonOpen: idSeasonOpen,
      idSubscriptionOpen: idSubscriptionOpen,
      idRoundOpen: null,
    })
    this.listRounds(idSubscriptionOpen)
  }

  listRounds(idSubscriptionOpen) {
    dataProvider(GET_LIST, getResource('competiciones', 'rounds'), {
      sort: {
        field: 'position',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        subscription: idSubscriptionOpen,
      },
    }).then((response) => {
      this.setState({
        rounds: response.data,
      })
    })
  }

  refresRounds() {
    const { idSubscriptionOpen } = this.props
    this.listRounds(idSubscriptionOpen)
  }

  handleSelectRound(idRound) {
    if (this.state.roundSelected === idRound) {
      this.setState({
        roundSelected: null,
      })
    } else {
      this.setState({
        roundSelected: idRound,
      })
    }
  }

  handleClickEditRound(idRound) {
    this.setState({
      idRoundOpen: idRound,
    })
  }

  closeEditRound() {
    this.setState({
      idRoundOpen: null,
    })
    this.refresRounds()
  }

  moveRound(direction) {
    if (this.state.roundSelected) {
      let rounds = this.state.rounds
      let index = _.findIndex(rounds, { id: this.state.roundSelected })
      let new_rounds = false

      if (direction === 'up' && index > 0) {
        new_rounds = this.reorder(rounds, index, parseInt(index) - 1)
      }
      if (direction === 'down' && index < rounds.length) {
        new_rounds = this.reorder(rounds, index, parseInt(index) + 1)
      }

      if (new_rounds !== false) {
        this.setState({
          rounds: new_rounds,
        })

        new_rounds.map((item, key) => {
          let round = item
          round.position = key
          dataProvider(UPDATE, getResource('competiciones', 'rounds'), {
            id: item.id,
            data: round,
          }).then((response) => {})
          return null
        })
      }
    }
  }

  reorder(list, source, destination) {
    const result = Array.from(list)
    const [removed] = result.splice(source, 1)
    result.splice(destination, 0, removed)

    return result
  }

  render() {
    const { classes } = this.props

    if (this.state.idRoundOpen !== null) {
      return <EditRound idRound={this.state.idRoundOpen} closeEditRound={() => this.closeEditRound()} />
    } else {
      return (
        <Grid container>
          <Grid item xs={12}>
            <AddRound idSubscription={this.props.idSubscriptionOpen} refresRounds={() => this.refresRounds()} />
          </Grid>
          <Grid item xs={12}>
            <ListItemsStyled>
              <RowItemStyled className="sticky">
                <div className="order">
                  <UpIcon className={classes.orderIcon} onClick={() => this.moveRound('up')} />
                  <DownIcon className={classes.orderIcon} onClick={() => this.moveRound('down')} />
                </div>
                <div className="head">Name</div>
                <div className="head">Type</div>
                <div className="head">Groups</div>
                <div className="head" />
              </RowItemStyled>
              {this.state.rounds &&
                this.state.rounds.map((item, index) => {
                  return (
                    <RowItemStyled className={item.id === this.state.roundSelected ? 'selected' : ''} key={index}>
                      <div className="order">
                        <OrderIcon className={classes.cellIcon} onClick={() => this.handleSelectRound(item.id)} />
                      </div>
                      <div className="full">
                        <Typography variant="body1">{item.name}</Typography>
                      </div>
                      <div>
                        <Typography variant="body1">{item.type === 'table' ? 'league' : item.type}</Typography>
                      </div>
                      <div>
                        <Typography variant="body1">{item.groups ? 'Yes' : 'No'}</Typography>
                      </div>
                      <div className={classes.cellButton}>
                        <Button onClick={() => this.handleClickEditRound(item.id)} variant="contained" color="primary" size="small" aria-label="Edit">
                          Edit
                        </Button>
                      </div>
                    </RowItemStyled>
                  )
                })}
            </ListItemsStyled>
          </Grid>
        </Grid>
      )
    }
  }
}

export default withStyles(styles)(Competition)
