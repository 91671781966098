import styled from 'styled-components'
import theme from '../../../../../config/theme'

export const ContentStyled = styled.div`
  padding: 10px;
  border: solid 1px ${theme.palette.grey[300]};
`

export const TableStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  grid-gap: 10px;
  & > div {
    border: solid 1px ${theme.palette.grey[300]};
    padding: 5px;
  }
`

export const FormStyled = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-top: 10px;
  & > div {
    margin-right: 10px;
  }
`
