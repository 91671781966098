import React from 'react'
import { connect } from 'react-redux'
import { GET_LIST, showNotification, UPDATE } from 'react-admin'
import _ from 'lodash'
import { Typography, CircularProgress, Button, Grid, Collapse, Select, MenuItem, TextField } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'

const homeStates = [
  { state: 'off_season', name: 'OFF Season' },
  { state: 'pre_season', name: 'PRE Season' },
  { state: 'on_season', name: 'Season' },
]

class Homestate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openList: false,
      homestate: null,
      loading: false,
      fetching: false,
    }
  }

  componentDidMount() {
    this.getHomestate()
  }

  getHomestate = () => {
    this.setState({ loading: true })
    dataProvider(GET_LIST, getResource('transversal', 'globaldatas'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      filter: {
        name: 'APP_CONFIG',
      },
      pagination: { page: 1, perPage: 20 },
    }).then((res) => {
      this.setState({ homestate: res.data[0], loading: false })
    })
  }

  handleClick = () => {
    this.setState({
      openList: !this.state.openList,
    })
  }

  handleHomestate(event, section) {
    const copyHomeState = { ...this.state.homestate }
    if (section === 'home') {
      copyHomeState.contentJson.home.state = event.target.value
    } else if (section === 'minversion') {
      copyHomeState.contentJson.minversion[event.target.name] = event.target.value
    }
    this.setState({ homestate: copyHomeState })
  }

  handleSaveData() {
    this.setState({ fetching: true })
    let data = { ...this.state.homestate }
    data.contentJson = JSON.stringify(data.contentJson)
    dataProvider(UPDATE, getResource('transversal', 'globaldatas'), {
      id: this.state.homestate.id,
      data: data,
    })
      .then(({ data }) => {
        this.setState({ fetching: false, homestate: data })
        this.props.showNotification('Home state updated', 'success')
      })
      .catch((error) => {
        this.setState({ error: true, fetching: false })
        this.props.showNotification(error.message, 'error')
      })
  }

  render() {
    return (
      <>
        <div style={{ margin: '29px' }}>
          {this.state.loading ? (
            <div style={{ display: 'flex' }}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                Loading Home state
              </Typography>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => this.handleClick()}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px' }}>
                Home state
              </Typography>
              <div style={{ marginLeft: '16px' }}>{this.state.openList ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
          )}
          {this.state.homestate && this.state.openList && (
            <Collapse in={this.state.openList} timeout={350}>
              <Grid item xs={12} lg={8}>
                <div style={{ opacity: this.state.loading ? 0 : 1, transition: 'opacity 350ms' }}>
                  <Grid item xs={12} lg={8}>
                    <Select value={this.state.homestate?.contentJson?.home?.state} onChange={(event) => this.handleHomestate(event, 'home')} fullWidth>
                      {_.map(homeStates, (item) => (
                        <MenuItem key={item.state} value={item.state}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Grid item xs={12}>
                      <TextField
                        label="API version IOS"
                        value={this.state.homestate?.contentJson?.minversion?.ios}
                        onChange={(event) => this.handleHomestate(event, 'minversion')}
                        name="ios"
                        fullWidth
                        error={!this.state.homestate.contentJson.minversion.ios}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="API version Android"
                        value={this.state.homestate?.contentJson?.minversion?.android}
                        onChange={(event) => this.handleHomestate(event, 'minversion')}
                        name="android"
                        fullWidth
                        error={!this.state.homestate.contentJson.minversion.android}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={
                        this.state.loading ||
                        this.state.fetching ||
                        !this.state.homestate.contentJson.minversion.android ||
                        !this.state.homestate.contentJson.minversion.ios
                      }
                      onClick={() => this.handleSaveData()}
                    >
                      {this.state.fetching ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Collapse>
          )}
        </div>
      </>
    )
  }
}

const enhance = connect(null, {
  showNotification,
})

export default enhance(Homestate)
