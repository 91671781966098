import React from 'react'
import { Create } from 'react-admin'

import AmbassadorsForm from './form'

export const AmbassadorsCreate = (props) => (
  <Create {...props}>
    <AmbassadorsForm {...props} />
  </Create>
)

export default AmbassadorsCreate
