import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change } from 'redux-form'

import { getResource } from '../../../config/resources'
import ChipItem from './chipItem'
import EventInputMetadata from './eventInputMetadata'
import MatchInputMetadata from './matchInputMetadata'
import ExperienceInputMetadata from './experienceInputMetada'
import PlayerInputMetadata from './playerMetadata'
import MetadataInput from './metadata'
import { AddButtonStyled, ChipListStyled, ContainerStyled, ContentStyled, GrowStyled, HeaderStyled, LabelStyled } from './style'

const OPTIONS = [
  { type: 'match', name: 'Match' },
  { type: 'person', name: 'Player' },
  { type: 'team', name: 'Team' },
  { type: 'club', name: 'Club' },
  { type: 'event', name: 'Event' },
  { type: 'experience', name: 'Experience' },
  { type: 'promise', name: 'Promise' },
  { type: 'other', name: 'Others' },
]

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
  },
  select: {
    padding: '0px',
  },
})

class MetadataManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metadataSelected: 0,
    }
  }

  static defaultProps = {}

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    this.setState({
      content: value,
    })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props.input ? this.props.input : this.props
    const { value: prevValue } = prevProps.input ? prevProps.input : prevProps

    if (!prevValue && value) {
      this.setState({
        content: value,
      })
    }
  }

  handleChange = (event) => {
    const { value } = this.props.input ? this.props.input : this.props
    this.setState({
      metadataSelected: event.target.value,
      content: value,
    })
  }

  handleChangeMetadata = (value) => {
    this.setState({
      content: value,
    })
  }

  handleChangeMetadataMulti = (valueInput) => {
    const { value } = this.props.input ? this.props.input : this.props
    this.setState({
      content: _.union(value, valueInput),
    })
  }

  handleAdd = () => {
    const { onChange } = this.props.input ? this.props.input : this.props
    const { sources, changeitemForm } = this.props
    onChange(this.state.content)

    if (sources) {
      _.map(sources, (item) => {
        const formItem = _.find(this.state.content, { type: item })
        changeitemForm('record-form', item, formItem ? formItem.value : null)
      })
    }
  }

  handleDelete(index) {
    const { value, onChange } = this.props.input ? this.props.input : this.props
    const { sources, changeitemForm } = this.props
    const newValue = [...value]
    newValue.splice(index, 1)
    this.setState(
      {
        content: newValue,
      },
      () => {
        onChange(newValue)
        if (sources) {
          _.map(sources, (item) => {
            const formItem = _.find(this.state.content, { type: item })
            changeitemForm('record-form', item, formItem ? formItem.value : null)
          })
        }
      }
    )
  }

  getResource(type) {
    switch (type) {
      case 'season':
        return getResource('competiciones', 'seasons')
      case 'competition':
        return getResource('competiciones', 'competitions')
      case 'subscription':
        return getResource('competiciones', 'subscriptions')
      case 'round':
        return getResource('competiciones', 'rounds')
      case 'gameweek':
        return getResource('competiciones', 'gameweeks')
      case 'match':
        return getResource('competiciones', 'matches')
      case 'person':
        return getResource('competiciones', 'people')
      case 'team':
        return getResource('competiciones', 'teams')
      case 'club':
        return getResource('competiciones', 'clubs')
      case 'event':
        return getResource('assets', 'events')
      case 'other':
        return getResource('assets', 'metadataothers')
      case 'act':
        return getResource('assets', 'acts')
      case 'experience':
        return getResource('layouts', 'experiences')
      case 'category_experience':
        return getResource('layouts', 'experience-categories')
      case 'promise':
        return getResource('layouts', 'promises')
    }
  }

  renderMetadataInput() {
    const { metadataSelected } = this.state
    const { meta, sources, types } = this.props
    const myTypes = types ? types : OPTIONS
    const type = myTypes[metadataSelected].type
    if (type === 'event') {
      return (
        <EventInputMetadata
          value={this.state.content}
          onChange={(value) => {
            this.handleChangeMetadata(value)
          }}
        />
      )
    }
    if (type === 'match') {
      return (
        <MatchInputMetadata
          value={this.state.content}
          {...sources}
          onChange={(value) => {
            this.handleChangeMetadata(value)
          }}
        />
      )
    }
    if (type === 'experience') {
      return (
        <ExperienceInputMetadata
          value={this.state.content}
          onChange={(value) => {
            this.handleChangeMetadata(value)
          }}
        />
      )
    }
    if (type === 'person') {
      return (
        <>
          <PlayerInputMetadata
            value={this.state.content}
            onChange={(value) => {
              this.handleChangeMetadataMulti(value)
            }}
          />
        </>
      )
    }
    if (type === 'team') {
      return (
        <MetadataInput
          value={this.state.content}
          mode="autocomplete"
          type="team"
          placeholder="Team's name ..."
          label="team"
          sort={{
            field: 'id',
            order: 'ASC',
          }}
          onChange={(data) => {
            this.handleChangeMetadataMulti(data)
          }}
          meta={meta}
          reference={getResource('competiciones', 'teams')}
          optionText={'selectorName'}
          fullWidth
        />
      )
    }
    if (type === 'club') {
      return (
        <MetadataInput
          value={this.state.content}
          mode="autocomplete"
          type="club"
          optionText="name"
          placeholder="Club"
          label="Club"
          sort={{
            field: 'id',
            order: 'ASC',
          }}
          onChange={(data) => {
            this.handleChangeMetadata(data)
          }}
          meta={meta}
          reference={getResource('competiciones', 'clubs')}
          fullWidth
        />
      )
    }
    if (type === 'promise') {
      return (
        <MetadataInput
          value={this.state.content}
          mode="autocomplete"
          type="promise"
          optionText="name"
          placeholder="Promise"
          label="Promise"
          sort={{
            field: 'id',
            order: 'ASC',
          }}
          onChange={(data) => {
            this.handleChangeMetadata(data)
          }}
          meta={meta}
          reference={getResource('layouts', 'promises')}
          fullWidth
        />
      )
    }
    if (type === 'other') {
      return (
        <MetadataInput
          value={this.state.content}
          mode="autocomplete"
          type="other"
          optionText="name"
          placeholder="Others"
          label="Others"
          sort={{
            field: 'id',
            order: 'ASC',
          }}
          onChange={(data) => {
            this.handleChangeMetadataMulti(data)
          }}
          meta={meta}
          reference={getResource('assets', 'metadataothers')}
          fullWidth
        />
      )
    }
  }

  render() {
    const { label, classes, types } = this.props
    const { metadataSelected } = this.state
    const { value } = this.props.input ? this.props.input : this.props
    const myTypes = types ? types : OPTIONS
    return (
      <div>
        <FormControl fullWidth variant="filled" className={classes.formControl}>
          <LabelStyled>{label}</LabelStyled>
          <ContainerStyled>
            <HeaderStyled>
              <Select value={metadataSelected} onChange={this.handleChange}>
                {_.map(myTypes, (item, index) => {
                  return (
                    <MenuItem key={item.type} value={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <GrowStyled />
            </HeaderStyled>
            <ContentStyled>
              {this.renderMetadataInput()}
              <AddButtonStyled>
                <Button onClick={this.handleAdd} variant="contained" color="primary">
                  Add
                </Button>
              </AddButtonStyled>
            </ContentStyled>
            <ChipListStyled>
              {_.map(value, (item, index) => (
                <ChipItem
                  key={`${item.type}/${item.value}`}
                  value={item.value}
                  onDelete={() => this.handleDelete(index)}
                  resource={this.getResource(item.type)}
                  type={item.type}
                />
              ))}
            </ChipListStyled>
          </ContainerStyled>
        </FormControl>
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      changeitemForm: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(MetadataManager)
