import React from 'react'
import { Create } from 'react-admin'

import BrandSectorsForm from './form'

const BrandSectorsTitle = ({ record }) => <span>New sponsoring:</span>

export const BrandSectorsCreate = (props) => (
  <Create title={<BrandSectorsTitle />} {...props}>
    <BrandSectorsForm isEdit={false} />
  </Create>
)

export default BrandSectorsCreate
