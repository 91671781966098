import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'
import { CREATE, GET_LIST } from 'react-admin'

import { getResource } from '../../../../../config/resources'
import { dataProvider } from '../../../../../providers'
import MainManagement from './MainManagement'

const styles = (theme) => ({
  table: {
    borderSpacing: '0px 0px',
  },
  cellButton: {
    textAlign: 'right',
    paddingRight: '12px !important',
    width: '75px;',
  },
  cellSeason: {
    paddingRight: '0px',
    textAlign: 'right',
  },
  paper: {
    position: 'absolute',
    width: '90vw',
    maxWidth: '900px',
    height: '90vh',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
})

class CompetitionManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCompetition: null,
      seasons: null,
      seasonSelected: '',
      competitionName: '',
      open: false,
      idSeasonOpen: null,
      idSubscriptionOpen: null,
      subscriptions: null,
    }
  }

  componentDidMount() {
    this.setState({
      idCompetition: this.props.idCompetition,
    })

    this.listSubscription()
    this.listSeasons()
  }

  listSubscription() {
    dataProvider(GET_LIST, getResource('competiciones', 'subscriptions'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        competition: this.props.idCompetition,
        showInWeb: true,
      },
    }).then((response) => {
      this.setState({ subscriptions: response.data })
    })
  }

  listSeasons() {
    dataProvider(GET_LIST, getResource('competiciones', 'seasons'), {
      sort: {
        field: 'start',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: { showInWeb: 1 },
    }).then((response) => {
      this.setState({ seasons: response.data })
    })
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  addSubscription = () => {
    if (this.state.seasonSelected !== '') {
      dataProvider(CREATE, getResource('competiciones', 'subscriptions'), {
        data: {
          season: { id: this.state.seasonSelected },
          competition: { id: this.state.idCompetition },
          competitionName: this.state.competitionName,
        },
      }).then((response) => {
        this.setState({
          seasonSelected: '',
          competitionName: '',
        })
        this.listSubscription()
      })
    }
  }

  handleOpen = (idSubscription, idSeasonOpen) => {
    this.setState({
      open: true,
      idSeasonOpen: idSeasonOpen,
      idSubscriptionOpen: idSubscription,
    })
  }

  handleClose = () => {
    this.listSubscription()
    this.setState({
      open: false,
      idSeasonOpen: null,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell variant="head">
                  <FormControl margin="dense">
                    <InputLabel htmlFor="select-season">Season</InputLabel>
                    <Select
                      native
                      value={this.state.seasonSelected}
                      onChange={this.handleChange('seasonSelected')}
                      inputProps={{
                        name: 'seasonSelected',
                        id: 'select-season',
                      }}
                    >
                      <option value="" />
                      {this.state.seasons &&
                        this.state.seasons.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell variant="head">
                  <TextField
                    id="competitionName"
                    label="Competition Name"
                    value={this.state.competitionName}
                    margin="dense"
                    onChange={this.handleChange('competitionName')}
                  />
                </TableCell>
                <TableCell className={classes.cellButton}>
                  <Button
                    onClick={() => this.addSubscription()}
                    disabled={this.state.seasonSelected === '' ? true : false}
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="Add"
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.subscriptions &&
                this.state.subscriptions.map((item, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Typography variant="body1">{item.season.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.competitionName}</Typography>
                      </TableCell>
                      <TableCell className={classes.cellButton}>
                        <Button
                          isSeason={item.season.id}
                          onClick={() => this.handleOpen(item.id, item.season.id)}
                          variant="contained"
                          color="primary"
                          size="small"
                          aria-label="Config"
                        >
                          Config
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Paper>
        <Modal open={this.state.open} onClose={this.handleClose}>
          <Paper className={classes.paper}>
            <IconButton className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
            <MainManagement
              idCompetition={this.state.idCompetition}
              idSeasonOpen={this.state.idSeasonOpen}
              idSubscriptionOpen={this.state.idSubscriptionOpen}
              handleClose={this.handleClose}
            />
          </Paper>
        </Modal>
      </div>
    )
  }
}

export default withStyles(styles)(CompetitionManagement)
