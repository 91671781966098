import React from 'react'
import { Create } from 'react-admin'

import BroadcastForm from './form'

const BroadcasterTitle = ({ record }) => <span>Nueva difusión:</span>

export const BroadcastCreate = (props) => (
  <Create {...props} title={<BroadcasterTitle />}>
    <BroadcastForm isEdit={false} {...props} />
  </Create>
)

export default BroadcastCreate
