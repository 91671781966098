import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { DictionaryInput } from '../../../../../components/inputs'

const styles = (theme) => {
  return {
    paper: {
      position: 'absolute',
      width: '90vw',
      maxWidth: '650px',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }
}

const ModalDictionaries = (props) => {
  const { classes, baseSource } = props
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen} fullWidth>
        Dictionary
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          <DictionaryInput label="Dictionary" sources={['ldeId', 'performId', 'optaId', 'managerId']} baseSource={baseSource} />
          <Grid container justify="flex-end">
            <Button onClick={handleClose}>Close</Button>
          </Grid>
        </Paper>
      </Modal>
    </>
  )
}

export default withStyles(styles)(ModalDictionaries)
