import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'

import ListImages from './ListImages'

const styles = (theme) => ({
  row: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cell50: {
    width: '49%',
  },
  label: {
    marginBottom: '10px',
    display: 'block',
    fontSize: '0.75rem',
  },
})

class GridImageLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pluginAnswere: {
        cols: 3,
        aspectRatio: 'portrait',
        content: null,
      },
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      let pluginAnswere = {
        cols: value.cols ? value.cols : this.state.pluginAnswere.cols,
        aspectRatio: value.aspectRatio ? value.aspectRatio : this.state.pluginAnswere.aspectRatio,
        content: value.content ? value.content : this.state.pluginAnswere.content,
      }

      this.setState({
        pluginAnswere: pluginAnswere,
      })
    }
  }

  handleChange(key, value) {
    const { onChange } = this.props

    let pluginAnswere = { ...this.state.pluginAnswere }
    pluginAnswere[key] = value
    this.setState({ pluginAnswere })
    onChange(pluginAnswere)
  }

  render() {
    const { classes } = this.props
    const { cols, aspectRatio, content } = this.state.pluginAnswere
    return (
      <div>
        <div className={classes.row}>
          <div className={classes.cell50}>
            <FormControl fullWidth>
              <InputLabel htmlFor="slect-cols">Cols</InputLabel>
              <Select
                value={cols}
                onChange={(e) => this.handleChange('cols', e.target.value)}
                inputProps={{
                  name: 'cols',
                  id: 'slect-cols',
                }}
              >
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.cell50}>
            <FormControl fullWidth>
              <InputLabel htmlFor="slect-aspectRatio">Aspect Ratio</InputLabel>
              <Select
                value={aspectRatio}
                onChange={(e) => this.handleChange('aspectRatio', e.target.value)}
                inputProps={{
                  name: 'aspectRatio',
                  id: 'slect-aspectRatio',
                }}
              >
                <MenuItem value="portrait">Portrait</MenuItem>
                <MenuItem value="landscape">Landscape</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.row}>
          <ListImages content={content} onChange={(value) => this.handleChange('content', value)} version={aspectRatio} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GridImageLink)
