import { Card, CardHeader, CardContent, List } from '@material-ui/core'
import React from 'react'
import { FormDataConsumer } from 'react-admin'
import { LanguagesNav } from '../../../../../components'
import General from './modules/general'
import SeoInfo from './modules/SeoInfo'
import HeaderContent from './modules/headerContent'
import Events from './modules/events'
import News from './modules/news'
import Videos from './modules/videos'
import Images from './modules/images'
import Faqs from './modules/faqs'
import Players from './modules/players'
import History from './modules/history'
import Draws from './modules/draws'
import VideoPrev from './modules/VideoPrev'
import SummaryVideo from './modules/SummaryVideo'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    {({ dir }) => (
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData && (
            <div>
              <Card>
                <CardHeader title="General" subheader="Common for all languages" />
                <CardContent>
                  <General {...props} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title="Translatable" subheader="Independent for each language" />
                <CardContent>
                  <SeoInfo formData={formData} dispatch={dispatch} />
                  {/* Modules */}
                  <CardHeader title="Modules" />
                  <List>
                    <HeaderContent formData={formData} />
                    <VideoPrev />
                    <SummaryVideo />
                    <Events />
                    <Draws formData={formData} source="drawModule.items" />
                    <Images />
                    <Videos />
                    <News />
                    <History formData={formData} source="historyModule.items" />
                    <Players formData={formData} source="playerModule.items" />
                    <Faqs source="faqsModule.items" {...props} />
                  </List>
                </CardContent>
              </Card>
            </div>
          )
        }
      </FormDataConsumer>
    )}
  </LanguagesNav>
)

export default PageForm
