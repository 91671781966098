import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const UserBarStyled = styled.div`
  padding: 16px;
  color: ${colors.states.warning};
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
