import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_LIST, GET_ONE, showNotification, TextInput } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import { getResource } from '../../../../../config/resources'
import dataProvider from '../../../../../providers'
import { personsRoles } from '../../../utils/constants'
import PersonRoleInput from './PersonRoleInput'
import { ContainerPlayers, ContainerTeam, GroupPlayes, HeaderTeam } from './styled'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const FORM = 'lineups-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  inputShirtNumber: {
    width: '40px',
    padding: '0px',
    margin: '0px 5px',
    textAlign: 'center',
    '& > div': {
      marginTop: '0px',
    },
    '& input': {
      textAlign: 'center',
      padding: '2px 0',
    },
  },
})

export class Lineup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      completeSquad: null,
      lineupsDeleteds: [],
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onDragUpdate = this.onDragUpdate.bind(this)
  }

  componentDidMount() {
    this.initializeLineup()
  }

  initializeLineup() {
    const { fetchStart, fetchEnd, showNotification, idMatch, idSeason, team, change, source } = this.props

    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'squads'), {
      sort: {
        field: 'shirtNumber',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: {
        season: idSeason,
        team: team.id,
      },
    })
      .then(({ data }) => {
        let squad = _.cloneDeep(data)
        this.setState({
          completeSquad: data,
        })
        dataProvider(GET_LIST, getResource('competiciones', 'lineups'), {
          sort: {
            field: 'position',
            order: 'ASC',
          },
          pagination: { page: 1, perPage: 50 },
          filter: {
            match: idMatch,
            team: team.id,
          },
        })
          .then(({ data }) => {
            let lineupStart = []
            let lineupSub = []
            let lineupOther = []
            let squadOther = []

            data.map((item) => {
              let index = _.findIndex(squad, ['personRole.id', item.personRole.id])
              if (index !== -1) {
                squad.splice(index, 1)
              }
              switch (item.status) {
                case 'start':
                  lineupStart.push(item)
                  break
                case 'sub':
                  lineupSub.push(item)
                  break
                default:
                  lineupOther.push(item)
                  break
              }
            })

            const tempSquad = _.cloneDeep(squad)
            tempSquad.map((item) => {
              if (item.personRole.role.id !== personsRoles.jugador.id) {
                let index = _.findIndex(squad, ['personRole.id', item.personRole.id])
                if (index !== -1) {
                  squad.splice(index, 1)
                }
                squadOther.push(item)
              }
            })

            change(FORM, source, {
              squad: squad,
              'squad-other': squadOther,
              'squad-additional': [],
              'lineup-start': lineupStart,
              'lineup-sub': lineupSub,
              'lineup-other': lineupOther,
              'lineup-deleted': [],
            })
          })
          .catch((error) => {
            showNotification(error.message, 'error')
          })
          .finally(() => {
            fetchEnd()
          })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  onDragEnd(result) {
    const { field, change } = this.props
    const { source, destination } = result
    if (!destination) return false

    if (source.droppableId === destination.droppableId) {
      //Reordenamos dentro de la misma lista
      const dropId = this.getDropId(source.droppableId)
      if (dropId === 'lineup-start' || dropId === 'lineup-sub') {
        let list = _.cloneDeep(field(`${this.props.source}.${dropId}`))
        const listReorded = reorder(list, source.index, destination.index)
        change(FORM, `${this.props.source}.${dropId}`, listReorded)
      }
    } else {
      //Insertamos en una lista
      const sourceDropId = this.getDropId(source.droppableId)
      const destinationDropId = this.getDropId(destination.droppableId)

      if (sourceDropId === 'squad') {
        if (destinationDropId === 'lineup-start') {
          if (field(`${this.props.source}.lineup-start`).length < 11) {
            this.addPerson(source, destination)
          }
        } else if (destinationDropId === 'lineup-sub') {
          this.addPerson(source, destination)
        }
      } else if (sourceDropId === 'squad-other') {
        if (destinationDropId === 'lineup-other') {
          if (field(`${this.props.source}.lineup-other`).length < 1) {
            this.addPerson(source, destination)
          }
        }
      } else if (sourceDropId === 'squad-additional') {
        if (destinationDropId === 'lineup-start') {
          if (field(`${this.props.source}.lineup-start`).length < 11) {
            this.addPerson(source, destination, true)
          }
        } else if (destinationDropId === 'lineup-sub') {
          this.addPerson(source, destination, true)
        }
      } else if (sourceDropId === 'lineup-start') {
        if (destinationDropId === 'lineup-sub') {
          this.movePerson(source, destination)
        } else if (destinationDropId === 'squad' || destinationDropId === 'squad-other' || destinationDropId === 'squad-additional') {
          this.deletePerson(source)
        }
      } else if (sourceDropId === 'lineup-sub') {
        if (destinationDropId === 'lineup-start') {
          if (field(`${this.props.source}.lineup-start`).length < 11) {
            this.movePerson(source, destination)
          }
        } else if (destinationDropId === 'squad' || destinationDropId === 'squad-other' || destinationDropId === 'squad-additional') {
          this.deletePerson(source)
        }
      } else if (sourceDropId === 'lineup-other') {
        if (destinationDropId === 'squad' || destinationDropId === 'squad-other') {
          this.deletePerson(source)
        }
      }
    }
  }
  onDragUpdate(result) {
    // console.log("dani onDragUpdate", result)
  }

  getDropId(dropId) {
    const { team } = this.props
    return dropId.replace(`dropable-${team.id}-`, '')
  }

  addPerson(source, destination, additionalPerson = false) {
    const { field, change } = this.props
    const sourceDropId = this.getDropId(source.droppableId)
    const destinationDropId = this.getDropId(destination.droppableId)

    let completeSquad = null
    if (additionalPerson) {
      completeSquad = field(`${this.props.source}.squad-additional`)
    } else {
      completeSquad = this.state.completeSquad
    }
    const index = _.findIndex(completeSquad, ['personRole.id', field(`${this.props.source}.${sourceDropId}[${source.index}].personRole.id`)])
    const person = completeSquad[index]
    const newLineup = {
      id: 'new',
      shirtNumber: person.shirtNumber,
      personRole: person.personRole,
    }

    //lo añadirmos en la lista de destino
    let listDestination = field(`${this.props.source}.${destinationDropId}`)
    listDestination.splice(destination.index, 0, newLineup)
    change(FORM, `${this.props.source}.${destinationDropId}`, listDestination)

    //eliminamos de la lista de origen
    let listOrigin = field(`${this.props.source}.${sourceDropId}`)
    listOrigin.splice(source.index, 1)
    change(FORM, `${this.props.source}.${sourceDropId}`, listOrigin)
  }

  movePerson(source, destination) {
    const { field, change } = this.props
    const sourceDropId = this.getDropId(source.droppableId)
    const destinationDropId = this.getDropId(destination.droppableId)

    let listOrigin = field(`${this.props.source}.${sourceDropId}`)
    const person = listOrigin[source.index]
    //eliminamos de la lista de origen
    listOrigin.splice(source.index, 1)
    change(FORM, `${this.props.source}.${sourceDropId}`, listOrigin)

    //lo añadimos en la lista de destino
    let listDestination = field(`${this.props.source}.${destinationDropId}`)
    listDestination.splice(destination.index, 0, person)
    change(FORM, `${this.props.source}.${destinationDropId}`, listDestination)
  }

  deletePerson(source) {
    const { completeSquad, lineupsDeleteds } = this.state
    const { field, change } = this.props
    const sourceDropId = this.getDropId(source.droppableId)

    //lo añadimos en la plantilla / cuerpo tecnico
    const index = _.findIndex(completeSquad, ['personRole.id', field(`${this.props.source}.${sourceDropId}[${source.index}].personRole.id`)])

    if (index !== -1) {
      const person = completeSquad[index]
      let destinationDropId = 'squad'
      if (person.personRole.role.id !== personsRoles.jugador.id) {
        destinationDropId = 'squad-other'
      }
      let listDestination = field(`${this.props.source}.${destinationDropId}`)
      listDestination.push(person)
      listDestination.sort(function (a, b) {
        if (a.shirtNumber > b.shirtNumber) {
          return 1
        }
        if (a.shirtNumber < b.shirtNumber) {
          return -1
        }
        // a must be equal to b
        return 0
      })
      change(FORM, `${this.props.source}.${destinationDropId}`, listDestination)
    }

    let listOrigin = field(`${this.props.source}.${sourceDropId}`)
    const lineup = listOrigin[source.index]
    //eliminamos de la lista de origen
    listOrigin.splice(source.index, 1)
    change(FORM, `${this.props.source}.${sourceDropId}`, listOrigin)

    //Lo almacenamos en el redux para poder borrarlo en el proceso de guardado
    let lineupDeleted = field(`${this.props.source}.lineup-deleted`)
    lineupDeleted.push(lineup.id)
    change(FORM, `${this.props.source}.lineup-deleted`, lineupDeleted)
  }

  addAditionalPerson(idPersonRole) {
    const { fetchStart, fetchEnd, showNotification, field, change, team, source } = this.props
    fetchStart()
    dataProvider(GET_ONE, getResource('competiciones', 'peopleroles'), {
      id: idPersonRole,
    })
      .then(({ data }) => {
        let squadAdditional = field(`${source}.squad-additional`)
        const personSquad = {
          shirtNumber: null,
          team: team,
          personRole: data,
        }
        squadAdditional.push(personSquad)
        change(FORM, `${source}. squad-additional`, squadAdditional)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { team, field, source, classes } = this.props
    const squad = field(`${source}.squad`)
    const squadOther = field(`${source}.squad-other`)
    const squadAdditional = field(`${source}.squad-additional`)
    const lineupStart = field(`${source}.lineup-start`)
    const lineupSub = field(`${source}.lineup-sub`)
    const lineupOther = field(`${source}.lineup-other`)

    const { RowLineup } = this
    if (!squad) return false
    return (
      <ContainerTeam>
        <HeaderTeam>
          <Typography variant="title">{team.nickname}</Typography>
        </HeaderTeam>
        <DragDropContext id={`DragDropContext-${team.id}`} onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
          <ContainerPlayers>
            <div>
              <Droppable droppableId={`dropable-${team.id}-squad`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Plantilla</div>
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {squad.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                            {(provided, snapshot) => (
                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {item.shirtNumber} - {item.personRole.person.name}
                              </li>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
              <Droppable droppableId={`dropable-${team.id}-squad-other`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Cuerpo técnico</div>
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {squadOther.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                            {(provided, snapshot) => (
                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {item.shirtNumber} - {item.personRole.person.name}
                              </li>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
              <Droppable droppableId={`dropable-${team.id}-squad-additional`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Adicionales</div>
                    <PersonRoleInput
                      sourceRole={`${source}.person-additional.role`}
                      sourcePerson={`${source}.person-additional.personId`}
                      onClickAdd={(personRole) => this.addAditionalPerson(personRole)}
                    />
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {squadAdditional.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                            {(provided, snapshot) => (
                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {item.personRole.person.name} ({item.personRole.role.name})
                              </li>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
            </div>
            <div>
              <Droppable droppableId={`dropable-${team.id}-lineup-start`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Titulares</div>
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {lineupStart &&
                        lineupStart.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                              {(provided, snapshot) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <RowLineup source={`${this.props.source}.lineup-start[${index}]`} {...item} classes={classes} />
                                </li>
                              )}
                            </Draggable>
                          )
                        })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
              <Droppable droppableId={`dropable-${team.id}-lineup-sub`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Suplentes</div>
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {lineupSub &&
                        lineupSub.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                              {(provided, snapshot) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <RowLineup source={`${this.props.source}.lineup-sub[${index}]`} {...item} classes={classes} />
                                </li>
                              )}
                            </Draggable>
                          )
                        })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
              <Droppable droppableId={`dropable-${team.id}-lineup-other`}>
                {(provided, snapshot) => (
                  <GroupPlayes>
                    <div className="title">Entrenador</div>
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {lineupOther &&
                        lineupOther.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.personRole.person.id} index={index}>
                              {(provided, snapshot) => (
                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <RowLineup source={`${this.props.source}.lineup-other[${index}]`} {...item} classes={classes} />
                                </li>
                              )}
                            </Draggable>
                          )
                        })}
                      {provided.placeholder}
                    </ul>
                  </GroupPlayes>
                )}
              </Droppable>
            </div>
          </ContainerPlayers>
        </DragDropContext>
      </ContainerTeam>
    )
  }

  RowLineup = ({ source, shirtNumber, personRole, classes }) => {
    return (
      <span>
        <TextInput source={`${source}.shirtNumber`} label="" className={classes.inputShirtNumber} />
        {personRole.person.name}
      </span>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(Lineup)
