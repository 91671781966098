import styled from 'styled-components'
import theme from '../../../../../config/theme'

export const ListItemsStyled = styled.div`
  border-right: 1px solid ${theme.palette.grey[600]};
  border-left: 1px solid ${theme.palette.grey[600]};
  border-bottom: 1px solid ${theme.palette.grey[600]};
  width: 100%;
  display: table;
  font-family: ${theme.typography.fontFamily};
`
export const RowItemStyled = styled.div`
  display: table-row;
  box-sizing: border-box;
  background: #fff;
  &.sticky {
    background: #fff;
    top: 0px;
    position: sticky;
    z-index: 1;
    & > div {
      border-top: 1px solid ${theme.palette.grey[600]};
    }
  }
  &.selected {
    background: ${theme.palette.primary.light};
  }
  & > div {
    box-sizing: border-box;
    padding: 10px 56px 10px 10px;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid ${theme.palette.grey[200]};
    &.head {
      color: ${theme.palette.grey[700]} &.order {
        justify-content: space-between;
      }
      &.textfield {
        padding: 5px;
      }
    }
    &.order {
      padding: 10px;
      text-align: center;
      width: 70px;
      min-width: 70px;
      svg {
        display: inline-block;
      }
    }
    &.full {
      width: 100%;
    }
    &.tam-xs {
      width: 100px;
      max-width: 100px;
    }
  }
`
export const RowTeamsStyled = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0;
`

export const ListTeamsStyled = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  height: 200px;
  border: 1px solid ${theme.palette.grey[200]};
  overflow: auto;
  flex: 1 1 50%;
  & > div {
    box-sizing: border-box;
    padding: 6px 12px;
    font-family: ${theme.typography.fontFamily};
    &:hover {
      cursor: pointer;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
    }
    &.selected {
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export const ControlsTeamsStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & button {
    margin: 5px 10px;
    min-width: 40px;
  }
`
