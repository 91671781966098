import React from 'react'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import { getResource } from '../../../config/resources'
import { ReferenceField, ImageField } from 'react-admin'
import { MatchFieldStyled } from './style.js'

const MatchField = ({ ...props }) => {
  const baseSource = props.baseSource ? props.baseSource : ''
  return (
    <MatchFieldStyled>
      <ReferenceField {...props} label="Image" source={`${baseSource}homeTeam.shield`} reference={getResource('assets', 'images')} linkType={false} allowEmpty>
        <ImageField source="asset.fileDir" title="imagen competition" />
      </ReferenceField>
      <Typography component="span" body1="body1">
        {`${_.get(props.record, `${baseSource}homeTeam.nickname`)} - ${_.get(props.record, `${baseSource}awayTeam.nickname`)}`}
      </Typography>
      <ReferenceField {...props} label="Image" source={`${baseSource}awayTeam.shield`} reference={getResource('assets', 'images')} linkType={false} allowEmpty>
        <ImageField source="asset.fileDir" title="imagen competition" />
      </ReferenceField>
    </MatchFieldStyled>
  )
}

export default MatchField
