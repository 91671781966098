import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField, TextInput, ReferenceInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { AutocompleteInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const TeamsPromiseFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            source="team"
            label="Team"
            reference={getResource('competiciones', 'teams')}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={100}
            filter={{ type: 'promises' }}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <AutocompleteInput optionText="nickname" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            source="promise"
            label="Promises"
            reference={getResource('layouts', 'promises')}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={100}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<TeamsPromiseFilter />} />
}

export const TeamsPromise = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.promise.name}
          secondaryText={(record) => (
            <ReferenceField
              label="Team"
              source="team"
              record={record}
              basePath={getResource('competiciones', 'teams')}
              reference={getResource('competiciones', 'teams')}
              linkType={false}
              allowEmpty
            >
              <TextField source="nickname" fullWidth />
            </ReferenceField>
          )}
        />
      }
      medium={
        <Datagrid>
          <TextField label="Promise" source="promise.name" fullWidth />
          <ReferenceField label="Team" source="team" reference={getResource('competiciones', 'teams')} linkType={false} sortable={false} allowEmpty>
            <TextField source="nickname" fullWidth />
          </ReferenceField>
          <DateField label="Created At" source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default TeamsPromise
