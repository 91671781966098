import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  ArrayField,
  ChipField,
  CloneButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  Responsive,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { ListActions, TableRowList } from '../../../components'

const FilesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={12} alwaysOn>
          <TextInput source="name" label="Name" alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<FilesFilter />} />
}

export const FilesList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
      <Responsive
        small={<SimpleList primaryText={(record) => record.name} />}
        medium={
          <Datagrid>
            <FunctionField source="name" label="Name" render={(record) => <Link to={`${props.basePath}/${record.id}`}>{record.name}</Link>} />
            <TextField source="description" label="Description" />
            <ArrayField source="tags" label="tags" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
            <DateField source="createdAt" />
            <TableRowList>
              <EditButton />
              <CloneButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default FilesList
