import React from 'react'
import Grid from '@material-ui/core/Grid'
import LanguagesNav from '../../../../components/languagesNav'
import { TextInput, required, maxLength } from 'react-admin'
import { ColorPicker } from '../../../../components/inputs'

export const CategoriesForm = ({ ...props }) => (
  <LanguagesNav {...props} romeveDelete>
    <Grid container>
      <Grid item xs={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <ColorPicker fullWidth label="Color" source="color" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default CategoriesForm
