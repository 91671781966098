import React from 'react'
import { Grid, Button, Typography, Checkbox } from '@material-ui/core'
import { SimpleShowLayout, showNotification, refreshView } from 'react-admin'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { POST_ONE_WSC_VIDEO } from '../../../../providers/dataProvider/types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { httpClient } from '../../../../providers/dataProvider'
import API from '../../../../config/api'

class WSCForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: 'idle',
      isVideoPublicClick: false,
      isVideoPremiumClick: false,
      token: null,
    }
  }

  componentDidMount() {
    this.getSasToken()
  }

  getSasToken = () => {
    httpClient(`${API.assets.url}/videos/shared-token`, { method: 'GET' })
      .then((response) => {
        this.setState({
          token: response.json.sas,
        })
      })
      .catch((e) => {})
  }

  handlePost = (isPrivate) => {
    this.setState({
      loading: 'sending request',
      isVideoPublicClick: isPrivate === false && true,
      isVideoPremiumClick: isPrivate === true && true,
    })
    const { id, showNotification, refreshView } = this.props
    dataProvider(POST_ONE_WSC_VIDEO, getResource('assets', 'wsc'), {
      id: id,
      data: {
        isPrivate: isPrivate,
      },
    })
      .then(({ data }) => {
        showNotification(`Request sent successfully`, 'success')
        this.setState({
          loading: 'request sent',
        })
        refreshView()
      })
      .catch((error) => {
        showNotification(error.message, 'error')
        this.setState({
          loading: 'request sent',
        })
      })
  }

  render() {
    const { record } = this.props

    const { isVideoPremiumClick, isVideoPublicClick, loading, token } = this.state

    return (
      <SimpleShowLayout {...this.props}>
        <Typography variant="title" style={{ marginTop: '1rem', marginBottom: '2rem' }}>
          <span style={{ fontWeight: 'bold' }}>WSC:</span> {record.title}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Title:</span> {record.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>WSC Id:</span> {record.wscId}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>URL:</span> {record.url}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Type:</span> {record.type}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Orientation:</span> {record.orientation}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>OptaMatch Id:</span> {record.optaMatchId}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {token ? (
            <video controls style={{ width: '100%' }}>
              <source src={`${record.url}?${token}`} type="video/mp4" />
            </video>
          ) : (
            <div>Loading...</div>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={3} alignItems="center">
            <Grid item xs={8}>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Is Video Public:</span>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Checkbox checked={record.isVideoPublic} disabled />
            </Grid>
          </Grid>
          <Grid container item xs={3} alignItems="center">
            <Grid item xs={8} row>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Is Video Private:</span>
              </Typography>
            </Grid>
            <Grid item xs={4} row>
              <Checkbox checked={record.isVideoPremium} disabled />
            </Grid>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handlePost(false)}
              disabled={record.isVideoPublic === true || loading === 'sending request' || isVideoPublicClick === true}
            >
              {loading === 'sending request' && isVideoPublicClick === true ? 'Sending Request...' : 'Upload Public Video'}
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handlePost(true)}
              disabled={record.isVideoPremium === true || loading === 'sending request' || isVideoPremiumClick === true}
            >
              {loading === 'sending request' && isVideoPremiumClick === true ? 'Sending Request...' : 'Upload Premium Video'}
            </Button>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    )
  }
}

const enhance = compose(
  connect(null, {
    showNotification,
    refreshView,
  })
)

export default enhance(WSCForm)
