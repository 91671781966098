import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import { GET_ONE } from 'react-admin'

class SelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: [],
    }
  }

  componentDidMount() {
    if (this.props.promise) {
      this.getTeams()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.promise?.id !== this.props.promise?.id) {
      this.getTeams()
    }
  }

  async getTeams() {
    const { promise } = this.props

    if (promise.id) {
      let idSubscription = promise.subscription

      if (!idSubscription) {
        idSubscription = await dataProvider(GET_ONE, getResource('layouts', 'promises'), {
          id: promise.id,
        }).then(({ data }) => data.subscription)
      }

      dataProvider(GET_ONE, getResource('competiciones', 'subscriptions'), {
        id: idSubscription,
      }).then(({ data }) => {
        this.setState({
          choices: data?.subscriptionTeams.map((each) => each.team) || [],
        })
      })
    } else {
      this.setState({
        choices: [],
      })
    }
  }

  render() {
    const { value, onChange } = this.props.input
    const { choices } = this.state

    return (
      <FormControl fullWidth margin="normal">
        {this.props.label && <InputLabel htmlFor={this.props.source}>{this.props.label}</InputLabel>}
        <Select
          value={value}
          onChange={(e) => onChange(e.target?.value)}
          disabled={this.props.disabled || false}
          fullWidth
          inputProps={{
            name: this.props.source,
            id: this.props.source,
          }}
        >
          {choices &&
            choices.map((eachTeam) => (
              <MenuItem value={eachTeam.id} key={eachTeam.id}>
                {eachTeam?.nickname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }
}

const SelectTeam = (props) => {
  return <Field {...props} label={props.label} component={SelectInput} name={props.source} />
}

export default SelectTeam
