import React from 'react'
import { Edit } from 'react-admin'

import ChannelsForm from './form'

const ChannelTitle = ({ record }) => {
  return <span>{`Channel: ${record.nombre}`}</span>
}

export const ChannelsEdit = (props) => (
  <Edit undoable={false} title={<ChannelTitle />} {...props}>
    <ChannelsForm {...props} />
  </Edit>
)

export default ChannelsEdit
