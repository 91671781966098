import React from 'react'
import { Edit } from 'react-admin'

import BrandSectorsForm from './form'

const BrandSectorsTitle = ({ record }) => {
  return <span>{`BrandSectors: ${record.nombre}`}</span>
}

export const BrandSectorsEdit = (props) => (
  <Edit undoable={false} title={<BrandSectorsTitle />} {...props}>
    <BrandSectorsForm {...props} isEdit={true} />
  </Edit>
)

export default BrandSectorsEdit
