import lodashMemoize from 'lodash/memoize'

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args))

const isEmpty = (value) => typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0)

const getMessage = (message, messageArgs, value, values) =>
  typeof message === 'function'
    ? message({
        args: messageArgs,
        value,
        values,
      })
    : messageArgs
    ? {
        message,
        args: messageArgs,
      }
    : message

export const checkboxRequired = memoize((message = 'ra.validation.required') =>
  Object.assign((value, values, props) => (isEmpty(value) ? getMessage(message, undefined, value, values, props) : undefined), { isRequired: true })
)
