import styled, { keyframes } from 'styled-components'
import { colors } from '../../../../../config/theme'

const fetching = keyframes`
  from {
    opacity: .3;
  }

  to {
    opacity: 1;
  }
`

export const ModalStyled = styled.div`
  position: absolute;
  padding: 10px;
  background-color: ${colors.secondary.main};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const PluginContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: 23px;
`

export const LeftContainerStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`
export const RightContainerStyled = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
`
export const FullContainerStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 16px;
`

export const ImageContainerStyled = styled.div`
  background-color: transparent;
  width: 100%;
  position: relative;
`
export const ImageFooterStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageFooterNameStyled = styled.span`
  font-size: 13px;
`
export const ImageFooterDateStyled = styled.span`
  color: ${colors.grey['700']};
  font-size: 11px;
`

export const ImageStyled = styled.div`
  & > img {
    width: 100%;
    height: 200px;
    position: relative;
    object-fit: cover;
  }
`

export const ImageStyledEmpty = styled.div`
  height: 200px;
  width: 100%;
  background-color: ${colors.grey['500']};
`

export const OptionsBarStyled = styled.div`
  display: flex;
`

export const ContentListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 194px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 15px;
  background-color: ${colors.common.white};
  height: 388px;
  overflow-y: auto;
`

export const ContentStyled = styled.div``

export const ItemListStyled = styled.div`
  transition: all 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  animation: ${fetching} 1s;
  & img {
    width: 100%;
    box-sizing: border-box;
    height: 115px;
    object-fit: cover;
    transition: border 0.2s;
  }
  & span {
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    min-height: 25px;
    padding-top: 7px;
  }
  &:hover {
  }
`

export const ImgItemListStyled = styled.div`
  position: relative;
  line-height: 0px;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transition: all 0.2;
    border: solid 4px ${(props) => (props.selected ? colors.secondary.dark : 'transparent')};
  }
`

export const ItemListFetchingStyled = styled.div`
  background-color: ${colors.grey[300]};
  height: 133px;
  animation: ${fetching} 1s infinite;
  & span {
    font-weight: bold;
  }
  animation-direction: alternate;
`

export const FooterStyled = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 38px;
  display: flex;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`

export const InputStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 35px;
  & input {
    box-sizing: border-box;
    font-size: 13px;
    outline: none;
    border-image-outset: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 9px 16px;
    padding-right: 30px;
    height: 35px;
    background-image: none;
    background-color: ${colors.common.white};
    border: solid 1px ${colors.grey[300]};
    border-radius: 0px;
    box-shadow: none;
  }
  & svg {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    fill: ${colors.grey[300]};
  }
`
export const TextAreaStyled = styled.div`
  padding: 20px;
  background-color: ${colors.grey[700]};
`
export const PrimaryTextStyled = styled.h3`
  margin-top: 22px;
  display: flex;
  align-items: center;
`
export const SecondaryTextStyled = styled.p`
  font-size: 13px;
`
export const SeparatorStyled = styled.div`
  flex-grow: 1;
`

export const TitleStyled = styled.div`
  padding-right: 5px;
  word-break: break-all;
`
