import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Field } from 'redux-form'

import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { dataProvider } from '../../../providers'
import AutocompleteInput from '../autocompleteInput'
import { ValueSelectorStyled, FetchingStyled } from './style'

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
  },
})

const MODE_AUTOCOMPLETE = 'autocomplete'
const MODE_SELECT = 'select'

class SelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reference: [],
      isFetching: false,
    }
  }

  static defaultProps = {
    mode: MODE_SELECT,
    sort: {
      field: 'id',
      order: 'ASC',
    },
    filters: {},
  }

  componentDidMount() {
    this.updateSelector()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reference !== this.props.reference || !_.isEqual(prevProps.filter, this.props.filter)) {
      this.updateSelector()
    }
  }

  updateSelector() {
    const { reference, filter } = this.props
    this.getReference(reference, filter, (list) => {
      this.setState({
        reference: list,
      })
    })
  }

  getReference(reference, filters, func) {
    const { sort } = this.props
    this.setState({
      isFetching: true,
    })
    dataProvider(GET_LIST, reference, {
      sort: sort,
      filter: filters,
      pagination: {},
    })
      .then((response) => {
        func(response.data)
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        })
      })
  }

  handleChange(id) {
    if (!id || id === '') return null
    if (this.props.input) this.props.input.onChange(parseInt(id))
    const item = _.find(this.state.reference, { id: parseInt(id) })
    if (this.props.options && this.props.options.onChange) this.props.options.onChange(item)
  }

  handleFilter = _.debounce((value) => {
    if (value) {
      const { reference, filter } = this.props
      this.getReference(reference, { ...filter, q: value }, (list) => {
        this.setState({
          reference: list,
        })
      })
    }
  }, 800)

  render() {
    const { optionText, type, mode, placeholder, label, fullWidth, classes, meta } = this.props
    const { value } = this.props.input ? this.props.input : this.props
    const { isFetching } = this.state

    if (mode === MODE_AUTOCOMPLETE) {
      return (
        <FormControl className={classes.fullWidth} style={{ width: '100%' }}>
          <AutocompleteInput
            isFetching={isFetching}
            value={value}
            meta={this.props.meta}
            choices={this.state.reference}
            onChange={(value) => this.handleChange(value)}
            optionText={optionText}
            setFilter={(value) => this.handleFilter(value)}
            translateChoice={false}
            placeholder={placeholder}
            label={label}
            fullWidth={fullWidth}
          />
        </FormControl>
      )
    }
    return (
      <FormControl className={classes.fullWidth} style={{ width: '100%' }} error={meta ? meta.error : null} disabled={this.state.isFetching}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value ? value : 'metadataPlaceholder'}
          onChange={(event) => this.handleChange(event.target.value)}
          fullWidth
          placeholder={placeholder}
          renderValue={(value) => {
            const selectedReference = _.find(this.state.reference, (item) => {
              return item.id == value
            })
            const selectedName = selectedReference ? (optionText ? _.get(selectedReference, optionText) : selectedReference.name) : null
            return (
              <ValueSelectorStyled>
                {this.state.isFetching && (
                  <FetchingStyled>
                    <CircularProgress size={15} thickness={4} />
                  </FetchingStyled>
                )}
                {selectedName}
              </ValueSelectorStyled>
            )
          }}
        >
          >
          <MenuItem value={'metadataPlaceholder'} disabled>
            {this.state.isFetching && (
              <FetchingStyled>
                <CircularProgress size={15} thickness={4} />
              </FetchingStyled>
            )}
          </MenuItem>
          {_.map(this.state.reference, (item) => (
            <MenuItem key={item.id} value={item.id.toString()}>
              {optionText ? _.get(item, optionText) : item.name}
            </MenuItem>
          ))}
        </Select>
        {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )
  }
}

const MySelectInput = withStyles(styles)(SelectInput)

const queryParamsForm = ({ source, ...props }) => {
  return <Field {...props} name={source} component={MySelectInput} />
}

export default queryParamsForm
