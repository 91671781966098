import './bitmovin.css'
import 'bitmovin-player/bitmovinplayer-ui.css'

import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Player as BitmovinPlayer } from 'bitmovin-player'
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui'
import get from 'lodash/get'
import mimeTypes from 'mime-types'
import React, { Component } from 'react'
import userAgentParser from 'ua-parser-js'

import API from '../../../config/api'
import { colors } from '../../../config/theme'
import httpClient from '../../../providers/dataProvider/httpClient'
import { CloudUploadIconStyled, ErrorIconStyled, ImageFieldStyled, PlaceholderImgStyled, RefreshButtonStyled } from './style'

const processSource = (src, isIOS) => {
  if (!src) return {}
  const mime = mimeTypes.lookup(src)
  let newSource = {}
  if (isIOS || mime === 'application/vnd.apple.mpegurl') newSource.hls = src.replace('format=mpd-time-csf', 'format=m3u8-aapl')
  else if (mime === 'application/dash+xml' || src.indexOf('manifest') > -1) newSource.dash = src
  else if (mime) newSource.progressive = [{ url: src, type: mime }]
  return newSource
}

const styles = () => ({
  refreshButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  label: {
    display: 'inline-block',
    paddingBottom: '13px',
    fontSize: '1rem',
    lineHeight: 0,
  },
})

class ViedeoField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: { progress: 100, state: 'Finished' },
      isFetching: false,
    }

    const client = typeof navigator !== 'undefined' ? userAgentParser(navigator.userAgent).os.name : false
    this.isIOS = client === 'iOS'

    this.intervalId = null

    this.bitmovin = null
  }

  componentDidMount() {
    const { record, source, value } = this.props
    const myValue = record ? get(record, source) : value
    const { autorefresh } = this.props
    this.setState({ isFetching: true })
    if (autorefresh && myValue) {
      this.getJobStatus((response) => {
        if (response && response.state !== 'Finished') {
          this.setState({
            status: response,
          })
          var intervalId = setInterval(
            () =>
              this.getData((getDataResponse) => {
                if (getDataResponse.state === 'Finished') this.stopInterval()
              }),
            6000
          )
          this.intervalId = intervalId
        } else {
          this.stopInterval()
          this.setState({
            status: response,
          })
        }
      })
    } else {
      this.getData(() => {
        this.setState({ isFetching: false })
      })
    }
  }

  componentDidUpdate() {
    this.initializeBitmovin()
  }

  componentWillUnmount() {
    this.stopInterval()
  }

  initializeBitmovin() {
    const { record, source, value } = this.props
    const valueVideo = record ? get(record, source) : value

    if (valueVideo && !this.state.isFetching && document.getElementById('bitmovin' + valueVideo.id)) {
      const bitmovinSource = processSource(valueVideo.urlManifest, this.isIOS)

      this.bitmovin = new BitmovinPlayer(document.getElementById('bitmovin' + valueVideo.id), {
        key: '56092013-bb19-4275-9bd9-615a51400103',
        location: {
          ui: '',
          ui_css: '',
        },
      })

      this.bitmovin.load(bitmovinSource).then(() => {
        UIFactory.buildDefaultUI(this.bitmovin)
      })
    }
  }

  getData(func) {
    this.getJobStatus((response) => {
      if (response.state && response.state !== 'Finished') {
        this.setState(
          {
            status: response,
          },
          () => {
            if (func) func(response)
          }
        )
      }
    })
  }

  stopInterval() {
    const { autorefresh } = this.props
    if (autorefresh) {
      clearInterval(this.intervalId)
    }
  }

  getJobStatus = (func) => {
    const id = get(this.props.record, this.props.sourceId)
    this.setState({ isFetching: true })
    httpClient(`${API.assets.url}/videos/job-status?id=` + id, {
      method: 'GET',
    })
      .then((response) => {
        func(response.json)
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  render() {
    const { record, source, value, classes, label, autorefresh } = this.props
    const valueVideo = record ? get(record, source) : value

    const RenderVideoFinished = () => {
      if (valueVideo && valueVideo.urlManifest) {
        const BitmovinProps = {
          id: `bitmovin${valueVideo.id}`,
          className: 'bitmovin-player',
        }
        return (
          <ImageFieldStyled stateColor={colors.states.successLight} stateColorBorder={colors.states.success}>
            <div {...BitmovinProps} />
          </ImageFieldStyled>
        )
      } else {
        return (
          <ImageFieldStyled stateColor={colors.states.warningLight} stateColorBorder={colors.states.warning}>
            <LinearProgress variant="determinate" value={Math.round(this.state.status.progress)} />
            <PlaceholderImgStyled>
              {this.state.isFetching && !this.state.status ? (
                <CircularProgress />
              ) : (
                <React.Fragment>
                  <CloudUploadIconStyled />
                  Processing...
                  {!autorefresh && (
                    <RefreshButtonStyled onClick={() => this.getData()} className={classes.refreshButton}>
                      <RefreshIcon />
                    </RefreshButtonStyled>
                  )}
                </React.Fragment>
              )}
            </PlaceholderImgStyled>
          </ImageFieldStyled>
        )
      }
    }

    const RenderVideoQueued = () => {
      return (
        <ImageFieldStyled stateColor={colors.primary.main} stateColorBorder={colors.primary.main}>
          <PlaceholderImgStyled>
            {this.state.isFetching && !this.state.status ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <ErrorIconStyled />
                Queued
                <RefreshButtonStyled onClick={() => this.getData()} className={classes.refreshButton}>
                  <RefreshIcon />
                </RefreshButtonStyled>
              </React.Fragment>
            )}
          </PlaceholderImgStyled>
        </ImageFieldStyled>
      )
    }

    const RenderVideoProcessing = () => {
      return (
        <ImageFieldStyled stateColor={colors.states.warningLight} stateColorBorder={colors.states.warning}>
          <LinearProgress variant="determinate" value={Math.round(this.state.status.progress)} />
          <PlaceholderImgStyled>
            {this.state.isFetching && !this.state.status ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <CloudUploadIconStyled />
                {Math.round(this.state.status.progress)}%
                {!autorefresh && (
                  <RefreshButtonStyled onClick={() => this.getData()} className={classes.refreshButton}>
                    <RefreshIcon />
                  </RefreshButtonStyled>
                )}
              </React.Fragment>
            )}
          </PlaceholderImgStyled>
        </ImageFieldStyled>
      )
    }

    if (!valueVideo || (valueVideo && !valueVideo.id))
      return (
        <div>
          {label && <InputLabel className={classes.label}>{label}</InputLabel>}
          <PlaceholderImgStyled>
            {valueVideo && !valueVideo.id && (
              <video width="100%" height="200" controls>
                <source src={valueVideo.fileDir} type="video/mp4" />
              </video>
            )}
          </PlaceholderImgStyled>
        </div>
      )
    return (
      <div>
        {label && <InputLabel className={classes.label}>{label}</InputLabel>}
        {this.state.status && this.state.status.state === 'Finished' && <RenderVideoFinished />}
        {this.state.status && this.state.status.state === 'Processing' && <RenderVideoProcessing />}
        {this.state.status && this.state.status.state === 'Queued' && <RenderVideoQueued />}
        {(!this.state.status || (this.state.status && !this.state.status.state)) && (
          <PlaceholderImgStyled>{this.state.isFetching && !this.state.status && <CircularProgress />}</PlaceholderImgStyled>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ViedeoField)
