import React from 'react'
import { Edit } from 'react-admin'

import RulesForm from './form'

const RulesTitle = ({ record }) => {
  return <span>{`Difusión: ${record.nombre}`}</span>
}

export const RulesEdit = (props) => (
  <Edit undoable={false} title={<RulesTitle />} {...props}>
    <RulesForm {...props} />
  </Edit>
)

export default RulesEdit
