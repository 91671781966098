import React, { Component } from 'react'
import { Field } from 'redux-form'
import Metadata from './autocompleteInput'

class MetadataInput extends Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source && !this.props.meta) {
      return <Field {...rest} component={Metadata} name={source} />
    } else {
      return <Metadata {...this.props} />
    }
  }
}

export default MetadataInput
