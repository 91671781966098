import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import './styleTinyMCE.css'
import { withFormActiveLanguage } from '../../languagesNav/formActiveLanguageContext'
import { additionalLanguages } from '../../../config/languages'

const styles = (theme) => ({
  label: {
    display: 'block',
    marginBottom: '8px',
    transform: 'translate( -12.5%, 0px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
})

const RichText = (props) => {
  const {
    label,
    classes,
    formLanguageData: { formActiveLanguage },
  } = props
  const { value } = props.input ? props.input : props

  const dir = additionalLanguages.find((lang) => lang.slug === formActiveLanguage).dir || 'ltr'

  const handleChange = (value) => {
    const { onChange } = props.input ? props.input : props
    onChange(value)
  }

  return (
    <div>
      {label && <label className={classes.label}>{label}</label>}
      <Editor
        value={value}
        init={{
          height: 350,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen table',
            'insertdatetime media table paste code help wordcount autoresize',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | code | table',
          directionality: dir,
        }}
        onEditorChange={handleChange}
      />
    </div>
  )
}

export default withStyles(styles)(withFormActiveLanguage(RichText))
