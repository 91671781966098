import React from 'react'
import { BooleanInput, ReferenceInput, AutocompleteInput, TextInput, required, maxLength } from 'react-admin'
import LanguagesNav from '../../../../components/languagesNav'
import { Grid } from '@material-ui/core'
import { ImageInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'

class DynamicSplashScreenForm extends React.Component {
  render() {
    const { record } = this.props

    return (
      <LanguagesNav {...this.props}>
        <Grid container>
          <Grid item xs={12}>
            {record.country === '_DEFAULT' ? (
              <TextInput source="country" label="Country" disabled />
            ) : (
              <ReferenceInput fullWidth source="country" label="Country" reference={getResource('transversal', 'countries')} validate={required()}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextInput source="name" label="Name" fullWidth disabled={record.country === '_DEFAULT'} validate={[required(), maxLength(255)]} />
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="image" label="Splash Screen" accept="image/*" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            {record.country !== '_DEFAULT' && <BooleanInput source="active" label="Active" />}
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default DynamicSplashScreenForm
