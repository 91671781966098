import styled from 'styled-components'
import { colors } from '../../config/theme'

export const ContainerStyled = styled.div`
  background-color: ${colors.grey[800]};
  padding: 3px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 65px;
  width: 100%;
  z-index: 100;
  & > .button-back {
    width: 40px;
    height: 40px;
  }
`

export const LanguagesNavStyled = styled.nav`
  & a,
  & span {
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    line-height: 34px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 0 4px;
    color: ${colors.grey[400]};
    border-radius: 4px;
    &:hover,
    &.active {
      color: ${colors.common.white};
      background: ${colors.primary.main};
    }
    &.disabled {
      opacity: 0.5;
      &:hover,
      &.active {
        color: ${colors.grey[400]};
        background: ${colors.primary.main};
      }
    }
  }
`

export const GrowStyled = styled.nav`
  flex-grow: 1;
`

export const MainFormStyled = styled.div`
  padding: 20px;
`

export const TitleFormStyled = styled.h2`
  padding: 20px 0px;
  margin: 0px;
`
export const FormContainerStyled = styled.div`
  width: 100%;
`
export const SpinnerContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
