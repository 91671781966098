import React from 'react'

import CameraIcon from './camera'
import VideoIcon from './videos'
import { IconStyled } from './style'

export { CameraIcon, VideoIcon }

require('./icomoon/style.css')
const Icon = ({ icon, ...rest }) => {
  return <IconStyled {...rest} className={`icon-${icon}`} />
}

export default Icon
