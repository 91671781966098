import styled from 'styled-components'

export const StateFieldStyled = styled.div`
  position: relative;
  display: inline-block;
  margin: 0px;
  line-height: 0px;
  padding-left: '24px'
  &:before {
    content: '';
    left: 0px;
    top: 0px;
    position: absolute;
    border: solid 1px transparent;
    border-color: ${(props) => props.stateColorBorder};
    background-color: ${(props) => props.stateColor};
    width: 24px;
    height: 100%;
    box-sizing: border-box;
  }
`
