import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'

import { ContentStyled, FormStyled, TableStyled } from './style'

const styles = (theme) => ({
  input: {
    '& input': {
      width: '100%',
    },
  },
})

class textPluginEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cols: 0,
      rows: 0,
    }
  }

  componentDidMount() {
    const { value, onChange } = this.props.input ? this.props.input : this.props
    if (!value.content) {
      const content = this._getContentTable(2, 2)
      this.setState(
        {
          cols: 2,
          rows: 2,
        },
        () => {
          onChange({
            cols: 2,
            rows: 2,
            content: content,
          })
        }
      )
    } else {
      this.setState({
        cols: value.cols,
        rows: value.rows,
        content: value.content,
      })
    }
  }

  getTable(cols, content) {
    if (content) {
      let data = [...content]
      var newArr = []
      while (data.length) newArr.push(data.splice(0, cols))
      return newArr
    }
  }

  _getContentTable(rows, cols) {
    if (cols && rows) {
      const { value } = this.props.input ? this.props.input : this.props
      const oldValue = value
      const newTable = []
      const newTableContent = []
      const oldTableFormated = this.getTable(oldValue.cols, oldValue.content)
      for (let index = 0; index < rows; index++) {
        newTable[index] = []
        for (let indexY = 0; indexY < cols; indexY++) {
          const position = index * cols + indexY
          if (oldTableFormated && oldTableFormated[index] && oldTableFormated[index][indexY]) {
            newTable[index][indexY] = oldTableFormated[index][indexY]
            newTableContent[position] = oldTableFormated[index][indexY]
          } else {
            newTableContent[position] = ''
          }
        }
      }
      this.setState({
        table: newTable,
        content: newTableContent,
      })
      return newTableContent
    }
  }

  _getRowCol(newVal, oldVal) {
    const { onChange } = this.props.input ? this.props.input : this.props
    if ((oldVal + 1 === newVal || oldVal - 1 === newVal) && newVal > 0) {
      onChange(this.props.val)
      return newVal
    } else return oldVal
  }

  _handleChangeContent(e, index) {
    const { value, onChange } = this.props.input ? this.props.input : this.props
    let newValue = { ...value }
    newValue.content[index] = e.target.value
    onChange(newValue)
  }

  _handleChangeRowCol(rows, cols) {
    this.setState({
      cols: cols,
      rows: rows,
    })
  }

  _submitChangeRowCol() {
    if (!this.state.rows || !this.state.cols) {
      return null
    } else {
      const { value, onChange } = this.props.input ? this.props.input : this.props
      let newValue = { ...value }
      newValue.rows = this.state.rows
      newValue.cols = this.state.cols
      newValue.content = this._getContentTable(this.state.rows, this.state.cols)
      onChange(newValue)
    }
  }

  render() {
    const { value } = this.props.input ? this.props.input : this.props
    if (value) {
      return (
        <ContentStyled>
          <FormStyled>
            <TextField
              error={!this.state.rows || this.state.rows < 1}
              label="Filas"
              value={this.state.rows}
              onChange={(e) => this._handleChangeRowCol(parseInt(e.target.value), this.state.cols)}
              type="number"
            />
            <TextField
              error={!this.state.cols || this.state.cols < 1}
              label="Columnas"
              value={this.state.cols}
              onChange={(e) => this._handleChangeRowCol(this.state.rows, parseInt(e.target.value))}
              type="number"
            />
            <Button variant="contained" color="primary" onClick={() => this._submitChangeRowCol()}>
              Apply
            </Button>
          </FormStyled>
          {value.content && value.cols && value.rows && (
            <TableStyled columns={value.cols} rows={value.rows}>
              {value.content.map((item, index) => {
                return (
                  <div key={index}>
                    <TextField
                      value={item}
                      className={this.props.classes.input}
                      onChange={(e) => {
                        this._handleChangeContent(e, index)
                      }}
                      type="text"
                      variant="filled"
                      fullWidth
                    />
                  </div>
                )
              })}
            </TableStyled>
          )}
        </ContentStyled>
      )
    }
    return null
  }
}

export default withStyles(styles)(textPluginEdit)
