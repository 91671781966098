import Grid from '@material-ui/core/Grid'
import React from 'react'
import _get from 'lodash/get'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  Responsive,
  SimpleList,
  TextField,
  TextInput,
  SelectInput,
  ReferenceInput,
  FunctionField,
} from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const ExperienceFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={4} md={3}>
          <ReferenceInput
            source="category"
            label="Category"
            reference={getResource('layouts', 'experience-categories')}
            sort={{ field: 'type', order: 'DESC' }}
            perPage={50}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText={(choice) => `${choice.type} ${choice.name}`} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <TextInput source="q" label="Search" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ExperienceFilter />} />
}

export const ExperienceList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="image"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField mini source="asset.fileDir" title="imagen competition" />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
          secondaryText={(record) => !!record.category && `${_get(record, 'category.type')} ${_get(record, 'category.name')}`}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField source="asset.fileDir" title="imagen competition" />
          </ReferenceField>
          <TextField label="Name" source="name" fullWidth />
          <FunctionField label="Category" render={(record) => `${_get(record, 'category.type')} ${_get(record, 'category.name')}`} />
          <DateField label="Created At" source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default ExperienceList
