import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
// import ClearIcon from '@material-ui/icons/Clear'
import CopyIcon from '@material-ui/icons/ContentCopy'
import LinkIcon from '@material-ui/icons/Link'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { dataProvider } from '../../../providers'
import { getResource } from '../../../config/resources'
import { CREATE_SH } from '../../../providers/dataProvider/types'

const styles = {}

class Clipboard extends Component {
  state = { showClear: false }

  copyToClipboard = (value, message) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(value)
        .then(this.props.showNotification(message || 'Copied to clipboard'))
        .catch((err) => {
          this.props.showNotification('Error copying to clipboard', 'warning')
        })
    } else {
      this.props.showNotification('This browser does not support this functionality.', 'warning')
    }
  }

  handleClickButtonCopy = (event) => {
    const { value } = this.props.source ? this.props.input : this.props
    event.preventDefault()
    this.copyToClipboard(value)
  }

  handleUrlShortener = (event) => {
    const { value } = this.props.source ? this.props.input : this.props
    event.preventDefault()
    dataProvider(CREATE_SH, getResource('shortUrls', 'short-urls'), {
      data: {
        url: value,
      },
    })
      .then(({ data }) => {
        const shortenedUrl = data.shortUrl
        this.copyToClipboard(shortenedUrl, 'Url shortened and copied to clipboard')
      })
      .catch((e) => {
        this.props.showNotification('Error when shortening the url', 'warning')
      })
  }

  handleMouseDownButtonCopy = (event) => {
    event.preventDefault()
  }

  render() {
    const { classes, urlShortener, ...rest } = this.props
    const { value } = this.props.source ? this.props.input : this.props
    const { ...restClasses } = classes

    return (
      <TextField
        classes={restClasses}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {urlShortener && (
                <IconButton
                  aria-label={'Shorten url'}
                  title={'Shorten url'}
                  disableRipple
                  onClick={this.handleUrlShortener}
                  onMouseDown={this.handleMouseDownButtonCopy}
                >
                  <LinkIcon />
                </IconButton>
              )}
              <IconButton aria-label={'Copy'} title={'Copy'} disableRipple onClick={this.handleClickButtonCopy} onMouseDown={this.handleMouseDownButtonCopy}>
                <CopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
        readOnly
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    )
  }
}
Clipboard = withStyles(styles)(Clipboard)

class ClipboardField extends Component {
  render() {
    if (this.props.source) {
      return <Field component={Clipboard} {...this.props} name={this.props.name ? this.props.name : this.props.source} />
    } else {
      return <Clipboard {...this.props} />
    }
  }
}

export default connect(null, {
  showNotification,
})(ClipboardField)
