import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceInput, Responsive, SelectInput, SimpleList, TextField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const SponsoringsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item sm={12} md={6}>
          <ReferenceInput
            sort={{ field: 'name', order: 'ASC' }}
            label="Brand"
            source="brand"
            reference={getResource('digitalAssets', 'brands')}
            alwaysOn
            fullWidth
            perPage={100}
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item sm={12} md={6}>
          <ReferenceInput
            sort={{ field: 'name', order: 'ASC' }}
            label="Digital Asset"
            source="digitalAsset"
            reference={getResource('digitalAssets', 'digital-assets')}
            alwaysOn
            fullWidth
            perPage={100}
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container alwaysOn>
        <Grid item sm={12} md={6}>
          <DateRangeInput source="startedAt" label="Start at" fullWidth alwaysOn />
        </Grid>
        <Grid item sm={12} md={6}>
          <DateRangeInput source="endAt" label="End at" fullWidth alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<SponsoringsFilter />} />
}

export const SponsoringsList = (props) => {
  return (
    <List bulkActions={false} title="Digital Assets" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => (
              <div>
                <TextField label="Brand" source="brand.name" record={record} />
                <TextField label="Digital Asset" source="digitalAsset.name" record={record} />
              </div>
            )}
            secondaryText={(record) => `${new Date(record.startedAt).toLocaleDateString()} - ${new Date(record.endAt).toLocaleDateString()}`}
          />
        }
        medium={
          <Datagrid>
            <TextField label="Brand" source="brand.name" sortable={false} />
            <TextField label="Digital Asset" source="digitalAsset.name" sortable={false} />
            <DateField source="startedAt" label="Start" />
            <DateField source="endAt" label="End" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default SponsoringsList
