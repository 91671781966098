import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { initialize, isSubmitting, change, formValueSelector } from 'redux-form'
import { SimpleForm, showNotification, fetchStart, fetchEnd, GET_LIST, GET_ONE, UPDATE, CREATE, DELETE } from 'react-admin'
import { ReferenceInput, SelectInput, NumberInput, BooleanInput } from 'react-admin'
import { AutocompleteInput } from '../../../../../components/inputs'
import { ConfirmationDialog } from '../../../../../components'
import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { ListItemsStyled, RowItemStyled } from '../../../utils/styles'
import { FormStyled } from './style'
import { personsRoles, roleCollection } from '../../../utils/constants'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const FORM = 'squads-form'
const selector = formValueSelector(FORM)

const styles = (theme) => {
  return {
    header: {
      fontSize: '1.5em',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
    },
    subTitle: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
    squad: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    form: {
      paddingLeft: '0px',
      paddingRight: '0px',
      maxHeight: '80vh',
      overflow: 'auto',
    },
    cellButton: {
      textAlign: 'center',
      paddingRight: '12px !important',
      width: '95px;',
      minWidth: '95px;',
    },
    trashIcon: {
      color: theme.palette.grey[500],
      margin: '0px 5px',
      '&:hover': {
        color: theme.palette.error.dark,
        cursor: 'pointer',
      },
    },
    editIcon: {
      margin: '0px 5px',
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
    },
    listItems: {
      borderTop: '1px solid',
      borderColor: theme.palette.grey[600],
    },
  }
}

class Squads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formActive: false,
      editSquad: false,
      dialogOpen: false,
      idDelete: null,
    }
  }

  componentDidMount() {
    const { idTeam, idSeason } = this.props
    this.initializeSquad(idTeam, idSeason)
  }

  initializeSquad(idTeam, idSeason) {
    const { fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'squads'), {
      sort: {
        field: 'personRole',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: {
        season: idSeason,
        team: idTeam,
      },
    })
      .then(({ data }) => {
        let squadPlayers = []
        let squadOthers = []
        data.map((item, key) => {
          if (item.personRole.role.id === personsRoles.jugador.id) {
            squadPlayers.push(item)
          } else {
            squadOthers.push(item)
          }
          return null
        })
        squadPlayers = _.orderBy(squadPlayers, ['position.id'], ['asc'])

        this.setState({
          formActive: false,
          editSquad: false,
        })

        this.props.initialize(FORM, {
          squadPlayers: squadPlayers,
          squadOthers: squadOthers,
          form: {},
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  newSquad() {
    const { change, idSeason, idTeam } = this.props

    let newForm = {
      season: {
        id: idSeason,
      },
      team: {
        id: idTeam,
      },
      current: true,
      loan: false,
      loanTo: false,
    }

    change(FORM, 'form', newForm)
    this.setState({
      formActive: true,
      editSquad: false,
    })
  }

  cancelEditSquad() {
    const { change } = this.props
    change(FORM, 'form', {})
    this.setState({
      formActive: false,
      editSquad: false,
    })
  }

  editPlayerSquad(idSquad) {
    const { fetchStart, fetchEnd, showNotification, change } = this.props

    fetchStart()
    dataProvider(GET_ONE, getResource('competiciones', 'squads'), {
      id: idSquad,
    })
      .then(({ data }) => {
        change(FORM, 'form', data)
        this.setState({
          formActive: true,
          editSquad: true,
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  saveSquad() {
    const { fetchStart, fetchEnd, showNotification, field, idSeason, idTeam } = this.props
    let form = field('form')

    fetchStart()
    dataProvider(UPDATE, getResource('competiciones', 'squads'), {
      id: form.id,
      data: form,
    })
      .then(({ data }) => {
        showNotification('Element updated', 'success')
        this.initializeSquad(idTeam, idSeason)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  createSquad() {
    const { fetchStart, fetchEnd, showNotification, field, idSeason, idTeam } = this.props
    let form = field('form')

    fetchStart()
    dataProvider(CREATE, getResource('competiciones', 'squads'), {
      data: form,
    })
      .then(({ data }) => {
        showNotification('Element created', 'success')
        this.initializeSquad(idTeam, idSeason)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  openConfirmDelete(idSquad) {
    this.setState({
      dialogOpen: true,
      idDelete: idSquad,
    })
  }

  deletePlayerSquad(idSquad) {
    const { fetchStart, fetchEnd, showNotification, idTeam, idSeason } = this.props

    fetchStart()
    dataProvider(DELETE, getResource('competiciones', 'squads'), {
      id: idSquad,
    })
      .then(({ data }) => {
        showNotification('Element deleted', 'success')
        this.initializeSquad(idTeam, idSeason)
        this.setState({
          dialogOpen: false,
          idDelete: null,
        })
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  ToolbarSquads = () => {
    const { formActive, editSquad } = this.state
    return (
      <Grid container>
        {!formActive && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => this.newSquad()}>
              Add
            </Button>
          </Grid>
        )}
        {formActive && (
          <Fragment>
            <Grid item>
              <Button variant="contained" onClick={() => this.cancelEditSquad()}>
                Cancel
              </Button>
            </Grid>
            {!editSquad && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => this.createSquad()}>
                  Create
                </Button>
              </Grid>
            )}
            {editSquad && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => this.saveSquad()}>
                  Save
                </Button>
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
    )
  }

  ListPlayers = () => {
    const { classes, field } = this.props
    const squadPlayers = field('squadPlayers')

    return (
      <Grid container>
        <Grid item xs={12} className={classes.subTitle}>
          <Typography variant="h3" headlineMapping="h3">
            Players
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListItemsStyled className={classes.listItems}>
            {squadPlayers &&
              squadPlayers.map((player, key) => {
                return (
                  <RowItemStyled key={key}>
                    <div className="full">{player.personRole.person.name}</div>
                    <div>{player.position && player.position.name}</div>
                    <div className={classes.cellButton}>
                      <EditIcon className={classes.editIcon} aria-label="Edit" onClick={() => this.editPlayerSquad(player.id)} />
                      <DeleteIcon className={classes.trashIcon} aria-label="Delete" onClick={() => this.openConfirmDelete(player.id)} />
                    </div>
                  </RowItemStyled>
                )
              })}
          </ListItemsStyled>
        </Grid>
      </Grid>
    )
  }

  ListOthers = () => {
    const { classes, field } = this.props
    const squadOthers = field('squadOthers')

    return (
      <Grid container>
        <Grid item xs={12} className={classes.subTitle}>
          <Typography variant="h3" headlineMapping="h3">
            Others
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListItemsStyled className={classes.listItems}>
            {squadOthers &&
              squadOthers.map((player, key) => {
                return (
                  <RowItemStyled key={key}>
                    <div className="full">{player.personRole.person.name}</div>
                    <div>{player.personRole.role.name}</div>
                    <div className={classes.cellButton}>
                      <EditIcon className={classes.editIcon} aria-label="Edit" onClick={() => this.editPlayerSquad(player.id)} />
                      <DeleteIcon className={classes.trashIcon} aria-label="Delete" onClick={() => this.openConfirmDelete(player.id)} />
                    </div>
                  </RowItemStyled>
                )
              })}
          </ListItemsStyled>
        </Grid>
      </Grid>
    )
  }

  FormSquad = () => {
    const { field } = this.props
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <BooleanInput label="Current" source="form.current" />
        </Grid>
        <Grid item xs={12} md={4}>
          <BooleanInput label="Loan" source="form.loan" />
        </Grid>
        <Grid item xs={12} md={4}>
          <BooleanInput label="Loan To" source="form.loanTo" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="Rol"
            source="form.personRole.role.id"
            reference={getResource('competiciones', 'roles')}
            filter={{ collection: roleCollection.squad.type }}
          >
            <SelectInput allowEmpty fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={8}>
          <ReferenceInput
            label="Person"
            source="form.personRole.id"
            reference={getResource('competiciones', 'peopleroles')}
            filter={{ role: field('form.personRole.role.id') }}
            fullWidth
          >
            <AutocompleteInput allowEmpty optionText="person.name" fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberInput label="Shirt Number" source="form.shirtNumber" fullWidth />
        </Grid>
        <Grid item xs={12} md={8}>
          <ReferenceInput label="Position" source="form.position.id" reference={getResource('competiciones', 'positions')}>
            <AutocompleteInput fullWidth />
          </ReferenceInput>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { label, classes, isSubmitting } = this.props
    const { formActive, dialogOpen, idDelete } = this.state
    const { ListPlayers, ListOthers, FormSquad, ToolbarSquads } = this
    return (
      <FormStyled>
        <SimpleForm
          resource={getResource('competiciones', 'squads')}
          saving={isSubmitting}
          form={FORM}
          // save={()=>this.updateSquads()}
          toolbar={<ToolbarSquads />}
        >
          <Grid container className={classes.form}>
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h2" headlineMapping="h2">
                {label}
              </Typography>
            </Grid>
            {formActive ? (
              <Grid item xs={12}>
                <FormSquad />
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.squad}>
                <ListPlayers />
                <ListOthers />
                <ConfirmationDialog
                  open={dialogOpen}
                  title="Are you sure?"
                  onCancel={() => {
                    this.setState({
                      dialogOpen: false,
                      idDelete: null,
                    })
                  }}
                  onSuccess={() => this.deletePlayerSquad(idDelete)}
                />
              </Grid>
            )}
          </Grid>
        </SimpleForm>
      </FormStyled>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(Squads)
