import { Grid } from '@material-ui/core'
import React from 'react'
import { required, TextInput } from 'react-admin'

import { LanguagesNav } from '../../../../components'
import GameweekSelector from './GameweekSelector'

export const PageForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <GameweekSelector {...props} />
    <Grid container>
      <Grid items xs={12}>
        <TextInput fullWidth source="description" label="Note" validate={[required()]} />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default PageForm
