import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_MANY } from 'react-admin'

import { ConfirmationDialog } from '../../'
import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import NewRelatedNewsForm from './newRelatedNewsForm'
import { ContainerStyled, ListItemStyled } from './style'

const styles = (theme) => ({
  listItem: {
    marginBottom: '4px',
  },
})

class RelatedNewsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      choose: [],
      open: false,
      auxList: [],
      list: [],
    }
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props

    this.getRelatedNewsById(value, (list) => {
      this.setState({
        list: list,
      })
    })
  }

  getRelatedNewsById(ids, onChange) {
    if (ids.length > 0) {
      dataProvider(GET_MANY, getResource('news', 'noticias'), {
        sort: {
          field: 'title',
          order: 'ASC',
        },
        ids: ids,
      }).then((response) => {
        onChange(response.data)
      })
    }
  }

  handleRemoveNew(index) {
    const { onChange } = this.props.input ? this.props.input : this.props
    const { list } = this.state

    const newValue = [...list]
    newValue.splice(index, 1)
    this.setState(
      {
        list: newValue,
      },
      () => {
        onChange(newValue)
      }
    )
  }

  returnSelectedList() {
    const { list } = this.state

    if (list.length <= 0) return null
    return (
      <List>
        {_.map(list, (item, index) => (
          <ListItemStyled key={item.id}>
            <ListItemText primary={item.title} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.handleRemoveNew(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemStyled>
        ))}
      </List>
    )
  }

  handleSubmit(list) {
    const { onChange } = this.props.input ? this.props.input : this.props
    this.setState(
      {
        list: list,
        open: false,
      },
      () => onChange(list)
    )
  }

  render() {
    const { label, slug } = this.props
    const { list, auxList, isModalFetching } = this.state

    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel>{label}</InputLabel>
        <ContainerStyled>
          {this.returnSelectedList()}
          <Button mini variant="fab" color="primary" onClick={() => this.setState({ open: true })}>
            <AddIcon />
          </Button>
          <ConfirmationDialog
            title="Related news"
            maxWidth="md"
            isFetching={isModalFetching}
            open={this.state.open}
            onCancel={() => this.setState({ open: false })}
            onSuccess={() => this.handleSubmit(auxList)}
          >
            <NewRelatedNewsForm
              value={list}
              slug={slug}
              setFetching={(fetching) =>
                this.setState({
                  isModalFetching: fetching,
                })
              }
              onChange={(list) => {
                this.setState({
                  auxList: list,
                })
              }}
            />
          </ConfirmationDialog>
        </ContainerStyled>
      </FormControl>
    )
  }
}

export default withStyles(styles)(RelatedNewsList)
