import { Grid, withStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import React from 'react'
import {
  ArrayField,
  ChipField,
  CloneButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  Responsive,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
  Pagination,
  DeleteButton,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { ImageField, ListActions, TableRowList } from '../../../components'
import { MetadataInput } from '../../../components/inputs'

const ImagesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput source="name" label="Name" alwaysOn />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput source="caption" label="Caption" alwaysOn />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MetadataInput source="metadatas" label="Metadatas" alwaysOn />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImagesFilter />} />
}

const PostPagination = (props) => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

const styles = (theme) => ({
  deleteStyle: {
    color: fade(theme.palette.error.main, 0.9),
    padding: '2px 6px 2px 2px',
  },
})

export const ImageList = (props) => {
  return (
    <List perPage={25} bulkActions={false} actions={<Actions />} title="Imágenes" {...props} pagination={<PostPagination />}>
      <Responsive
        small={<SimpleList leftAvatar={(record) => <ImageField record={record} label="State" title="imagen" />} primaryText={(record) => record.name} />}
        medium={
          <Datagrid>
            <ImageField label="Image" title="imagen" sizes={['small', 'medium', 'large', 'xlarge']} />
            <FunctionField source="name" label="Name" render={(record) => <Link to={`${props.basePath}/${record.id}`}>{record.name}</Link>} />
            <TextField source="caption" label="caption" />
            <DateField source="createdAt" />
            <DateField source="publishedAt" />
            <TableRowList>
              <EditButton />
              <CloneButton />
              <DeleteButton className={props.classes.deleteStyle} />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}
export default withStyles(styles)(ImageList)
