import React from 'react'
import LanguagesNav from '../../../../components/languagesNav'
import Form from './form'

export const ChannelMatchesForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Form form="record-form" />
  </LanguagesNav>
)

export default ChannelMatchesForm
