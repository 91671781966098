import { withStyles, ListItemSecondaryAction, Button, List, ListItem, ListItemText, Avatar, Typography, CircularProgress } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Edit'
import React, { Component, Fragment } from 'react'
import moment from 'moment-timezone'
import { GET_LIST, Pagination } from 'react-admin'
import { Link } from 'react-router-dom'

import { dataProvider } from '../../../../../providers'
import { getResource } from '../../../../../config/resources'
import { getResizeUrl } from '../../../../../config/utils'

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '0px',
    marginBottom: '10px',
  },
  feching: {
    position: 'absolute',
    width: 'calc(100% + 10px)',
    height: '100%',
    top: '0px',
    left: '-5px',
    background: 'rgba(0, 0, 0, 0.10)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  image: {
    width: '100px',
    minHeight: '65px',
  },
  button: {
    margin: theme.spacing.unit,
    display: 'inline-block',
    textDecoration: 'none',
    '& svg': {
      marginLeft: '5px',
      width: '1rem',
      height: '1rem',
    },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    marginBottom: '5px',
  },
})

class ListActs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      acts: [],
      page: 1,
      perPage: 10,
      total: 0,
      isFetching: false,
    }
  }

  componentDidMount() {
    const { idEvent } = this.props
    const { page, perPage } = this.state
    this.getActs(idEvent, page, perPage)
  }

  getActs(idEvent, page, perPage) {
    this.setState({ isFetching: true })
    dataProvider(GET_LIST, getResource('assets', 'acts'), {
      sort: {
        field: 'startedAt',
        order: 'DESC',
      },
      pagination: {
        page: page,
        perPage: perPage,
      },
      filter: {
        event: idEvent,
      },
    })
      .then(({ data, total }) => {
        this.setState({
          acts: data,
          page: page,
          perPage: perPage,
          total: total,
        })
      })
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  formatDate(time) {
    return moment(time).tz('Europe/Madrid').format('L')
  }

  render() {
    const { acts, isFetching } = this.state
    const { classes } = this.props

    return (
      <div style={{ position: 'relative' }}>
        <Typography variant="body1" className={classes.label}>
          Acts
        </Typography>
        <List className={classes.root}>
          {acts &&
            acts.map((item, key) => (
              <ListItem className={classes.listItem} key={key}>
                {item.image && (
                  <Avatar className={classes.image}>
                    <img src={getResizeUrl(item.image, ['xsmall', 'small', 'medium'])} />
                  </Avatar>
                )}
                <ListItemText primary={item.name} secondary={this.formatDate(item.startedAt)} />
                <ListItemSecondaryAction>
                  <Link to={`/${getResource('assets', 'acts')}/${item.id}`} target="_blank" rel="noopener noreferrer" className={classes.button}>
                    <Button variant="contained" size="small" color="primary">
                      Edit
                      <ImageIcon className={classes.iconSmall} />
                    </Button>
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <Pagination
          setPage={(page) => this.getActs(this.props.idEvent, page, this.state.perPage)}
          setPerPage={(perPage) => this.getActs(this.props.idEvent, 1, perPage)}
          total={this.state.total}
          perPage={this.state.perPage}
          page={this.state.page}
          isLoading={false}
        />
        {isFetching && (
          <div className={classes.feching}>
            <CircularProgress className={classes.progress} thickness={7} />
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ListActs)
