import { Grid, Paper, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { GET_LIST, BooleanInput, fetchEnd, fetchStart, LongTextInput, NumberInput, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'
import { MatchField } from '../../../../components/field'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import { POST_MANY } from '../../../../providers/dataProvider/types'
import InfoGameweek from './InfoGameweek'
import { BallInput } from '../../../../components/inputs'
import _get from 'lodash/get'

const FORM = 'record-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  boxMatch: {
    marginBottom: '20px',
  },
  rowBall: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    '& .input-balls': {
      flex: '1 1 auto',
    },
    '& .group-switchs': {
      display: 'flex',
    },
  },
})

export class AttemptsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      balls: [],
    }
    this.handleChangeBall = this.handleChangeBall.bind(this)
  }

  componentDidMount() {
    const { id, record } = this.props
    const idSeason = _get(record, 'round.subscription.season.id')
    this.initializeMatches(id)
    this.getBalls(idSeason)
  }

  initializeMatches(idGameweek) {
    const { change, field, showNotification, fetchStart, fetchEnd } = this.props
    const lang = field('lang')
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
      data: {
        lang: lang ? lang : 'es',
      },
      sort: {
        field: 'date',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        gameweek: idGameweek,
      },
    })
      .then((response) => {
        change(FORM, 'matches', response.data)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  getBalls(idSeason) {
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'balls'), {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'id', order: 'DESC' },
      filter: { season: idSeason },
    })
      .then(({ data }) => {
        this.setState({
          balls: data,
        })
      })
      .finally(() => {
        fetchEnd()
      })
  }

  updateAttempts() {
    const { field, showNotification, fetchStart, fetchEnd } = this.props
    const matches = field('matches')
    const lang = field('lang')

    if (matches) {
      const matchesAttempt = matches.map((item) => {
        return {
          id: item.id,
          attempt: item.attempt,
          attemptOfficial: item.attemptOfficial,
          attemptNote: item.attemptNote,
          matchBalls: item.matchBalls,
          showBall: item.showBall,
          ballBlocked: item.ballBlocked,
        }
      })
      fetchStart()
      dataProvider(POST_MANY, getResource('competiciones', 'matches-update'), {
        data: {
          lang: lang ? lang : 'es',
          arrayData: matchesAttempt,
        },
      })
        .then((response) => {
          showNotification('Save success', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  handleChangeBall = (baseSource) => {
    const { change } = this.props
    change(FORM, `${baseSource}ballBlocked`, true)
  }

  render() {
    const { isSubmitting, record, field, classes } = this.props
    const matches = field('matches')

    return (
      <LanguagesNav {...this.props} saving={isSubmitting} form={FORM} save={() => this.updateAttempts()}>
        <InfoGameweek record={record} />
        {matches &&
          matches.map((item, key) => {
            const baseSource = `matches[${key}].`
            return (
              <Paper className={classes.boxMatch} key={`match-${item.id}`}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <MatchField
                      baseSource={baseSource}
                      record={{
                        matches: matches,
                      }}
                      basePath={getResource('assets', 'images')}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <NumberInput source={`${baseSource}attempt`} label="Attempt" fullWidth />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <BooleanInput source={`${baseSource}attemptOfficial`} label="Official" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <LongTextInput source={`${baseSource}attemptNote`} label="Note" />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.rowBall}>
                      <div className="input-balls">
                        <BallInput
                          label="Balls"
                          source={`${baseSource}matchBalls`}
                          choices={this.state.balls}
                          onChange={() => this.handleChangeBall(baseSource)}
                        />
                      </div>
                      <div className="group-switchs">
                        <BooleanInput label="Show" source={`${baseSource}showBall`} style={{ marginLeft: '8px' }} />
                        <BooleanInput label="Blocked" source={`${baseSource}ballBlocked`} style={{ marginLeft: '8px' }} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(AttemptsForm)
