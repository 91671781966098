import React from 'react'
import { List, Datagrid, TextField, EditButton, Filter, TextInput } from 'react-admin'
import { TableRowList, ListActions } from '../../../components'

const CountriesFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<CountriesFilters />} />
}

export const CountriesList = (props) => {
  return (
    <List bulkActions={false} title="Countries" {...props} actions={<Actions />}>
      <Datagrid>
        <TextField source="id" label="ISO" />
        <TextField source="name" label="Name" />
        <TextField source="area" label="Area" />
        <TableRowList>
          <EditButton />
        </TableRowList>
      </Datagrid>
    </List>
  )
}

export default CountriesList
