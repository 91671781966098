import styled from 'styled-components'
import theme from '../../../config/theme'

export const ListItemsStyled = styled.div`
  border-right: 1px solid ${theme.palette.grey[600]};
  border-left: 1px solid ${theme.palette.grey[600]};
  border-bottom: 1px solid ${theme.palette.grey[600]};
  width: 100%;
  display: table;
  font-family: ${theme.typography.fontFamily};
`
export const RowItemStyled = styled.div`
  display: table-row;
  box-sizing: border-box;
  background: #fff;
  &.sticky {
    background: #fff;
    top: 0px;
    position: sticky;
    z-index: 1;
    & > div {
      border-top: 1px solid ${theme.palette.grey[600]};
    }
  }
  &.selected {
    background: ${theme.palette.primary.light};
  }
  & > div {
    box-sizing: border-box;
    padding: 10px 56px 10px 10px;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid ${theme.palette.grey[200]};
    &.head {
      color: ${theme.palette.grey[700]} &.order {
        justify-content: space-between;
      }
      &.textfield {
        padding: 5px;
      }
    }
    &.order {
      padding: 10px;
      text-align: center;
      width: 70px;
      min-width: 70px;
      svg {
        display: inline-block;
      }
    }
    &.full {
      width: 100%;
    }
  }
`
