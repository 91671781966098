import React from 'react'
import Management from './Management'
import { Field } from 'redux-form'

class SubgroupManagementInput extends React.Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source) {
      return <Field component={Management} name={source} {...rest} />
    } else {
      return <Management {...this.props} />
    }
  }
}

export default SubgroupManagementInput
