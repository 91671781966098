import { Button, LinearProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import { showNotification, TextInput } from 'react-admin'
import { connect } from 'react-redux'

import { ConfirmationDialog, SubForm } from '../../../../components'
import { ClipboardField } from '../../../../components/formFields'
import { getResource } from '../../../../config/resources'
import { dataProvider } from '../../../../providers'
import { CREATE_SH, GET } from '../../../../providers/dataProvider/types'
import { isUrl, isAllowedDomain } from './utils'

class UrlShortener extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      clipboardText: null,
      allowedDomains: null,
      ready: false,
    }

    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleShortenerSuccess = this.handleShortenerSuccess.bind(this)
  }

  componentDidUpdate() {
    const { isModalOpen, allowedDomains } = this.state
    if (isModalOpen && !allowedDomains) {
      dataProvider(GET, getResource('shortUrls', 'domains'), {}).then(({ data }) => {
        this.setState({
          allowedDomains: data,
          ready: true,
        })
      })
    }
  }

  handleModalOpen() {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      clipboardText: null,
    })
  }

  handleShortenerSuccess({ url }) {
    const { showNotification } = this.props

    this.setState({
      ready: false,
    })

    dataProvider(CREATE_SH, getResource('shortUrls', 'short-urls'), {
      data: {
        url,
      },
    })
      .then(({ data }) => {
        this.setState({
          clipboardText: data,
          ready: true,
        })
      })
      .catch((e) => {
        showNotification((e.body && e.body.detail) || 'Error when shortening the url', 'warning')
      })
  }

  render() {
    const { isModalOpen, clipboardText, allowedDomains, ready } = this.state

    return (
      <>
        <Button onClick={this.handleModalOpen}>Url Shortener</Button>
        <ConfirmationDialog title="Url Shortener" open={isModalOpen} maxWidth="md" dialogActions={false} onCancel={this.handleModalClose}>
          {clipboardText ? (
            <>
              <ClipboardField label="Short url" value={clipboardText.shortUrl} fullWidth />
              <TextField readOnly label="Views" value={clipboardText.views} fullWidth style={{ marginTop: '1rem' }} />
            </>
          ) : (
            <>
              <SubForm form="url_shortener" isFetching={!ready} onClickSave={this.handleShortenerSuccess} onClose={this.handleModalClose}>
                <TextInput validate={[isUrl, isAllowedDomain(allowedDomains)]} fullWidth source="url" label="Url" />
              </SubForm>
              <p>Allowed Domains:</p>
              {allowedDomains && Array.isArray(allowedDomains) ? (
                <ul>
                  {allowedDomains.map((domain, key) => (
                    <li key={key}>{domain.domain}</li>
                  ))}
                </ul>
              ) : (
                <div style={{ marginTop: '1rem', width: '200px' }}>
                  <LinearProgress />
                </div>
              )}
            </>
          )}
        </ConfirmationDialog>
      </>
    )
  }
}

export default connect(null, {
  showNotification,
})(UrlShortener)
