import styled from 'styled-components'
import { colors } from '../../../config/theme'
import styles from 'ra-input-rich-text/lib/styles'

export const ContainerStyled = styled.div`
  width: 100%;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`

export const ContaierAddMarket = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & button {
    margin-right: 10px;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
`
export const ContaierAddMarketLoading = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% + 10px);
  opacity: 0.8;
  & > div {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
`

export const AddMarketStyled = styled.div`
  color: ${colors.primary.main};
  cursor: pointer;
`

export const EmptyStyled = styled.div`
  min-height: 347px;
  display: flex;
  align-items: center;
  justify-content: center;
`
