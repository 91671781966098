import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST, Pagination, ReferenceField } from 'react-admin'

import { getResource } from '../../../config/resources'
import { colors } from '../../../config/theme'
import dataProvider from '../../../providers'
import ItemListLayout from './itemListLayout'
import { ContentListStyled, FooterStyled, GrowStyled, ImageFieldStyled } from './style'

const IMAGES = 'images'
const VIDEOS = 'videos'
const GALLERIES = 'galleries'
const NEWS = 'news'
const LIST_VIEW_LIST = 'list'
const LIST_VIEW_SELECTED = 'listSelected'

const styles = {
  pagination: {
    backgroundColor: 'transparent',
  },
}

class ImageList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: null,
      selectedImg: [],
      selectedView: LIST_VIEW_LIST,
      page: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    this.setState(
      {
        selectedImg: this.props.selectedImg,
      },
      () => this.setList(this.state.page, this.state.perPage, this.props.filters)
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filters !== this.props.filters) {
      this.setList(this.state.page, this.state.perPage, nextProps.filters)
    }
  }

  setList(page, perPage, filters) {
    const newPage = _.isEqual(filters, this.state.filters) ? page : 1
    const { resource } = this.props
    this.setState({
      list: null,
      isFetching: true,
    })

    return dataProvider(GET_LIST, resource ? resource : getResource('assets', this.props.type), {
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: newPage, perPage: perPage },
      filter: filters,
    })
      .then((response) => {
        this.setState({
          error: null,
          list: response.data,
          total: response.total,
          page: newPage,
          perPage: perPage,
          filters: filters,
        })
      })
      .catch((error) => {
        this.setState({
          error: error,
        })
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        })
      })
  }

  //PUSH ITEM
  selectItem(item) {
    const { multiselect, onChange } = this.props
    if (multiselect) {
      const mySelectedItems = this.state.selectedImg ? [...this.state.selectedImg] : []
      const indexItem = _.findIndex(mySelectedItems, { id: item.id })
      if (indexItem > -1) {
        mySelectedItems.splice(indexItem, 1)
      } else {
        mySelectedItems.push(item)
      }
      this.setState({ selectedImg: mySelectedItems })
      onChange(mySelectedItems)
    } else {
      this.setState({ selectedImg: item })
      onChange(item)
    }
  }

  isSelected(item) {
    const { multiselect } = this.props
    if (!this.state.selectedImg) return false
    if (multiselect) {
      return _.find(this.state.selectedImg, { id: item.id })
    } else {
      return item.id === this.state.selectedImg.id
    }
  }

  renderList(list) {
    //ERROR
    if (this.state.error) {
      return <ContentListStyled>Error</ContentListStyled>
    }
    //IS FETCHING
    if (this.state.isFetching) {
      let list = []
      for (var i = 0; i < this.state.perPage; i++) {
        list.push(<ItemListLayout isFetching />)
      }
      return list
    }
    //LIST
    if (list && list.length > 0) {
      let myList = []
      myList = list.map((item) => {
        if (this.props.type === VIDEOS) {
          const ImageVideo = () => {
            if (item.imageVideo) {
              return <ImageFieldStyled mini record={item.imageVideo} sizes={['small', 'medium', 'large', 'xlarge']} sourceImage="" />
            } else if (item.providerThumbnails) {
              return <ImageFieldStyled record={item} source="providerThumbnails.small" title="imagen" mini />
            } else {
              return null
            }
          }
          return (
            <ItemListLayout
              key={item.id}
              image={<ImageVideo />}
              text={item.name}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        if (this.props.type === GALLERIES) {
          return (
            <ItemListLayout
              key={item.id}
              image={<ImageFieldStyled mini sourceImage="prominentImage.content" sizes={['small', 'medium', 'large', 'xlarge']} record={item} title="imagen" />}
              text={item.name}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        if (this.props.type === NEWS) {
          return (
            <ItemListLayout
              key={item.id}
              image={
                <ReferenceField
                  linkType={false}
                  record={item}
                  basePath={getResource('assets', 'images')}
                  source="imageDefault"
                  reference={getResource('assets', 'images')}
                  allowEmpty
                >
                  <ImageFieldStyled mini sizes={['small', 'medium', 'large', 'xlarge']} />
                </ReferenceField>
              }
              text={item.title}
              onClick={() => {
                this.selectItem(item)
              }}
              selected={this.isSelected(item)}
            />
          )
        }
        return (
          <ItemListLayout
            key={item.id}
            image={<ImageFieldStyled mini record={item} sizes={['small', 'medium', 'large', 'xlarge']} />}
            text={item.name}
            onClick={() => {
              this.selectItem(item)
            }}
            selected={this.isSelected(item)}
          />
        )
      })
      return myList
    } else {
      return <span>No Results</span>
    }
  }

  render() {
    return (
      <div>
        {!this.state.list && <LinearProgress classes={{ colorPrimary: colors.primary.light, barColorPrimary: colors.primary.main }} />}
        {this.state.selectedView === LIST_VIEW_LIST && (
          <React.Fragment>
            <ContentListStyled>{this.renderList(this.state.list)}</ContentListStyled>
            <FooterStyled>
              {this.state.list && (
                <Pagination
                  setPage={(page) => this.setList(page, this.state.perPage, this.props.filters)}
                  setPerPage={(perPage) => this.setList(this.state.page, perPage, this.props.filters)}
                  total={this.state.total}
                  perPage={this.state.perPage}
                  page={this.state.page}
                />
              )}
              <GrowStyled />
              <Button
                onClick={() => this.props.onSubmit(this.state.selectedImg)}
                variant="contained"
                color="primary"
                disabled={!this.state.list || this.state.list <= 0 || !this.state.selectedImg}
              >
                ADD
              </Button>
            </FooterStyled>
          </React.Fragment>
        )}
        {this.state.selectedView === LIST_VIEW_SELECTED && (
          <React.Fragment>
            <ContentListStyled>{this.renderList(this.state.selectedImg)}</ContentListStyled>
            <FooterStyled></FooterStyled>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ImageList)
