import slugify from 'slugify'

export const slugifyFiler = (value) => {
  slugify.extend({ '/': '-' })
  return slugify(value, {
    replacement: '-',
    remove: /[*+/~\\.,()'"¿?¡!:@]/g,
    lower: true,
  })
}

export const getResizeUrl = (image, priority = ['xlarge', 'large', 'medium']) => {
  let url = null
  if (image.resizes) {
    let { resizes } = image

    priority.forEach((element) => {
      if (!url) {
        const eachResize = image.resizes.find((item) => item.type === element)
        url = eachResize.url
      }
    })
  }

  return url
}

export const IS = ['Object', 'Arguments', 'Function', 'String', 'Number', 'Date', 'RegExp', 'Array'].reduce((exp, name) => {
  exp[name.toLowerCase()] = (x) => Object.prototype.toString.call(x) === `[object ${name}]`
  return exp
}, {})
// Faster checks
IS.object = (obj) => obj === Object(obj)
