import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import LanguagesNav from '../../../../components/languagesNav'
import { DisabledInput, TextInput, NumberInput, required, maxLength, number, minValue, ReferenceInput, SelectInput, BooleanInput } from 'react-admin'
import { RichTextInput, ImageInput, DateTimeInput } from '../../../../components/inputs'
import { getResource } from '../../../../config/resources'

const styles = (theme) => ({
  currentStyled: {
    '& label': {
      justifyContent: 'flex-end',
    },
  },
})

export const ExperienceForm = ({ ...props }) => (
  <LanguagesNav {...props} romeveDelete>
    <Grid container alignItems="flex-end">
      <Grid item xs={12}>
        <BooleanInput source="current" className={props.classes.currentStyled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReferenceInput
          source="category.id"
          label="Category"
          reference={getResource('layouts', 'experience-categories')}
          sort={{ field: 'type', order: 'DESC' }}
          perPage={50}
          allowEmpty
          validate={[required()]}
          fullWidth
        >
          <SelectInput optionText={(choice) => `${choice.type} ${choice.name}`} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput label="Start" source="startedAt" date time fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <DisabledInput fullWidth label="Slug" source="slug" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth label="Hashtag" source="hashtag" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth label="Instagram" source="instagram" validate={[maxLength(255)]} />
      </Grid>
      <Grid item xs={12}>
        <RichTextInput source="description" label="Description" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="club" label="Clubs" validate={[number(), minValue(0)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="broadcaster" label="Broadcasters" validate={[number(), minValue(0)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="influencer" label="Influencers" validate={[number(), minValue(0)]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput fullWidth source="challenge" label="Challenges" validate={[number(), minValue(0)]} />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          source="image"
          label="Image"
          accept="image/*"
          options={{
            remove: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          source="imageSecondary"
          label="Image header (1920x255)"
          accept="image/*"
          options={{
            remove: true,
          }}
        />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default withStyles(styles)(ExperienceForm)
