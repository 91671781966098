import React from 'react'
import Grid from '@material-ui/core/Grid'
import { SimpleList, Responsive, List, Filter, Datagrid, TextField, TextInput } from 'react-admin'
import { TableRowList, ListActions, ImageField, CloneButton } from '../../../components'
import { DateRangeInput } from '../../../components/inputs'
import { getLink } from '../../../config/resources'
import { DateField } from '../../../components/field'

const ImagesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="title" label="Title" alwaysOn />
        </Grid>
        <Grid container alwaysOn>
          <Grid item xs={12} sm={12} md={6}>
            <DateRangeInput label="Published at" source="publishedAt" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DateRangeInput label="Created at" source="createdAt" fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImagesFilter />} />
}

export const ExternalNewsList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => <ImageField source="asset" record={record} label="Image" title="imagen" />}
          primaryText={(record) => record.title}
        />
      }
      medium={
        <Datagrid>
          <ImageField label="Image" source="asset" title="image external new" />
          <TextField source="title" label="title" />
          <TextField source="source" label="Source" />
          <DateField source="createdAt" />
          <DateField source="publishedAt" />
          <TableRowList>
            <CloneButton
              source="slug"
              onClone={(item) => {
                window.location.href = `${window.location.origin}/#/${getLink('news', 'noticias')}/${item.id}`
              }}
            />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default ExternalNewsList
