import React from 'react'
import { Create } from 'react-admin'

import SocialMediaProfilesForm from './form'

export const SocialMediaProfilesCreate = (props) => (
  <Create {...props}>
    <SocialMediaProfilesForm isEdit={false} {...props} />
  </Create>
)

export default SocialMediaProfilesCreate
