import React from 'react'
import { Edit } from 'react-admin'

import SocialMediaProfilesForm from './form'

const SocialMediaProfiles = (props) => (
  <Edit {...props}>
    <SocialMediaProfilesForm isEdit={true} {...props} />
  </Edit>
)

export default SocialMediaProfiles
