import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField, TextInput, SelectInput, ReferenceInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const ExperienceFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12}>
          <TextInput source="q" label="Search" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ExperienceFilter />} />
}

export const ExperienceList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Imágenes" {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="image"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField sizes={['small', 'medium', 'large', 'xlarge']} />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField sizes={['small', 'medium', 'large', 'xlarge']} />
          </ReferenceField>
          <TextField label="Name" source="name" fullWidth />
          <ReferenceField label="Season" source="subscription" reference={getResource('competiciones', 'subscriptions')} linkType={false} sortable={false}>
            <TextField source="season.name" fullWidth />
          </ReferenceField>
          <DateField label="Created At" source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default ExperienceList
