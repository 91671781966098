import React from 'react'
import { Edit } from 'react-admin'

import CompetitionsForm from './form'

export const ClubsEdit = (props) => (
  <Edit undoable={false} {...props}>
    <CompetitionsForm isEdit={true} {...props} />
  </Edit>
)

export default ClubsEdit
