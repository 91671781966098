import _ from 'lodash'
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    const list = _.cloneDeep(this.props.list)
    this.setState({
      items: list,
    })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(this.props.list, result.source.index, result.destination.index)

    this.setState(
      {
        items,
      },
      () => (this.props.onChange ? this.props.onChange(items) : null)
    )
  }

  render() {
    if (!this.props.list) return null
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef}>
              {this.props.list.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div>{item.content}</div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export default App
