import React from 'react'
import { Edit } from 'react-admin'

import DigitalAssetsForm from './form'

const DigitalAssetsTitle = ({ record }) => {
  return <span>{`Digital Asset: ${record.nombre}`}</span>
}

export const DigitalAssetsEdit = (props) => (
  <Edit undoable={false} title={<DigitalAssetsTitle />} {...props}>
    <DigitalAssetsForm {...props} isEdit={true} />
  </Edit>
)

export default DigitalAssetsEdit
