import Grid from '@material-ui/core/Grid'
import React from 'react'
import { List, Datagrid, ReferenceField, TextField, EditButton, Filter, TextInput, Responsive, SimpleList, ReferenceInput, SelectInput } from 'react-admin'
import { ImageField } from '../../../components/field'
import { AutocompleteInput } from '../../../components/inputs'
import { TableRowList, ListActions } from '../../../components'
import { getResource } from '../../../config/resources'
import { choicesTeamType } from '../utils/constants'

const TeamsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} md={3}>
          <SelectInput allowEmpty source="type" label="Type" choices={choicesTeamType()} fullWidth alwaysOn />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput className={props.classes.input} variant="filled" label="Search" source="q" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReferenceInput
            className={props.classes.input}
            variant="filled"
            label="Club"
            source="club"
            reference={getResource('competiciones', 'clubs')}
            alwaysOn
            fullWidth
          >
            <AutocompleteInput fullWidth />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<TeamsFilter />} />
}

const TeamsList = (props) => (
  <List bulkActions={false} title="Teams" actions={<Actions />} {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="shield"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField mini source="asset.fileDir" title="imagen competition" />
            </ReferenceField>
          )}
          primaryText={(record) => record.selectorName}
          secondaryText={(record) => record.type}
          tertiaryText={(record) => record.shortname}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Shield" source="shield" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField source="asset.fileDir" title="imagen competition" />
          </ReferenceField>
          <TextField source="type" label="Type" />
          <TextField source="shortname" label="Shortname" />
          <TextField source="selectorName" label="Name" />
          <TextField source="club.nickname" label="Club" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default TeamsList
