import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Switch from '@material-ui/core/Switch'
import MenuListItem from './menuListItem'
import React, { Component } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ListItemTextStyled, ListItemStyled } from './style'
import { Responsive } from 'react-admin'

import { ImgItemListStyled, ItemListFetchingStyled, ItemListStyled, SmallItemListStyled, SmallItemListOptionsStyled } from './style'

class RenderCheckbox extends Component {
  render() {
    const { checkOptions, checked, handleChangeCheckbox, index, sm } = this.props
    if (checkOptions && checkOptions.checkboxActions && handleChangeCheckbox) {
      return (
        <FormControlLabel
          control={<Switch checked={checked} onChange={(e) => handleChangeCheckbox(index, e.target.checked)} color="primary" />}
          label={!sm && checkOptions && checkOptions.checkboxActions.label ? checkOptions && checkOptions.checkboxActions.label : null}
          labelPlacement="top"
        />
      )
    } else return null
  }
}

class ItemListLayout extends Component {
  handleClick() {
    this.props.onClick(this.props.value)
  }

  truncateString(str, length) {
    const ending = '...'
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending
    } else {
      return str
    }
  }

  render() {
    const { type, image, text, selected, canChangePosition, provided, options, index, handleChangeCheckbox, ...rest } = this.props

    const RenderDrag = () => {
      if (canChangePosition) {
        return (
          <ListItemIcon>
            <span {...provided.dragHandleProps}>
              <DragHandleIcon fontSize="large" {...provided.dragHandleProps} />
            </span>
          </ListItemIcon>
        )
      } else return null
    }

    if ((type === 'horizontal', handleChangeCheckbox)) {
      return (
        <ListItemStyled alignItems="flex-start">
          <RenderDrag />
          <Responsive
            small={
              <React.Fragment>
                <SmallItemListStyled sm>
                  <ListItemAvatar style={{ maxWidth: '75px', minWidth: '75px', marginRight: '5px' }}>{image}</ListItemAvatar>
                  <ListItemTextStyled>{text}</ListItemTextStyled>
                  <SmallItemListOptionsStyled>
                    <MenuListItem {...this.props} />
                  </SmallItemListOptionsStyled>
                </SmallItemListStyled>
                <RenderCheckbox checked={selected} checkOptions={options} handleChangeCheckbox={handleChangeCheckbox} index={index} />
              </React.Fragment>
            }
            medium={
              <React.Fragment>
                <RenderCheckbox checked={selected} checkOptions={options} handleChangeCheckbox={handleChangeCheckbox} index={index} />
                <ListItemAvatar style={{ width: '110px', minWidth: '110px' }}>{image}</ListItemAvatar>
                <ListItemTextStyled>{text}</ListItemTextStyled>
                <MenuListItem {...this.props} />
              </React.Fragment>
            }
          />
        </ListItemStyled>
      )
    }
    if (this.props.isFetching) {
      return <ItemListFetchingStyled />
    } else {
      return (
        <ItemListStyled selected={selected} onClick={() => this.handleClick()} {...rest}>
          <ImgItemListStyled selected={selected}>{image}</ImgItemListStyled>
          <span>{this.truncateString(text, 75)}</span>
        </ItemListStyled>
      )
    }
  }
}

export default ItemListLayout
