import React from 'react'
import { Datagrid, EditButton, TextField, List } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'

const Actions = (props) => {
  return <ListActions {...props} />
}

export default (props) => (
  <List {...props} actions={<Actions />} bulkActions={false} bulkActionButtons={false} title="Quick access" sort={{ field: 'id', order: 'ASC' }}>
    <Datagrid>
      <TextField source="market" label="Market" />
      <TableRowList>
        <EditButton />
      </TableRowList>
    </Datagrid>
  </List>
)
