import styled from 'styled-components'
import { colors } from '../../../config/theme'
import ListItem from '@material-ui/core/ListItem'
import { ImageField } from '../../field'
import Card from '@material-ui/core/Card'

export const ContainerStyled = styled.div`
  border: solid 1px ${colors.grey[400]};
  padding: 10px;
`

export const LabelStyled = styled.label`
  font-size: 13px;
  margin-bottom: 16px;
  display: block;
`

export const TextareaStyled = styled.div`
  position: relative;
  margin: 4px;
  & input {
    resize: none;
    width: 100%;
    height: 34px;
    padding: 16px;
    border: solid 1px ${colors.grey[500]};
    box-sizing: border-box;
  }
`

export const InputStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 35px;
  align-self: flex-start;
  & input {
    box-sizing: border-box;
    font-size: 13px;
    outline: none;
    border-image-outset: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 9px 16px;
    padding-right: 30px;
    height: 35px;
    background-image: none;
    background-color: ${colors.common.white};
    border: solid 1px ${colors.grey[300]};
    border-radius: 0px;
    box-shadow: none;
  }
  & svg {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    fill: ${colors.grey[300]};
  }
`

export const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
export const ListItemStyled = styled(ListItem)`
  background-color: ${colors.common.white};
  margin-bottom: 5px;
`

export const ImageFieldStyled = styled(ImageField)`
  width: 100%;
  height: 150px;
`
export const CardStyled = styled(Card)`
  background-color: ${colors.common.white} !important;
  border: solid 2px ${(props) => (props.selected ? colors.primary.main : 'transparent')} !important;
  min-height: 100%;
  cursor: pointer;
`
