import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { GET_LIST } from 'react-admin'

import { CheckboxGroupInput } from '../'
import { ConfirmationDialog } from '../../'
import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'
import { EmptyStyled } from './style'

const styles = (theme) => ({
  selector: {
    marginBottom: '10px',
  },
})

class CountriesSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      markets: [],
      selectedCountries: [],
      marketSelected: [],
    }
  }

  componentDidMount() {
    this.getMarkets((markets) => {
      const selectMarkets = _.differenceWith(markets, this.props.marketList, (itemLeft, itemRight) => {
        if (this.props.market) {
          return itemLeft.id === itemRight.market && itemLeft.id !== this.props.market.market
        } else {
          return itemLeft.id === itemRight.market
        }
      })
      if (this.props.market) {
        let countries = []
        if (this.props.market.market === 'ALL') {
          markets.map((item) => {
            if (item.countries) {
              countries = countries.concat(item.countries)
            }
          })
        } else {
          let infoMarket = markets.filter((item) => item.id === this.props.market.market)
          if (infoMarket) {
            countries = infoMarket[0].countries
          }
        }

        this.setState(
          {
            marketCountries: this.prepareCountries(countries),
            marketSelected: this.props.market,
            markets: selectMarkets,
            selectedCountries: this.props.market.countries,
          },
          () => this.prepareChoose()
        )
      } else {
        this.setState(
          {
            markets: selectMarkets,
            selectedCountries: [],
          },
          () => this.handleMarketChange(selectMarkets[0].id)
        )
      }
    })
  }

  prepareMarkets(market, countries, checked) {
    const marketSelected =
      this.state.markets[
        _.findIndex(this.state.markets, (o) => {
          return o.id === market
        })
      ]
    const newCountries = this.prepareCountries(_.orderBy(countries, ['name'], ['asc']))
    this.setState(
      {
        marketCountries: newCountries,
        marketSelected: {
          market: market,
          countries: marketSelected.countries,
        },
        selectedCountries: newCountries,
      },
      () => this.prepareChoose(checked)
    )
  }

  prepareCountries(countries) {
    const newCountries = _.map(countries, (country) => {
      return {
        country: country.id,
        name: country.name,
      }
    })

    return newCountries
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleChange(items, checked) {
    this.setState(
      {
        selectedCountries: items,
      },
      () => this.prepareChoose(checked)
    )
  }

  handleMarketChange = (market) => {
    if (market === 'ALL') {
      this.setState({
        isFetching: true,
      })
      this.getAllCountries((countries) => {
        this.prepareMarkets(market, countries)
        this.setState({
          isFetching: false,
        })
      })
    } else {
      this.setState({
        isFetching: true,
      })
      this.getMarketCountries(market, (countries) => {
        this.prepareMarkets(market, countries)
        this.setState({
          isFetching: false,
        })
      })
    }
  }

  submit() {
    const list = _.map(_.filter(this.state.selectedCountries, { checked: true }), (item) => {
      return item.value
    })
    const market = {
      market: this.state.marketSelected.market,
      countries: list,
    }
    return market
  }

  handleSubmit() {
    this.props.onSubmit(this.submit(), this.state.markets.length <= 1)
  }

  getAllCountries(func) {
    this.getAllCountriesLauncher(0, [], (response) => {
      func(response)
    })
  }

  getAllCountriesLauncher = (index, preCountries, func) => {
    const { markets } = this.state
    if (markets[index].id === 'ALL') {
      if (markets[index + 1]) {
        this.getAllCountriesLauncher(index + 1, preCountries, func)
      } else {
        this.prepareMarkets('ALL', preCountries)
      }
    } else {
      this.getMarketCountries(markets[index].id, (countries) => {
        const newCountries = _.concat(preCountries, countries)
        if (markets[index + 1]) {
          this.getAllCountriesLauncher(index + 1, newCountries, func)
        } else {
          func(newCountries)
        }
      })
    }
  }

  getMarketCountries(market, onChange) {
    dataProvider(GET_LIST, getResource('transversal', 'countries'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {
        perPage: 100,
      },
      filter: {
        market: market,
      },
    }).then((response) => {
      onChange(response.data)
    })
  }

  getMarkets(onChange) {
    dataProvider(GET_LIST, getResource('transversal', 'markets'), {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {},
      filter: {
        group: 'oneMarket',
      },
    }).then((response) => {
      onChange(response.data)
    })
  }

  prepareChoose(checked) {
    const choose = _.map(this.state.marketCountries, (item) => {
      return {
        id: item.country,
        name: item.name,
        checked:
          _.findIndex(this.state.marketSelected.countries, (o) => {
            return o.country === item.country
          }) >= 0,
        value: item,
      }
    })
    this.setState({
      choose: choose,
    })
  }

  render() {
    const { classes } = this.props
    const { isFetching, marketSelected, choose } = this.state
    const disabled = false

    if (!marketSelected) return null
    const markets = _.map(this.state.markets, (item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      )
    })
    return (
      <ConfirmationDialog
        title="Edit markets"
        open={true}
        maxWidth="sm"
        disableSave={!(choose && marketSelected) || isFetching}
        onSuccess={() => this.handleSubmit()}
        onCancel={this.handleClose}
      >
        <React.Fragment>
          {choose && marketSelected && !isFetching && (
            <DialogContentText id="alert-dialog-description">
              <Select className={classes.selector} value={marketSelected.market} onChange={(value) => this.handleMarketChange(value.target.value)}>
                {markets}
              </Select>
              <div>
                <CheckboxGroupInput disabled={disabled} value={choose} onChange={(items) => this.handleChange(items)} />
              </div>
            </DialogContentText>
          )}
          {(!(choose && marketSelected) || isFetching) && (
            <EmptyStyled>
              <CircularProgress />
            </EmptyStyled>
          )}
        </React.Fragment>
      </ConfirmationDialog>
    )
  }
}

export default withStyles(styles)(CountriesSelector)
