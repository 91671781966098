import React from 'react'
import { Create } from 'react-admin'

import BroadcastersForm from './form'

const BroadcasterTitle = ({ record }) => <span>New broadcaster:</span>

export const BroadcastersCreate = (props) => (
  <Create title={<BroadcasterTitle />} {...props}>
    <BroadcastersForm />
  </Create>
)

export default BroadcastersCreate
