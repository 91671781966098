import React, { Component } from 'react'
import { Field } from 'redux-form'
import CategorySelector from './categorySelector'

class CategoryInput extends Component {
  render() {
    const { source, ...rest } = this.props
    if (this.props.source) {
      return <Field {...rest} component={CategorySelector} name={this.props.source} />
    } else {
      return <CategorySelector {...this.props} />
    }
  }
}

export default CategoryInput
