import styled from 'styled-components'

export const TeamContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 10px 10px 0;
  & img {
    width: 33px;
    margin-top: -5px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 5px;
  }
`
