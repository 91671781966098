import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import React, { Component, Fragment } from 'react'
import { RadioButtonGroupInput, ReferenceInput, required } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector } from 'redux-form'

import { AutocompleteInput, MetadataInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

const FORM = 'record-form'
const selector = formValueSelector(FORM)
const styles = (theme) => ({
  radioButtonGroupInput: {
    display: 'flex',
    '& > div': {
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'nowrap',
    },
  },
  rowIndex: {
    padding: '10px',
    alignSelf: 'flex-start',
  },
  rowBroadcastCountries: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rowContent: {
    flex: 'auto',
    padding: '0px 10px',
  },
})

export class BroadcastForm extends Component {
  addRow(source) {
    const { field, change } = this.props
    let items = field(source)
    if (!items) {
      items = []
    }
    items.push({})
    change(FORM, source, items)
  }

  removeRow(source, key) {
    const { field, change } = this.props
    let items = field(source)
    items.splice(key, 1)
    change(FORM, source, items)
  }

  BroadcastCountries = ({ source }) => {
    const { field, classes } = this.props
    const items = field(source)
    return (
      <Fragment>
        {items &&
          items.map((item, key) => (
            <div className={classes.rowBroadcastCountries} key={key}>
              <div className={classes.rowIndex}>{key + 1}</div>
              <div className={classes.rowContent}>
                <ReferenceInput label="Country" source={`${source}[${key}].country`} reference={getResource('transversal', 'countries')} fullWidth>
                  <AutocompleteInput fullWidth />
                </ReferenceInput>
                <RadioButtonGroupInput
                  className={classes.radioButtonGroupInput}
                  source={`${source}[${key}].state`}
                  label="State"
                  choices={[
                    { id: 'approved', name: 'Approved' },
                    { id: 'refused', name: 'Refused' },
                    { id: 'pending', name: 'Pending' },
                  ]}
                />
              </div>
              <Button size="small" onClick={() => this.removeRow(source, key)}>
                <CloseIcon />
                Remove
              </Button>
            </div>
          ))}

        <Button size="small" onClick={() => this.addRow(source)}>
          <AddIcon className={classes.leftIcon} />
          Add
        </Button>
      </Fragment>
    )
  }

  render() {
    const { BroadcastCountries } = this
    return (
      <LanguagesNav {...this.props}>
        <Grid container>
          <Grid item xs={12}>
            <ReferenceInput label="Operador" source="broadcaster.id" validate={[required()]} reference={getResource('operadores', 'broadcasters')}>
              <AutocompleteInput fullWidth inputValueMatcher={() => null} source="name" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <BroadcastCountries source="broadcastCountries" />
          </Grid>
          <Grid item xs={12}>
            <MetadataInput
              source="metadatas"
              label="Metadata"
              sources={{
                match: 'match',
              }}
            />
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(BroadcastForm)
