import React, { Component } from 'react'
import { Field } from 'redux-form'
import LinkInputComponent from './LinkInput'

class LinkInput extends Component {
  render() {
    if (this.props.source) {
      return <Field {...this.props} label={this.props.label} component={LinkInputComponent} name={this.props.source} />
    } else {
      return <LinkInputComponent {...this.props} />
    }
  }
}

export default LinkInput
