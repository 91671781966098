import React from 'react'
import { Create } from 'react-admin'

import CountriesForm from './form'

export const CountriesCreate = (props) => (
  <Create {...props}>
    <CountriesForm {...props} />
  </Create>
)

export default CountriesCreate
