import DialogTitle from '@material-ui/core/DialogTitle'
import _ from 'lodash'
import React from 'react'
import { CREATE } from 'react-admin'
import Grid from '@material-ui/core/Grid'

import { SubForm } from '../../../'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import ImagesForm from '../../../../resources/assets/images/form/form'
import ItemListLayout from '../itemListLayout'
import { ContentListStyled, ImageFieldStyled, FiltersStyled } from '../style'

const FORM = 'asset-form'

class ImagesFormModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      files: [],
    }
  }

  componentDidMount() {
    const { file } = this.props
    this.setState({
      files: file,
    })
  }

  handleSubmit(data) {
    this.props.onSubmit(data)
  }

  setImage(MyAsset, myData) {
    const { asset, ...rest } = myData
    const data = {
      asset: { ...MyAsset },
      name: MyAsset.filename,
      ...rest,
    }
    return dataProvider(CREATE, getResource('assets', 'images'), {
      data,
    })
      .catch((error) => {
        this.setState({})
      })
      .finally(() => {
        this.setState({
          fetching: false,
        })
      })
  }

  saveImages(images, data) {
    this.setState({
      isFormFetching: true,
    })
    _.map(images, (image) => {
      this.setImage(image, data)
        .then((data) => {
          this.props.onClose()
        })
        .finally(() => {
          this.setState({
            isFormFetching: false,
          })
        })
    })
  }

  handleClick(selectedFile) {
    let selectedFiles = [...this.state.files]
    const index = _.findIndex(selectedFiles, { id: selectedFile.id })
    if (index > -1) {
      selectedFiles.splice(index, 1)
    } else {
      selectedFiles.push(selectedFile)
    }
    this.setState({
      files: selectedFiles,
    })
  }

  render() {
    const { file, classes } = this.props
    return (
      <div>
        {file.length > 1 && (
          <SubForm
            submitOnEnter={false}
            isFetching={this.state.isFormFetching}
            onClose={() => this.props.onClose()}
            record={{ asset: file }}
            form={FORM}
            onClickSave={(data) => this.saveImages(this.state.files, data)}
          >
            <Grid container>
              <Grid item xs={12}>
                <ContentListStyled style={{ width: '100%' }}>
                  {_.map(file, (myfile) => {
                    return (
                      <ItemListLayout
                        selected={_.find(this.state.files, { id: myfile.id })}
                        key={myfile.id}
                        image={<ImageFieldStyled mini record={myfile} source="fileDir" />}
                        text={myfile.filename}
                        onClick={() => this.handleClick(myfile)}
                      />
                    )
                  })}
                </ContentListStyled>
              </Grid>
            </Grid>
          </SubForm>
        )}
        {file.length === 1 && (
          <SubForm
            submitOnEnter={false}
            record={{ asset: file[0], name: file[0].filename }}
            resource={getResource('assets', 'images')}
            onClose={() => this.props.onClose()}
            form={FORM}
            onSubmit={(data) => this.handleSubmit(data)}
          >
            <ImagesForm />
          </SubForm>
        )}
      </div>
    )
  }
}

export default ImagesFormModal
