import 'typeface-roboto'
import 'typeface-roboto'

import { createHashHistory } from 'history'
import _ from 'lodash'
import React from 'react'
import { Admin, Resource } from 'react-admin'
import { ThemeProvider } from 'styled-components'

import admin from '../config/admin'
import resources from '../config/resources'
import { theme as styledTheme } from '../config/styled'
import theme from '../config/theme'
import LoginPage from '../login'
import LogoutButton from '../logout'
import authProvider from '../providers/authProvider'
import adminReducer from '../reducers/admin'
import sagaValidate from '../sagas'
import appLayout from './layout'
import { ContainerStyled } from './style'

export const history = createHashHistory()

//const MARKETS = ['es', 'en']

const App = () => (
  <ThemeProvider theme={styledTheme}>
    <ContainerStyled>
      <Admin
        history={history}
        customReducers={{ adminCustom: adminReducer }}
        customSagas={[sagaValidate]}
        theme={theme}
        appLayout={appLayout}
        authProvider={authProvider}
        logoutButton={LogoutButton}
        loginPage={LoginPage}
        {...admin}
      >
        {(permissions) => {
          if (process.env.REACT_APP_APP_ENV === 'pre' || process.env.REACT_APP_APP_ENV === 'des') {
            permissions = permissions?.map((permission) => permission.replace('_pre_', '_'))
          }

          return Object.keys(resources).map((item) => {
            const resourceProps = resources[item].resource
            const resourceName = item
            const resourcePermissions = resources[item].permissions

            const allowedPermissions = _.intersection(resourcePermissions, permissions)
            if (allowedPermissions.length > 0) {
              return <Resource name={resourceName} {...resourceProps} key={resourceName} />
            } else {
              return <Resource name={resourceName} key={resourceName} />
            }
          })
        }}
      </Admin>
    </ContainerStyled>
  </ThemeProvider>
)

export default App
