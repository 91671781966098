import React, { Component } from 'react'
import _ from 'lodash'
import { CheckboxFormStyled, ListStyled, SelectAllStyled } from './style'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  label: {
    margin: '0px',
  },
})

class CheckboxGroup extends Component {
  handleChange = (index) => (event) => {
    let list = _.cloneDeep(this.props.choose)
    list[index].checked = event.target.checked
    if (this.props.onChange) {
      this.props.onChange(list)
    }
  }

  setAllCheckbox() {
    const { choose } = this.props
    const value = _.find(choose, { checked: false }) ? true : false
    const selectedCountriesCopy = { ...choose }
    const selectedCountriesNew = _.map(selectedCountriesCopy, (item) => {
      let newItem = { ...item }
      newItem.checked = value
      return newItem
    })
    if (this.props.onChange) this.props.onChange(selectedCountriesNew)
  }

  render() {
    const { choose, classes, disabled } = this.props

    return (
      <CheckboxFormStyled>
        <SelectAllStyled>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                disabled={disabled}
                color="primary"
                checked={!_.find(choose, { checked: false })}
                onChange={(event) => this.setAllCheckbox(!event.target.value)}
              />
            }
            label="Select all"
          />
        </SelectAllStyled>
        <ListStyled>
          <FormGroup>
            {_.map(choose, (item, index) => (
              <FormControlLabel
                className={classes.label}
                key={item.id}
                control={<Checkbox disabled={disabled} color="primary" checked={item.checked} onChange={this.handleChange(index)} value={item.id} />}
                label={item.name}
              />
            ))}
          </FormGroup>
        </ListStyled>
      </CheckboxFormStyled>
    )
  }
}

export default withStyles(styles)(CheckboxGroup)
