import { crudDelete, startUndoable } from 'ra-core'
import React, { Component } from 'react'
import { SaveButton, Toolbar } from 'react-admin'
import DeleteButton from './deleteButton'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { GrowStyled } from './styles'

class PostCreateToolbar extends Component {
  handleOnClick() {
    const { dispatchCrudDelete, record, basePath, redirect, resource } = this.props
    dispatchCrudDelete(resource, record.id, record, basePath, redirect)
  }

  render() {
    const { reset, save, initialize, startUndoable, remove, AdditionalToolbar, ...props } = this.props
    return (
      <Toolbar {...props}>
        <SaveButton />
        {AdditionalToolbar && this.props.AdditionalToolbar}
        <GrowStyled />
        {props.isEdit && remove && <DeleteButton {...props} />}
      </Toolbar>
    )
  }
}

export default compose(connect(null, { startUndoable, dispatchCrudDelete: crudDelete }))(PostCreateToolbar)
