import React from 'react'
import { Create } from 'react-admin'

import EventForm from './form'

export const EventCreate = (props) => (
  <Create {...props}>
    <EventForm {...props} />
  </Create>
)

export default EventCreate
