import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'

import { loginRequest, msalConfig } from './authConfig'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
export const ua = window.navigator.userAgent
export const msie = ua.indexOf('MSIE ')
export const msie11 = ua.indexOf('Trident/')
export const msedge = ua.indexOf('Edge/')
export const isIE = msie > 0 || msie11 > 0
export const isEdge = msedge > 0

export let signInType
export let accountId = ''

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
export const myMSALObj = new PublicClientApplication(msalConfig)

// Redirect: once login is successful and redirects with tokens, call Graph API
myMSALObj
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((err) => {
    console.error(err)
  })

export function handleResponse(resp) {
  if (resp !== null) {
    accountId = resp.account.homeAccountId
    myMSALObj.setActiveAccount(resp.account)
    localStorage.setItem('token', resp.accessToken)
    window.location.reload()
  } else {
    // need to call getAccount here?
    const currentAccounts = myMSALObj.getAllAccounts()
    if (!currentAccounts || currentAccounts.length < 1) {
      return
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
    } else if (currentAccounts.length === 1) {
      const activeAccount = currentAccounts[0]
      myMSALObj.setActiveAccount(activeAccount)
      accountId = activeAccount.homeAccountId
    }
  }
}

export async function signIn(method = 'redirect') {
  signInType = isIE ? 'redirect' : method
  if (signInType === 'popup') {
    return myMSALObj
      .loginPopup(loginRequest)
      .then(handleResponse)
      .catch(function (error) {
        console.error(error)
      })
  } else if (signInType === 'redirect') {
    return myMSALObj.loginRedirect(loginRequest)
  }
}

export function signOut(interactionType = 'redirect') {
  const logoutRequest = {
    account: myMSALObj.getAccountByHomeId(accountId),
  }

  if (interactionType === 'popup') {
    myMSALObj.logoutPopup(logoutRequest).then(() => {
      window.location.reload()
    })
  } else {
    myMSALObj.logoutRedirect(logoutRequest)
  }
}

export async function getTokenPopup(request) {
  return await myMSALObj.acquireTokenSilent(request).catch(async (error) => {
    console.log('silent token acquisition fails.') // eslint-disable-line
    if (error instanceof InteractionRequiredAuthError) {
      console.log('acquiring token using popup') // eslint-disable-line
      return myMSALObj.acquireTokenPopup(request).catch((error) => {
        console.error(error)
      })
    } else {
      console.error(error)
    }
  })
}

// This function can be removed if you do not need to support IE
export async function getTokenRedirect(request) {
  return await myMSALObj.acquireTokenSilent(request).catch(async (error) => {
    console.log('silent token acquisition fails.') // eslint-disable-line
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      console.log('acquiring token using redirect') // eslint-disable-line
      myMSALObj.acquireTokenRedirect(request)
    } else {
      console.error(error)
    }
  })
}
