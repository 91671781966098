import styled from 'styled-components'

export const FormStyled = styled.div`
  & form {
    & > div:last-child {
      padding-bottom: 0px;
      & > div {
        justify-content: end;
      }
    }
  }
`
