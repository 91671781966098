import AppBar from '@material-ui/core/AppBar'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { GET_ONE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { getResource } from '../../../../../config/resources'
import { dataProvider } from '../../../../../providers'
import Competition from './Competition'
import Subscriptions from './Subscriptions'

const styles = (theme) => {
  return {
    body: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    header: {
      flex: '0 0',
      fontSize: '1.5em',
      marginBottom: '10px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
    },
    main: {
      flex: 'auto',
      overflow: 'auto',
    },
    footer: {
      flex: '0 0 ',
      paddingTop: '10px',
    },
    tabs: {
      background: theme.palette.background.paper,
      boxShadow: 'none',
    },
  }
}

class MainManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSeasson: null,
      tabActive: 0,
    }
  }

  componentDidMount() {
    const { idSeasonOpen, idCompetition, idSubscriptionOpen } = this.props

    this.setState({
      idCompetition: idCompetition,
      idSeasonOpen: idSeasonOpen,
      idSubscriptionOpen: idSubscriptionOpen,
    })
    if (idSeasonOpen !== null) {
      this.getDataSeason(idSeasonOpen)
    }
  }

  getDataSeason(idSeason) {
    dataProvider(GET_ONE, getResource('competiciones', 'seasons'), {
      id: idSeason,
    }).then((response) => {
      this.setState({ dataSeasson: response.data })
    })
  }

  handleChangeTab = (event, value) => {
    this.setState({ tabActive: value })
  }

  render() {
    const { classes, idCompetition, idSeasonOpen, idSubscriptionOpen, isLoading } = this.props

    return (
      <div className={classes.body}>
        <div className={classes.header}>
          <Typography variant="h2" headlineMapping="h2">
            {this.state.competitionName ? this.statecompetitionName : ''}
          </Typography>
        </div>
        <AppBar className={classes.tabs} position="static" color="default">
          <Tabs value={this.state.tabActive} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" fullWidth>
            <Tab label="Subscriptions" />
            <Tab label="Competition" />
          </Tabs>
        </AppBar>
        {isLoading && <LinearProgress color="primary" />}
        {this.state.tabActive === 0 && (
          <div className={classes.main}>
            <Subscriptions
              idCompetition={idCompetition}
              idSeasonOpen={idSeasonOpen}
              idSubscriptionOpen={idSubscriptionOpen}
              handleClose={this.props.handleClose}
            />
          </div>
        )}
        {this.state.tabActive === 1 && (
          <div className={classes.main}>
            <Competition
              idCompetition={idCompetition}
              idSeasonOpen={idSeasonOpen}
              idSubscriptionOpen={idSubscriptionOpen}
              handleClose={this.props.handleClose}
            />
          </div>
        )}
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isLoading: state.admin.loading === 1 ? true : false,
    }),
    (dispatch) => ({})
  ),
  withStyles(styles)
)

export default enhance(MainManagement)
