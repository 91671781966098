import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { GET_ONE } from 'react-admin'

import { getResource } from '../../../config/resources'
import { dataProvider } from '../../../providers'

import { ImageFieldStyled, CardStyled } from './style'

const styles = {
  card: {
    backgroundColor: 'white',
  },
  media: {
    objectFit: 'cover',
  },
}

class NewCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      asset: null,
    }
  }

  componentDidMount() {
    this.getAsset(this.props.asset, (asset) => {
      this.setState({
        asset: asset,
      })
    })
  }

  getAsset(asset, onChange) {
    dataProvider(GET_ONE, getResource('assets', 'images'), {
      id: asset,
    }).then((response) => {
      onChange(response.data)
    })
  }

  render() {
    const { title, shortDescription, selected, classes } = this.props
    const { asset } = this.state
    return (
      <CardStyled selected={selected} onClick={() => this.props.onClick()}>
        <ImageFieldStyled record={asset} source="asset.fileDir" sizes="sizes" mini height="150px" />
        <CardContent>
          <h3>{title}</h3>
          <Typography component="p">{shortDescription}</Typography>
        </CardContent>
        <CardActions></CardActions>
      </CardStyled>
    )
  }
}

export default withStyles(styles)(NewCard)
