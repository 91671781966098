import React from 'react'
import { BooleanField, Datagrid, EditButton, List, ReferenceField, Responsive, SimpleList, TextField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const Actions = (props) => {
  return <ListActions {...props} />
}

const MobileAppsList = (props) => {
  return (
    <List bulkActions={false} title="Competitions" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            leftAvatar={(record) => (
              <ReferenceField
                source="image"
                reference={getResource('assets', 'images')}
                basePath={getResource('assets', 'images')}
                linkType={false}
                record={record}
                allowEmpty
              >
                <ImageField mini source="asset.fileDir" title="Image app" />
              </ReferenceField>
            )}
            primaryText={(record) => record.name}
          />
        }
        medium={
          <Datagrid>
            <ReferenceField label="Image" source="image" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
              <ImageField source="asset.fileDir" title="Image aoo" />
            </ReferenceField>
            <TextField source="name" label="App name" />
            <BooleanField source="active" label="Active" />
            <TextField source="positionApp" label="Position App" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default MobileAppsList
