import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'
import ClearIcon from '@material-ui/icons/Clear'
import OrderIcon from '@material-ui/icons/SwapVerticalCircle'
import _ from 'lodash'
import { CREATE } from 'ra-core/lib/dataFetchActions'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, GET_LIST, showNotification, UPDATE } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { getResource } from '../../../../../../config/resources'
import dataProvider from '../../../../../../providers'
import { ListItemsStyled, RowItemStyled } from '../styles'
import Group from './Group'

const styles = (theme) => {
  return {
    border: {
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      position: 'relative',
      minHeight: '150px',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.1)',
      top: '0px',
      left: '0px',
      zIndex: 100,
    },
    itemDefault: {
      width: '100%',
      minHeight: '200px',
    },

    cellButton: {
      textAlign: 'right',
      paddingRight: '12px !important',
      width: '75px;',
    },
    orderIcon: {
      fontSize: '23px',
      opacity: 0.6,
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        color: theme.palette.primary.dark,
      },
    },
    cellIcon: {
      fontSize: '30px',
      opacity: 0.6,
      color: theme.palette.grey[600],
      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '2px',
      right: '2px',
      height: '38px',
      width: '38px',
      '& .icon': {
        fontSize: '20px',
      },
    },
  }
}

class Groups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      groups: null,
      groupSelected: null,
      groupOpen: null,
      addGroup: '',
      addLetter: '',
    }
  }

  componentDidMount() {
    this.listGroups()
  }

  listGroups() {
    const { idRound } = this.props

    dataProvider(GET_LIST, getResource('competiciones', 'aggregates'), {
      sort: {
        field: 'position',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: {
        round: idRound,
      },
    }).then((response) => {
      this.setState({
        loading: false,
        groups: response.data,
      })
    })
  }

  handleClickCloseGroup() {
    this.setState({
      loading: true,
      groupOpen: null,
    })
    this.listGroups()
  }

  handleClickEditGroup(idGroup) {
    this.setState({
      groupOpen: idGroup,
    })
  }

  handleSelectGroup(idGroup) {
    if (this.state.groupSelected === idGroup) {
      this.setState({
        groupSelected: null,
      })
    } else {
      this.setState({
        groupSelected: idGroup,
      })
    }
  }

  moveRound(direction) {
    if (this.state.groupSelected) {
      let groups = this.state.groups
      let index = _.findIndex(groups, { id: this.state.groupSelected })
      let new_groups = false

      if (direction === 'up' && index > 0) {
        new_groups = this.reorder(groups, index, parseInt(index) - 1)
      }
      if (direction === 'down' && index < groups.length) {
        new_groups = this.reorder(groups, index, parseInt(index) + 1)
      }

      if (new_groups !== false) {
        this.setState({
          groups: new_groups,
        })

        new_groups.map((item, key) => {
          let groups = item
          groups.position = key
          dataProvider(UPDATE, getResource('competiciones', 'aggregates'), {
            id: item.id,
            data: groups,
          }).then((response) => {})
        })
      }
    }
  }

  reorder(list, source, destination) {
    const result = Array.from(list)
    const [removed] = result.splice(source, 1)
    result.splice(destination, 0, removed)

    return result
  }

  handleChangeInput = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleClickAddGroup() {
    const { fetchEnd, fetchStart, showNotification } = this.props
    if (this.state.addGroup !== '' && this.state.addLetter !== '') {
      fetchStart()
      dataProvider(CREATE, getResource('competiciones', 'aggregates'), {
        data: {
          name: this.state.addGroup,
          letter: this.state.addLetter,
          round: {
            id: this.props.idRound,
          },
        },
      })
        .then((response) => {
          this.setState({
            loading: true,
            addGroup: '',
            addLetter: '',
          })
          this.listGroups()
          showNotification('Group created', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container className={classes.border}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h2" headlineMapping="h2">
            Groups
          </Typography>
          {this.state.groupOpen !== null && (
            <IconButton onClick={() => this.handleClickCloseGroup()} className={classes.closeButton} color="primary" size="small">
              <ClearIcon className="icon" />
            </IconButton>
          )}
        </Grid>

        <Grid item xs={12}>
          {this.state.groupOpen === null && (
            <ListItemsStyled>
              <RowItemStyled className="sticky">
                <div className="order">
                  <UpIcon className={classes.orderIcon} onClick={() => this.moveRound('up')} />
                  <DownIcon className={classes.orderIcon} onClick={() => this.moveRound('down')} />
                </div>
                <div className="head textfield">
                  <TextField
                    id="name"
                    label="Name *"
                    margin="dense"
                    fullWidth={true}
                    value={this.state.addGroup}
                    onChange={this.handleChangeInput('addGroup')}
                  />
                </div>
                <div className="head textfield tam-xs">
                  <TextField
                    id="letter"
                    label="Letter *"
                    margin="dense"
                    fullWidth={true}
                    value={this.state.addLetter}
                    onChange={this.handleChangeInput('addLetter')}
                  />
                </div>
                <div className={classes.cellButton}>
                  <Button
                    onClick={() => this.handleClickAddGroup()}
                    disabled={this.state.addGroup === '' || this.state.addLetter === ''}
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="Add"
                  >
                    Add
                  </Button>
                </div>
              </RowItemStyled>
              {this.state.groups &&
                this.state.groups.map((item, key) => (
                  <RowItemStyled key={key} className={item.id === this.state.groupSelected ? 'selected' : ''}>
                    <div className="order">
                      <OrderIcon className={classes.cellIcon} onClick={() => this.handleSelectGroup(item.id)} />
                    </div>
                    <div className="full">
                      <Typography variant="body1">{item.name}</Typography>
                    </div>
                    <div className="tam-xs">
                      <Typography variant="body1">{item.letter}</Typography>
                    </div>
                    <div className={classes.cellButton}>
                      <Button onClick={() => this.handleClickEditGroup(item.id)} variant="contained" color="primary" size="small" aria-label="Edit">
                        Edit
                      </Button>
                    </div>
                  </RowItemStyled>
                ))}
            </ListItemsStyled>
          )}
          {this.state.groupOpen !== null && (
            <Group
              data={this.state.groups[_.findIndex(this.state.groups, ['id', this.state.groupOpen])]}
              idSubscription={this.props.idSubscription}
              closeGroup={() => this.handleClickCloseGroup()}
            />
          )}
        </Grid>

        {this.state.loading && (
          <div className={classes.loading}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
      </Grid>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)

export default enhance(Groups)
