import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const styles = () => ({
  addIcon: {
    position: 'absolute',
    right: 0,
    top: -8,
    width: 32,
    height: 32,
  },
  icon: {
    width: 32,
    height: 32,
    marginTop: 12,
  },
})

const TextArray = (props) => {
  const { onChange, value } = props.input ? props.input : props
  const { classes } = props

  const handleOnChange = (index, data) => {
    const newValue = [...value]
    newValue[index] = data
    onChange(newValue)
  }

  const handleRemoveLine = (index) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  const handleAdd = () => {
    const newValue = value ? [...value] : []
    newValue.push('')
    onChange(newValue)
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor={props.source}>{props.label}</InputLabel>
      <IconButton className={classes.addIcon} onClick={handleAdd} aria-label="Add" color="primary">
        <AddIcon />
      </IconButton>
      {value &&
        value.map((eachValue, key) => (
          <TextField
            value={eachValue}
            onChange={(e) => handleOnChange(key, e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Remove line" onClick={() => handleRemoveLine(key)} className={classes.icon}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            key={key}
          />
        ))}
    </FormControl>
  )
}

export default withStyles(styles)(TextArray)
