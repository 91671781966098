import React from 'react'
import { Edit } from 'react-admin'
import GameweekForm from './form'

export const GameweekEdit = (props) => (
  <Edit undoable={false} {...props}>
    <GameweekForm isEdit={true} {...props} />
  </Edit>
)

export default GameweekEdit
