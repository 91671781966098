import React from 'react'
import { Create } from 'react-admin'
import GameweekForm from './form'

export const GameweekCreate = (props) => (
  <Create {...props}>
    <GameweekForm isEdit={false} {...props} />
  </Create>
)

export default GameweekCreate
