import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ArrowDropDown'
import ExpandMore from '@material-ui/icons/ArrowDropUp'
import DefaultIcon from '@material-ui/icons/ViewList'
import classnames from 'classnames'
import inflection from 'inflection'
import _ from 'lodash'
import React from 'react'

import Icons from '../../../components/icons'
import resourcesConfig from '../../../config/resources'
import MenuItemLink from './MenuItemLink'

const styles = (theme) => ({
  container: {
    position: 'sticky',
    top: '0px',
    maxHeight: '100vh',
    overflowY: 'visible',
  },
  containerOpen: {
    overflowY: 'auto',
  },
  submenu: {
    backgroundColor: theme.palette.grey[100],
    padding: '0px',
  },
  submenuMini: {
    position: 'absolute',
    left: 'calc(100%)',
    top: '0px',
    zIndex: '99',
    boxShadow: `2px 2px 2px ${theme.palette.grey[400]}`,
  },
  submenuText: {
    color: theme.palette.primary.contrastText,
    paddingLeft: '10px',
    fontSize: '13px',
    marginBottom: '0px',
    '& > span': {
      color: theme.palette.primary.contrastText,
      fontSize: '13px',
    },
  },
  miniHeaderList: {
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: '48px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  item: {
    position: 'relative',
  },
  list: {
    padding: '0px',
  },
  listItem: {
    backgroundColor: 'transparent',
    marginBottom: '0px',
  },
  textPrimary: {
    fontSize: '14px',
  },
  textPrimaryActive: {
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
  },
  listItemActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginBottom: '0px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
})

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuState: [],
    }
  }

  componentDidMount() {
    const resources = this.props.resources
    let links = []
    resources
      .filter((r) => r.hasList)
      .map((resource) => {
        if (resourcesConfig[resource.name].group) {
          const group = resourcesConfig[resource.name].group
          if (!links[group.label])
            links[group.label] = {
              open: false,
              icon: group.icon,
              name: group.label,
              resources: [],
            }
          links[group.label].resources.push(resource)
        } else {
          links.push(resource)
        }
        return null
      })

    this.setState({
      menuState: links,
    })
  }

  translatedResourceName(resource, translate) {
    return translate(`resources.${resource.name}.name`, {
      smart_count: 2,
      _:
        resource.options && resource.options.label
          ? translate(resource.options.label, {
              smart_count: 2,
              _: resource.options.label,
            })
          : inflection.humanize(inflection.pluralize(resource.name)),
    })
  }

  GetLink(link, dense, onMenuClick, open) {
    return (
      <MenuItemLink
        key={link.name}
        to={`/${link.name}`}
        primaryText={open ? this.translatedResourceName(link, this.props.translate) : ''}
        leftIcon={link.icon ? <link.icon /> : <DefaultIcon />}
        onClick={onMenuClick}
        dense={dense}
      />
    )
  }

  handleMenu(item, state) {
    if (this.state.menuState[item]) {
      const menuState = { ...this.state.menuState }
      _.map(menuState, (menuItem) => {
        menuItem.open = false
      })
      menuState[item].open = state

      this.setState({
        menuState: menuState,
      })
    }
  }
  onMenuClick(item, state) {
    this.handleMenu(item, state)
  }

  getSubmenu(submenu) {
    const items = submenu.resources.map((item) => {
      if (resourcesConfig[item.name].visible) {
        return (
          <MenuItemLink
            key={item.name}
            to={`/${item.name}`}
            primaryText={this.translatedResourceName(item, this.props.translate)}
            className={this.props.classes.submenuText}
            onClick={this.props.onMenuClick}
            dense={this.props.dense}
          />
        )
      }
      return null
    })
    return (
      <Collapse in={submenu.open} timeout="auto" unmountOnExit>
        <List component="div" className={classnames(this.props.classes.submenu, !this.props.open ? this.props.classes.submenuMini : '')}>
          {!this.props.open && (
            <ListItem primary={submenu.name} button className={this.props.classes.miniHeaderList}>
              {submenu.name}
            </ListItem>
          )}
          {items}
        </List>
      </Collapse>
    )
  }
  GetMenu() {
    const links = this.state.menuState
    const myLinks = Object.keys(links).map((item) => {
      if (this.state.menuState[item].resources) {
        return (
          <div key={item} className={this.props.classes.item} onMouseLeave={() => (!this.props.open ? this.onMenuClick(item, false) : null)}>
            <ListItem
              button
              className={this.state.menuState[item].open ? this.props.classes.listItemActive : this.props.classes.listItem}
              onClick={() => this.onMenuClick(item, !this.state.menuState[item].open)}
            >
              <ListItemIcon>
                <Icons size="16px" icon={links[item] ? links[item].icon : ''} />
              </ListItemIcon>
              {this.props.open && (
                <ListItemText
                  classes={{ primary: this.state.menuState[item].open ? this.props.classes.textPrimaryActive : this.props.classes.textPrimary }}
                  inset
                  primary={item}
                />
              )}
              {this.state.menuState[item].open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {this.getSubmenu(this.state.menuState[item])}
          </div>
        )
      }
      const myItem = this.state.menuState[item]
      return (
        <MenuItemLink
          className={this.props.open ? this.translatedResourceName(myItem, this.props.translate) : ''}
          key={myItem.name}
          to={`/${myItem.name}`}
          primaryText={this.props.open ? this.translatedResourceName(myItem, this.props.translate) : ''}
          leftIcon={myItem.icon ? <myItem.icon /> : <DefaultIcon />}
          onClick={this.props.onMenuClick}
          dense={this.props.dense}
        />
      )
    })
    return <List className={this.props.classes.list}>{myLinks}</List>
  }

  render() {
    return <div className={classnames(this.props.classes.container, this.props.open ? this.props.classes.containerOpen : '')}>{this.GetMenu()}</div>
  }
}

export default withStyles(styles)(Menu)
