import Grid from '@material-ui/core/Grid'
import React from 'react'
import { maxLength, ReferenceInput, required, SelectInput, TextInput } from 'react-admin'

import { AutocompleteInput, DateSingleInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'

export const ProceduresForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12} sm={4}>
        <DateSingleInput source="date" label="Date" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="procedure.id" label="Procedure" reference={getResource('competiciones', 'procedures')} validate={[required()]} fullWidth>
          <SelectInput />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput
          source="state"
          label="State"
          choices={[
            { id: 'validado CMP', name: 'Validado' },
            { id: 'validado provisional CMP', name: 'Validado provisional' },
          ]}
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Player" source="name" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput source="nationalityBirth" label="Nationality" reference={getResource('transversal', 'nationalities')} validate={[required()]}>
          <AutocompleteInput optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Team From" source="teamFromString" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Team To" source="teamToString" validate={[required(), maxLength(255)]} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput source="competition.id" label="Competition" reference={getResource('competiciones', 'competitions')} validate={[required()]}>
          <AutocompleteInput optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default ProceduresForm
