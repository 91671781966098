import React from 'react'
import { OptionBarContainerStyled } from './style'

class OptionBar extends React.Component {
  state = {
    anchorEl: null,
  }

  render() {
    const { children } = this.props
    return <OptionBarContainerStyled>{children}</OptionBarContainerStyled>
  }
}

export default OptionBar
