import React from 'react'
import { Create } from 'react-admin'

import GalleryForm from './form'

const BroadcasterTitle = ({ record }) => <span>Nueva difusión:</span>

export const BroadcastCreate = (props) => (
  <Create title={<BroadcasterTitle />} {...props}>
    <GalleryForm isEdit={false} {...props} />
  </Create>
)

export default BroadcastCreate
