import React from 'react'
import { Edit } from 'react-admin'

import AttemptsForm from './form'

export const AttemptsEdit = (props) => (
  <Edit undoable={false} {...props}>
    <AttemptsForm {...props} />
  </Edit>
)

export default AttemptsEdit
