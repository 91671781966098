import { Grid } from '@material-ui/core'
import React from 'react'
import { FormDataConsumer, ReferenceInput, required, SelectInput } from 'react-admin'

import { RichTextInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import SelectTeam from './SelectTeam'

export const TeamPromiseForm = ({ ...props }) => (
  <LanguagesNav {...props}>
    <Grid container>
      <Grid item xs={12}>
        <ReferenceInput label="Promise" source="promise.id" reference={getResource('layouts', 'promises')} validate={[required()]} perPage={100}>
          <SelectInput fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <FormDataConsumer>
          {({ formData }) => {
            return <SelectTeam label="Team" source="team" promise={formData?.promise} disabled={!formData?.promise?.id} />
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12}>
        <RichTextInput fullWidth source="description" label="Description" />
      </Grid>
    </Grid>
  </LanguagesNav>
)

export default TeamPromiseForm
