import MenuIcon from '@material-ui/icons/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React from 'react'

import ListItem from './listItem'
import { BackStyled, ListContainerStyled, ListStyled } from './style'

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  getList() {
    const list = React.Children.map(this.props.children, (child) => {
      return <ListItem>{React.cloneElement(child, { ...this.props })}</ListItem>
    })
    return <ListStyled open={this.state.open}>{list}</ListStyled>
  }

  render() {
    return (
      <React.Fragment>
        <ListContainerStyled
          open={this.state.open}
          onClick={() => {
            if (!this.state.open) this.setState({ open: true })
          }}
        >
          <div>
            <MoreVertIcon color="inherit" aria-label="open drawer" onClick={() => this.setState({ open: !this.state.open })} className="menu-icon">
              <MenuIcon />
            </MoreVertIcon>
            {this.state.open && this.getList()}
          </div>
        </ListContainerStyled>
        {this.state.open && <BackStyled onClick={() => this.setState({ open: false })} />}
      </React.Fragment>
    )
  }
}

export default List
