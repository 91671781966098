import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import styled from 'styled-components'

import AppBar from './appBar'
import Layout from './layout'
import Menu from './menu'

//import MyMenu from './MyMenu';
//import MyNotification from './MyNotification';

const ContainerStyled = styled.div`
  & > div {
    padding-top: ${(props) => props.height};
    transition: padding-top 0.3s;
  }
`
const styles = (theme) => {
  return {
    layout: {
      backgroundColor: theme.palette.grey[200],
    },
  }
}

const MyLayout = (props) => {
  const height = props.subheader ? '50px' : '0px'
  return (
    <ContainerStyled height={height}>
      <Layout {...props} className={props.classes.layout} appBar={AppBar} menu={Menu} />
    </ContainerStyled>
  )
}

export default withStyles(styles)(MyLayout)
