import React from 'react'
import { List } from 'react-admin'
import { ListActions } from '../../../components'

import MyList from '../list'

const Actions = (props) => {
  return <ListActions {...props} />
}

export const SubhomePrimeraDivisionFemenina = (props) => (
  <List
    {...props}
    actions={<Actions />}
    bulkActions={false}
    bulkActionButtons={false}
    filter={{ section: 'primera-division-femenina' }}
    title="Subhome Primera división"
  >
    <MyList />
  </List>
)

export default SubhomePrimeraDivisionFemenina
