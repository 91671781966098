import React, { Component } from 'react'
import { Field } from 'redux-form'
import CheckboxGroup from './checkboxGroup'

class CheckboxGroupInput extends Component {
  render() {
    if (this.props.source) {
      return <Field label={this.props.label} component={CheckboxGroup} name={this.props.source} {...this.props} />
    } else {
      return <CheckboxGroup {...this.props} />
    }
  }
}

export default CheckboxGroupInput
