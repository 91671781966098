import React from 'react'
import { ItemListStyled } from './style'

class ListItem extends React.Component {
  render() {
    return <ItemListStyled>{this.props.children}</ItemListStyled>
  }
}

export default ListItem
