import { LogLevel } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message) // eslint-disable-line
            return
          case LogLevel.Info:
            console.info(message) // eslint-disable-line
            return
          case LogLevel.Verbose:
            console.debug(message) // eslint-disable-line
            return
          case LogLevel.Warning:
            console.warn(message) // eslint-disable-line
            return
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphGroupsEndpoint: 'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group',
  graphMemberOfEndpoint: 'https://graph.microsoft.com/v1.0/me/memberOf',
}

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: [],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
}

export const silentRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
}

export const logoutRequest = {}
