import styled from 'styled-components'
import theme from '../../../config/theme'

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`

export const ItemStyled = styled.div`
  flex: 45%;
`

export const SpaceStyled = styled.div`
  flex: 0 1 25px;
`

export const LabelStyled = styled.div`
  width: 100;
  font-family: ${theme.typography.fontFamily};
  margin: 10px 0;
  font-size: 0.9rem;
`
