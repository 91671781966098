import { Grid, InputLabel, MenuItem, FormControl, Select, NativeSelect } from '@material-ui/core'
import _ from 'lodash'
import React, { Component } from 'react'
import { FormDataConsumer, RadioButtonGroupInput, ReferenceInput, required, SelectInput, TextInput } from 'react-admin'

import { AddAssetInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'

class AssetForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      group: null,
    }
  }

  GroupSelector = ({ ...props }) => {
    const { input, label, choices, source, disabled, ...rest } = props
    let options = {}
    if (choices) {
      choices.map((item) => {
        if (options[item.section]) {
          options[item.section].items.push(item)
        } else {
          options[item.section] = {
            label: item.section,
            items: [item],
          }
        }
      })
    }

    return (
      <FormControl fullWidth>
        <InputLabel htmlFor={`selector-${source}`}>{label}</InputLabel>
        <NativeSelect
          value={input.value.id}
          onChange={(event) => {
            const group = _.find(choices, { id: parseInt(event.target.value) })
            input.onChange(group ? group : '')
          }}
          inputProps={{ id: `selector-${source}` }}
          disabled={disabled}
        >
          <option value=""></option>
          {_.map(options, (group) => (
            <optgroup key={group.label} label={_.lowerCase(group.label)}>
              {group.items.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </optgroup>
          ))}
        </NativeSelect>
      </FormControl>
    )
  }

  render() {
    const { slugBMAGKind, recordForm, assetTypeOptions } = this.props
    const { GroupSelector } = this
    return (
      <FormDataConsumer form="asset-form">
        {({ formData }) => {
          const myFormData = recordForm || formData
          return (
            <Grid container>
              <Grid item xs={12}>
                <ReferenceInput
                  {...assetTypeOptions}
                  label="Group"
                  source="bMAssetGroup"
                  reference={getResource('digitalAssets', 'brand-market-asset-groups')}
                  validate={[required()]}
                  filter={{ bMAGKind: slugBMAGKind }}
                  sort={{ field: 'section', order: 'ASC' }}
                  fullWidth
                >
                  <GroupSelector fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="url" label="Url" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <RadioButtonGroupInput
                  source="language"
                  defaultValue="nolangauge"
                  choices={[
                    { id: 'nolanguage', name: 'No Language' },
                    { id: 'es', name: 'Spanish' },
                    { id: 'en', name: 'English' },
                  ]}
                />
              </Grid>

              {myFormData && myFormData.bMAssetGroup && (
                <Grid container>
                  <Grid item xs={12}>
                    <AddAssetInput
                      label="Image"
                      accept="image/*"
                      source="asset"
                      fullWidth
                      cropperOptions={{
                        width: myFormData.bMAssetGroup.width,
                        height: myFormData.bMAssetGroup.height,
                        keepSelection: true,
                        imageMinWidth: myFormData.bMAssetGroup.width,
                        imageMinHeight: myFormData.bMAssetGroup.height,
                        crop: {
                          x: 0,
                          y: 0,
                          width: myFormData.bMAssetGroup.width,
                          height: myFormData.bMAssetGroup.height,
                          aspect: myFormData.bMAssetGroup.proportion,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )
        }}
      </FormDataConsumer>
    )
  }
}

export default AssetForm
