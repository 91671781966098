import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import equal from 'fast-deep-equal/es6'
import React, { Component } from 'react'
import { GET_ONE } from 'react-admin'
import { Field } from 'redux-form'

import { getResource } from '../../../config/resources'
import dataProvider from '../../../providers'
import ImageInputEditor from './galleryEditor'

class ImageEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props
    if (value) this.getImage(value)
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props.input ? this.props.input : this.props
    const prevValue = prevProps.input ? prevProps.input.value : prevProps.value

    if (!equal(value, prevValue)) {
      this.getImage(value)
    }
  }

  getImage(value) {
    const { options, getObject, multiselect } = this.props
    const resource = options && options.type ? options.type : 'images'
    if (multiselect) {
      this.setState({
        value: value,
      })
    } else {
      const idImage = value.id ? value.id : value

      if (idImage) {
        return dataProvider(GET_ONE, options && options.resource ? options.resource : getResource('assets', resource), {
          id: value.id ? value.id : value,
        }).then((response) => {
          this.setState(
            {
              value: response.data,
            },
            () => {
              this.onChange(response.data)
            }
          )
        })
      } else {
        this.setState(
          {
            value: idImage,
          },
          () => {
            this.onChange(idImage)
          }
        )
      }
    }
  }

  onChange(item) {
    const { onChange } = this.props.input ? this.props.input : this.props
    const { getObject, multiselect } = this.props

    this.setState({ value: item })
    if (item) {
      const response = getObject || multiselect ? item : item.id
      if (this.props.input) {
        onChange(response)
      } else {
        onChange(item)
      }
    } else {
      onChange(item)
    }
  }

  render() {
    const { label, fullWidth, ...rest } = this.props
    const { meta } = this.props
    return (
      <FormControl error={meta && meta.error && meta.touched} fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <ImageInputEditor {...rest} value={this.state.value} onChange={(item) => this.onChange(item)} />
        {meta && meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )
  }
}

class ImageInput extends Component {
  render() {
    const { source, ...rest } = this.props
    if (source) {
      return <Field {...rest} name={source} component={ImageEditor} />
    } else {
      return <ImageEditor {...this.props} />
    }
  }
}

export default ImageInput

export { ImageEditor }
