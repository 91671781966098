import Grid from '@material-ui/core/Grid'
import React from 'react'
import {
  ArrayField,
  ChipField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  Responsive,
  SelectInput,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
  NumberField,
} from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput } from '../../../components/inputs'
import { getResource } from '../../../config/resources'

const BroadcastersFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput variant="filled" label="Nombre" source="name" alwaysOn fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} alwaysOn>
          <ReferenceInput
            source="market"
            label="Markets"
            filter={{ group: 'oneMarket' }}
            reference={getResource('transversal', 'markets')}
            alwaysOn
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={6}>
          <DateRangeInput label="Start sponsoring" source="startedAt" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DateRangeInput label="End sponsoring" source="endAt" fullWidth />
        </Grid>
      </Grid>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={12} md={6}>
          <ReferenceInput label="Type" source="brandGroup" reference={getResource('digitalAssets', 'brand-groups')} alwaysOn>
            <SelectInput fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ReferenceInput label="Sector" source="brandSector.id" reference={getResource('digitalAssets', 'brand-sectors')} alwaysOn>
            <SelectInput fullWidth />
          </ReferenceInput>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<BroadcastersFilter />} />
}

export const BroadcastersList = (props) => {
  return (
    <List bulkActions={false} title="Operadores" {...props} actions={<Actions />}>
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => (
              <div>
                <div>{record.brandGroup.name}</div>
                <div>{record.brandSector.name}</div>
              </div>
            )}
          />
        }
        medium={
          <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="brandGroup.name" label="Type" sortable={false} />
            <ArrayField source="brandMarkets" label="Markets" sortable={false}>
              <SingleFieldList linkType={false}>
                <ChipField source="market" />
              </SingleFieldList>
            </ArrayField>
            <DateField source="startedAt" label="Start sponsoring" />
            <DateField source="endAt" label="End sponsoring" />
            <TextField source="brandSector.name" label="Sector" sortable={false} />
            <NumberField source="positionFooter" label="Footer Position" />
            <NumberField source="positionPage" label="Page Position" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default BroadcastersList
