import React from 'react'
import { Datagrid, EditButton, List, Responsive, SimpleList, TextField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'

const Actions = (props) => {
  return <ListActions {...props} />
}

export const BeyonStatsHeaderList = (props) => {
  return (
    <List bulkActions={false} actions={<Actions />} {...props} pagination={false}>
      <Responsive
        small={<SimpleList primaryText={(record) => record.title} />}
        medium={
          <Datagrid>
            <TextField source="title" label="Title" />
            <TableRowList>
              <EditButton />
            </TableRowList>
          </Datagrid>
        }
      />
    </List>
  )
}

export default BeyonStatsHeaderList
