import React from 'react'

import Dictionary from './Dictionary'
import DictionaryControlled from './DictionaryControlled'

const DictionaryInput = ({ isControlled, ...rest }) => {
  return isControlled ? <DictionaryControlled {...rest} /> : <Dictionary {...rest} />
}

export default DictionaryInput
