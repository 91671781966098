import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import classnames from 'classnames'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { FieldTitle, translate } from 'ra-core'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import compose from 'recompose/compose'
import _ from 'lodash'

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  root: {},
  suggestionsContainerOpen: {
    position: 'absolute',
    marginBottom: theme.spacing.unit * 3,
    zIndex: 2000,
    '& > div': {
      maxHeight: '200px',
      overflowY: 'auto',
    },
  },
  fetching: {
    width: '15px',
    marginRight: '0px',
  },
  suggestion: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
  },
  suggestionText: { fontWeight: 300 },
  highlightedSuggestionText: { fontWeight: 500 },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
})

export class AutocompleteInput extends React.Component {
  state = {
    dirty: false,
    inputValue: null,
    searchText: '',
    selectedItem: null,
    suggestions: [],
  }

  inputEl = null

  UNSAFE_componentWillMount() {
    const { value } = this.props.input ? this.props.input : this.props
    const selectedItem = this.getSelectedItem(this.props, value)
    this.setState({
      selectedItem,
      inputValue: value,
      searchText: this.getSuggestionText(selectedItem),
      suggestions: this.props.limitChoicesToValue && selectedItem ? [selectedItem] : this.props.choices,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps.input ? nextProps.input : nextProps
    const { choices, limitChoicesToValue } = nextProps
    if (value !== this.state.inputValue) {
      const selectedItem = this.getSelectedItem(nextProps, value)
      this.setState({
        selectedItem,
        inputValue: value,
        searchText: this.getSuggestionText(selectedItem),
        dirty: false,
        suggestions: limitChoicesToValue && selectedItem ? [selectedItem] : this.props.choices,
        prevSuggestions: false,
      })
      // Ensure to reset the filter
      this.updateFilter('')
    } else if (!isEqual(choices, this.props.choices)) {
      const selectedItem = this.getSelectedItem(nextProps, this.state.inputValue)
      this.setState(({ dirty, searchText }) => ({
        isFetching: false,
        selectedItem,
        searchText: dirty ? searchText : this.getSuggestionText(selectedItem),
        suggestions: limitChoicesToValue && !dirty && selectedItem ? [selectedItem] : choices,
      }))
    }
    this.setState({
      isFetching: false,
    })
  }

  getSelectedItem = ({ choices }, inputValue) => {
    const selectedItem = choices && inputValue ? choices.find((choice) => this.getSuggestionValue(choice) == inputValue) : null
    return selectedItem
  }

  getSuggestionValue = (suggestion) => {
    return get(suggestion, this.props.optionValue)
  }

  getSuggestionText = (suggestion) => {
    if (!suggestion) return ''

    const { optionText, translate, translateChoice } = this.props
    const suggestionLabel = typeof optionText === 'function' ? optionText(suggestion) : get(suggestion, optionText)

    // We explicitly call toString here because AutoSuggest expect a string
    return translateChoice ? translate(suggestionLabel, { _: suggestionLabel }).toString() : suggestionLabel.toString()
  }

  handleSuggestionSelected = (event, { suggestion, method }) => {
    const { onChange } = this.props.input ? this.props.input : this.props

    const inputValue = this.getSuggestionValue(suggestion)
    this.setState(
      {
        dirty: false,
        inputValue,
        selectedItem: suggestion,
        searchText: this.getSuggestionText(suggestion),
        suggestions: [suggestion],
      },
      () => onChange && onChange(inputValue)
    )

    if (method === 'enter') {
      event.preventDefault()
    }
  }

  handleSuggestionsFetchRequested = () => {
    this.setState(({ suggestions, prevSuggestions }) => {
      return {
        suggestions: prevSuggestions ? prevSuggestions : suggestions,
        isFetching: this.props.input ? true : false,
      }
    })
  }

  handleSuggestionsClearRequested = () => {
    this.updateFilter('')
  }

  handleMatchSuggestionOrFilter = (inputValue) => {
    //const { choices, inputValueMatcher } = this.props
    const { onChange } = this.props.input ? this.props.input : this.props

    this.setState({
      dirty: true,
      searchText: inputValue,
    })
    //AÑADIDO
    this.updateFilter(inputValue)
    if (_.trim(inputValue).length <= 0) {
      onChange(null)
    }

    /*

    if (matches.length === 1) {
      const match = matches[0]
      const nextId = this.getSuggestionValue(match)

      if (this.state.inputValue !== nextId) {
        this.setState(
          {
            inputValue: nextId,
            searchText: inputValue, // The searchText could be whatever the inputvalue matcher likes, so sanitize it
            selectedItem: match,
            suggestions: [match]
          },
          () => onChange(nextId)
        )
      } else {
        this.setState({
          dirty: false,
          suggestions: [match],
          searchText: inputValue

        })
      }
    } else {
      this.setState({
        dirty: true,
        searchText: inputValue
      })
      this.updateFilter(inputValue)
    }
    */
  }

  handleChange = (event, { newValue, method }) => {
    switch (method) {
      case 'type':
      case 'escape':
        {
          this.handleMatchSuggestionOrFilter(newValue)
        }
        break
    }
  }

  renderInput = (inputProps) => {
    const { input, fullWidth } = this.props
    const {
      autoFocus,
      className,
      classes = {},
      isRequired,
      label,
      meta,
      onChange,
      resource,
      source,
      value,
      ref,
      onKeyUp,
      options: { InputProps, ...options },
      ...other
    } = inputProps

    /*
    if (typeof meta === 'undefined') {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    */

    const {
      touched = false,
      error = '',
      helperText = false,
    } = meta
      ? meta
      : {
          touched: false,
          error: '',
          helperText: false,
        }

    // We need to store the input reference for our Popper element containg the suggestions
    // but Autosuggest also needs this reference (it provides the ref prop)
    const storeInputRef = (input) => {
      this.inputEl = input
      ref(input)
    }
    const { placeholder } = this.props
    return (
      <TextField
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        margin="normal"
        className={classnames(classes.root, className)}
        inputRef={storeInputRef}
        error={meta && touched && error}
        helperText={meta && ((touched && error) || helperText)}
        name={input && input.name}
        fullWidth={fullWidth}
        {...options}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.fetching} position="start">
              <div>{(this.state.isFetching || this.props.isFetching) && <CircularProgress size={15} thickness={4} />}</div>
            </InputAdornment>
          ),
          classes: {
            input: classes.input,
          },
          ...InputProps,
          ...other,
        }}
      />
    )
  }

  renderSuggestionsContainer = (options) => {
    const {
      containerProps: { className, ...containerProps },
      children,
    } = options
    return (
      <Popper className={className} open={!this.props.isFetching} anchorEl={this.inputEl} placement="bottom-start">
        <Paper square {...containerProps}>
          {children}
        </Paper>
      </Popper>
    )
  }

  renderSuggestionComponent = ({ suggestion, query, isHighlighted, ...props }) => <div {...props} />

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const label = this.getSuggestionText(suggestion)
    const matches = match(label, query)
    const parts = parse(label, matches)
    const { classes = {}, suggestionComponent } = this.props

    return (
      <MenuItem
        selected={isHighlighted}
        component={suggestionComponent || this.renderSuggestionComponent}
        suggestion={suggestion}
        query={query}
        isHighlighted={isHighlighted}
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} className={classes.highlightedSuggestionText}>
                {part.text}
              </span>
            ) : (
              <strong key={index} className={classes.suggestionText}>
                {part.text}
              </strong>
            )
          })}
        </div>
      </MenuItem>
    )
  }

  handleBlur = () => {
    const { dirty, searchText, selectedItem } = this.state
    const { onBlur } = this.props.input ? this.props.input : this.props

    const { allowEmpty } = this.props
    if (dirty) {
      if (searchText === '' && allowEmpty) {
        onBlur && onBlur(null)
      } else {
        onBlur && onBlur(this.state.inputValue)
        this.setState({
          dirty: false,
          searchText: this.getSuggestionText(selectedItem),
          suggestions: this.props.limitChoicesToValue && selectedItem ? [selectedItem] : this.props.choices,
        })
      }
    } else {
      onBlur && onBlur(this.state.inputValue)
    }
  }

  handleFocus = () => {
    const { onFocus } = this.props.input ? this.props.input : this.props
    onFocus && onFocus()
  }

  updateFilter = (value) => {
    const { setFilter, choices } = this.props
    if (this.previousFilterValue !== value) {
      if (setFilter) {
        setFilter(value)
      } else {
        this.setState({
          suggestions: choices.filter((choice) => this.getSuggestionText(choice).toLowerCase().includes(value.toLowerCase())),
        })
      }
    }
    this.previousFilterValue = value
  }

  shouldRenderSuggestions = (value) => {
    return value.length >= 2 && (!this.state.selectedItem || (this.state.selectedItem && this.state.selectedItem.name !== value))
  }

  handleKeyUp = (event) => {
    const { onChange, allowAnyText } = this.props.input ? this.props.input : this.props
    const code = event.which
    const inputValue = event.target.value

    if (allowAnyText && inputValue.trim().length > 0) {
      if (code == 13) {
        event.preventDefault()
        this.setState(
          {
            dirty: false,
            inputValue,
            selectedItem: inputValue,
            searchText: inputValue,
            suggestions: [],
          },
          () => onChange && onChange(inputValue)
        )
      }
    }
  }

  render() {
    const { alwaysRenderSuggestions, classes = {}, isRequired, label, meta, resource, source, className, options } = this.props
    const { suggestions, searchText } = this.state

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        onSuggestionSelected={this.handleSuggestionSelected}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionText}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={{
          className,
          classes,
          isRequired,
          label,
          meta,
          onChange: this.handleChange,
          resource,
          source,
          value: searchText,
          onBlur: this.handleBlur,
          onFocus: this.handleFocus,
          onKeyUp: this.handleKeyUp,
          options,
        }}
      />
    )
  }
}

AutocompleteInput.propTypes = {
  allowEmpty: PropTypes.bool,
  alwaysRenderSuggestions: PropTypes.bool, // used only for unit tests
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  InputProps: PropTypes.object,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  limitChoicesToValue: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  setFilter: PropTypes.func,
  source: PropTypes.string,
  suggestionComponent: PropTypes.func,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired,
  inputValueMatcher: PropTypes.func,
}

AutocompleteInput.defaultProps = {
  choices: [],
  options: {},
  optionText: 'name',
  optionValue: 'id',
  limitChoicesToValue: false,
  translateChoice: true,
  inputValueMatcher: (input, suggestion, getOptionText) => getOptionText(suggestion).toLowerCase().trim().includes(input.toLowerCase().trim()),
}

export default compose(translate, withStyles(styles))(AutocompleteInput)
