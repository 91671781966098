import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React, { Component } from 'react'
import { CloneButton, Datagrid, EditButton, Filter, List, NumberField, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField } from '../../../components/field'
import { DateRangeInput } from '../../../components/inputs'
import PreviewButton from './previewbutton'
import { StateStyled } from './styles'

const ImagesFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="name" label="Name" alwaysOn />
        </Grid>
        <Grid container alwaysOn>
          <Grid item xs={12} sm={12} md={6}>
            <DateRangeInput label="Start at" source="startAt" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DateRangeInput label="End at" source="endAt" fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<ImagesFilter />} />
}

const State = (props) => {
  const { record } = props
  const { endAt, startAt } = record
  const isActive = moment().isBetween(moment(startAt).tz('Europe/Madrid').format('YYYY-MM-DD'), moment(endAt).tz('Europe/Madrid').format('YYYY-MM-DD'))
  return <StateStyled active={isActive}>{isActive ? 'Active' : 'Expired'}</StateStyled>
}

export const BroadcastList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Layouts" {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => record.section} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="section" label="Section" />
          <NumberField source="priority" label="Priority" />
          <State label="State" />
          <DateField label="Start at" source="startAt" />
          <DateField label="End at" source="endAt" />
          <TableRowList>
            <EditButton />
            <CloneButton />
            <PreviewButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default BroadcastList
