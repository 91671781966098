import React from 'react'
import { Edit } from 'react-admin'

import MatchesForecastForm from './form'

export const MatchesForecastEdit = (props) => (
  <Edit undoable={false} {...props}>
    <MatchesForecastForm {...props} />
  </Edit>
)

export default MatchesForecastEdit
