import React, { Component } from 'react'
import { Field } from 'redux-form'
import LangSelector from './langSelector'

class LanguageInput extends Component {
  render() {
    if (this.props.source) {
      return <Field label={this.props.label} component={LangSelector} name={this.props.source} />
    } else {
      return <LangSelector {...this.props} />
    }
  }
}

export default LanguageInput
