import React, { Component } from 'react'
import { Field } from 'redux-form'

import ImageInputEditor from './addAssetInput'

class AddAssetInput extends Component {
  render() {
    if (this.props.source) {
      return <Field {...this.props} label={this.props.label} component={ImageInputEditor} name={this.props.source} />
    } else {
      return <ImageInputEditor {...this.props} />
    }
  }
}

export default AddAssetInput
