import React from 'react'
import { BooleanInput, DisabledInput, FormDataConsumer, maxLength, required, TextInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { DictionaryInput, ImageInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import CompetitionManagement from './competitionManagement'
import SubgroupManagementInput from './competitionManagement/SubgroupManagementInput'

class CompetitionsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubgroupsManagement: false,
    }
  }

  render() {
    const { isEdit, ...props } = this.props
    return (
      <LanguagesNav {...props}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput fullWidth source="name" label="Nombre" validate={[required(), maxLength(255)]} />
            <DisabledInput fullWidth source="nameConfiguration" label="Nombre Configuración" />
            <DisabledInput fullWidth source="slug" label="slug" />
            <Grid item container>
              <Grid item lg={3}>
                <ImageInput source="image" label="Logo competition App (selector)" accept="image/*" validate={[required()]} />
              </Grid>
              <Grid item lg={3}>
                <ImageInput source="secondaryImage" label="Logo competition App (top bar)" accept="image/*" validate={[required()]} />
              </Grid>
              <Grid item lg={3}>
                <ImageInput source="thirdImage" label="Logo competition App (match details)" accept="image/*" validate={[required()]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="detailsApp" label="Details App" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="importTsv" label="Import Tsv" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="tagging" label="Tagging" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="showSelector" label="Mostrar en el selector" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="showInPublic" label="Mostrar en la parte pública" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="showInCalendar" label="Show In Calendar" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="showInApp" label="Show In App" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BooleanInput source="mainCompetition" label="Main Competition" />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source="watermark" label="Watermark" />
            <FormDataConsumer>
              {({ formData, ...rest }) => formData && formData.watermark && <ImageInput source="watermarkImage" label="Watermark" accept="image/*" />}
            </FormDataConsumer>
          </Grid>
          {isEdit && (
            <Grid item xs={12}>
              <CompetitionManagement idCompetition={props.id} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper>
              <DictionaryInput label="Dictionary" sources={['performId', 'ldeId', 'optaId', 'managerId']} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SubgroupManagementInput label="qualify" source="qualify" />
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default CompetitionsForm
