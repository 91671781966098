import styled from 'styled-components'
import { colors } from '../../../config/theme'

export const ChipListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: 5px;
  }
`

export const ContainerStyled = styled.div`
  border: solid 1px ${colors.grey[400]};
  background-color: ${colors.common.white};
  padding: 10px;
  margin-bottom: 10px;
`

export const ContentStyled = styled.div`
  padding: 10px;
  border: solid 1px ${colors.grey[400]};
  margin-top: 10px;
  margin-bottom: 0px;
  background-color: ${colors.grey[200]};
`

export const HeaderStyled = styled.div`
  display: flex;
`

export const LabelStyled = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  padding: 0px 19px;
  color: ${colors.grey[700]};
`

export const AddButtonStyled = styled.div`
  margin: 10px;
`

export const GrowStyled = styled.div`
  flex-grow: 1;
`
export const ValueSelectorStyled = styled.div`
  display: flex;
  min-width: 100%;
`

export const FetchingStyled = styled.div``
