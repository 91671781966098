import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { Component } from 'react'
import { required, BooleanInput } from 'react-admin'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import ReferenceSelectInput from '../../referenceSelectInput'

import { getResource } from '../../../../config/resources'

class MatchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: null,
      perPage: 50,
    }
  }

  resetSelects(formName, field, StringInputValue) {
    const { source, filterForm, onChange } = this.props
    const season = 'season'
    const competition = 'competition'
    const round = 'round'
    const subscription = 'subscription'
    const gameweek = 'gameweek'
    const match = 'match'
    const value = 'value'
    const inputValue = parseInt(_.get(StringInputValue, 'id'))

    switch (field) {
      case 'all':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [season, competition, round, subscription, gameweek, match, value]))
        break
      case 'seasons':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [round, subscription, gameweek, match, value]))
        break
      case 'competition':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [round, subscription, gameweek, match, value]))
        break
      case 'subscription':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [round, gameweek, match, value]))
        break
      case 'round':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [gameweek, match, value]))
        break
      case 'gameweek':
        this.props.changeField(formName, source, _.omit(_.get(filterForm, source), [match, value]))
        break
      case 'match':
        if (inputValue) this.props.changeField(formName, source + '.' + value, StringInputValue.slug)
        onChange(_.get(filterForm, source))
        break
      default:
    }
  }

  render() {
    const { source, changeField } = this.props
    const season = source + 'season'
    const competition = source + 'competition'
    const round = source + 'round'
    const subscription = source + 'subscription'
    const gameweek = source + 'gameweek'
    const match = source + 'match'

    const formName = 'modalConfig-form'
    const form = this.props.filterForm

    if (source === null) return null
    if (!form) return null
    return (
      <div>
        <Grid container>
          <Grid item xs={6}>
            <BooleanInput label="Match slug" source={source + 'active'} onChange={() => this.resetSelects(formName, 'all')} />
          </Grid>
        </Grid>
        {_.get(form, source + 'active') && (
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <ReferenceSelectInput
                allowEmpty
                alwaysOn
                onChange={(inputValue, item) => this.resetSelects(formName, 'seasons', inputValue, item)}
                label="Season"
                source={season}
                reference={getResource('competiciones', 'seasons')}
                perPage={this.state.perPage}
                validate={this.props.validate}
                optionText="name"
                value={_.get(form, season)}
                filter={{ showInWeb: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <ReferenceSelectInput
                allowEmpty
                alwaysOn
                onChange={(inputValue) => this.resetSelects(formName, 'competition', inputValue)}
                label="Competition"
                source={competition}
                reference={getResource('competiciones', 'competitions')}
                perPage={this.state.perPage}
                sort={{ field: 'id', order: 'ASC' }}
                validate={this.props.validate}
                optionText="name"
                value={_.get(form, competition)}
              />
            </Grid>
            {subscription && _.get(form, competition) && _.get(form, season) && (
              <Grid item xs={12} sm={6} md={4}>
                <ReferenceSelectInput
                  allowEmpty
                  alwaysOn
                  disabled={!(subscription && _.get(form, competition) && _.get(form, season))}
                  onChange={(inputValue) => this.resetSelects(formName, 'subscription', inputValue)}
                  label="Subscription"
                  source={subscription}
                  reference={getResource('competiciones', 'subscriptions')}
                  filter={{ competition: _.get(form, competition), season: _.get(form, season) }}
                  perPage={this.state.perPage}
                  validate={this.props.validate}
                  optionText="competitionName"
                  value={_.get(form, subscription)}
                />
              </Grid>
            )}
            {competition && _.get(form, competition) && _.get(form, season) && _.get(form, subscription) && (
              <Grid item xs={12} sm={6} md={4}>
                <ReferenceSelectInput
                  allowEmpty
                  alwaysOn
                  disabled={!(competition && _.get(form, competition) && _.get(form, season) && _.get(form, subscription))}
                  onChange={(inputValue) => this.resetSelects(formName, 'round', inputValue)}
                  label="Round"
                  source={round}
                  reference={getResource('competiciones', 'rounds')}
                  filter={{ competition: _.get(form, competition), season: _.get(form, season) }}
                  perPage={this.state.perPage}
                  validate={this.props.validate}
                  optionText="name"
                  value={_.get(form, round)}
                />
              </Grid>
            )}
            {gameweek && _.get(form, competition) && _.get(form, season) && _.get(form, subscription) && _.get(form, round) && (
              <Grid item xs={12} sm={6} md={4}>
                <ReferenceSelectInput
                  allowEmpty
                  alwaysOn
                  disabled={!(gameweek && _.get(form, competition) && _.get(form, season) && _.get(form, subscription) && _.get(form, round))}
                  onChange={(inputValue) => this.resetSelects(formName, 'gameweek', inputValue)}
                  label="Game week"
                  source={gameweek}
                  reference={getResource('competiciones', 'gameweeks')}
                  filter={{ round: _.get(form, round) }}
                  perPage={this.state.perPage}
                  validate={this.props.validate}
                  optionText="name"
                  value={_.get(form, gameweek)}
                />
              </Grid>
            )}
            {match && _.get(form, competition) && _.get(form, season) && _.get(form, subscription) && _.get(form, round) && _.get(form, gameweek) && (
              <Grid item xs={12} sm={12} md={12}>
                <ReferenceSelectInput
                  allowEmpty
                  alwaysOn
                  disabled={
                    !(match && _.get(form, competition) && _.get(form, season) && _.get(form, subscription) && _.get(form, round) && _.get(form, gameweek))
                  }
                  options={{
                    onChange: (inputValue) => this.resetSelects(formName, 'match', inputValue),
                  }}
                  label="Match"
                  // validate={[required()]}
                  source={match}
                  reference={getResource('competiciones', 'matches')}
                  filter={{ gameweek: _.get(form, gameweek) }}
                  perPage={this.state.perPage}
                  validate={this.props.validate}
                  optionText="name"
                  value={_.get(form, match)}
                />
              </Grid>
            )}
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    filterForm: state.form['modalConfig-form'].values,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (form, field, value) => dispatch(change(form, field, value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchInput)
