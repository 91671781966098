import React from 'react'
import { Datagrid, EditButton, Filter, List, Responsive, SimpleList, TextField, TextInput } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'

const PagesFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="q" label="Search" alwaysOn fullWidth />
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<PagesFilter />} />
}

export const PagestList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Layouts" {...props}>
    <Responsive
      small={<SimpleList primaryText={(record) => record.name} secondaryText={(record) => record.slug} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Name" />
          <TextField source="slug" label="Slug" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default PagestList
