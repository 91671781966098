import React from 'react'
import { Create } from 'react-admin'

import RulesForm from './form'

const RulesTitle = ({ record }) => <span>Nueva difusión:</span>

export const RulesCreate = (props) => (
  <Create title={<RulesTitle />} {...props}>
    <RulesForm {...props} />
  </Create>
)

export default RulesCreate
