import { Button, InputLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { Component } from 'react'
import { fetchEnd, fetchStart, FormDataConsumer, GET_LIST, GET_ONE, REDUX_FORM_NAME, required, showNotification } from 'react-admin'
import { change } from 'redux-form'

import { ConfirmationDialog, SubForm } from '../../../../../components'
import { MatchInput } from '../../../../../components/inputs/'
import { getResource } from '../../../../../config/resources'
import { CURRENT_SEASON, ID_PRIMERA_DIVISION, ID_PRIMERA_DIVISION_FEMENINA, ID_SEGUNDA_DIVISION } from '../../../../../config/variables'
import dataProvider from '../../../../../providers'
import { competitions } from '../../../../competiciones/utils/constants'
import { slugifyFiler } from '../../../../../config/utils'

const styles = (theme) => {
  return {
    label: {
      fontSize: '14px',
    },
    buttonsContainer: {
      display: 'flex',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    button: {
      opacity: 0.9,
      '& + button': {
        marginLeft: '10px',
      },
      '&:hover': {
        opacity: 1,
      },
    },
  }
}

class MagicGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDialog: false,
      buttonActive: true,
      utilInfo: null,
      initialMatchInputData: null,
    }
  }

  closeDialog = () => this.setState({ showDialog: false, buttonActive: true })

  getCompetitionData(competition) {
    const { dispatch } = this.props
    let currentSubscription, currentRound, currentGameweek

    dispatch(fetchStart())
    dataProvider(GET_LIST, getResource('competiciones', 'subscriptions'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 100 },
      filter: { competition, season: CURRENT_SEASON },
    })
      .then(({ data }) => {
        currentSubscription = data[0].id
        currentGameweek = _.get(data[0], 'currentGameweek.id')
        currentRound = _.get(data[0], 'currentGameweek.round.id')

        this.setState({
          initialMatchInputData: {
            season: CURRENT_SEASON,
            competition: competition,
            subscription: currentSubscription,
            round: currentRound,
            gameweek: currentGameweek,
          },
          showDialog: true,
        })
      })
      .catch((error) => {
        dispatch(showNotification(error.message, 'error'))
      })
      .finally(() => {
        dispatch(fetchEnd())
      })
  }

  convertCompetitionDataToMetadata(formData) {
    const { type, ...competitionData } = formData

    let keys = Object.keys(competitionData)
    let values = Object.values(competitionData)
    let metadatas = []

    for (let i = 0; i < keys.length; i++) {
      metadatas.push({ type: keys[i], value: values[i] })
    }

    return metadatas
  }

  setButtonActive(buttonActive) {
    this.setState({
      buttonActive,
    })
  }

  getImages = async (formData, images = [], page = 1) => {
    const { dispatch, source } = this.props
    const { utilInfo } = this.state

    dispatch(fetchStart())
    this.setButtonActive(false)
    if (!formData.match) {
      dispatch(showNotification('You must select a match', 'error'))
    } else {
      let { images: newImages, total } = await dataProvider(GET_LIST, getResource('assets', 'images'), {
        sort: {
          field: 'id',
          order: 'DESC',
        },
        pagination: {
          page,
          perPage: 100,
        },
        filter: {
          metadatas: this.convertCompetitionDataToMetadata(formData),
        },
      })
        .then(({ data, total }) => {
          return {
            images: data.map((item, key) => ({
              position: key,
              content: item,
            })),
            total,
          }
        })
        .catch((error) => {
          dispatch(showNotification(error.message, 'error'))
        })
      images = [...images, ...newImages]

      if (total > images.length) {
        images = await this.getImages(formData, images, page + 1)
      } else {
        if (images.length > 0) {
          let awayTeam, homeTeam, competition
          await dataProvider(GET_ONE, getResource('competiciones', 'matches'), {
            id: formData.match,
          }).then(({ data }) => {
            competition = data.competition.name
            awayTeam = data.awayTeam.nickname
            homeTeam = data.homeTeam.nickname
          })

          let category
          await dataProvider(GET_ONE, getResource('assets', 'gallerycategories'), {
            id: 1,
          }).then(({ data }) => {
            category = data
          })

          let gameweek
          await dataProvider(GET_ONE, getResource('competiciones', 'gameweeks'), {
            id: formData.gameweek,
          }).then(({ data }) => {
            gameweek = data
          })

          let name = `Galería ${homeTeam} - ${awayTeam} ${gameweek.shortname} ${competition}`
          dispatch(change(REDUX_FORM_NAME, 'name', name))
          dispatch(change(REDUX_FORM_NAME, 'slug', slugifyFiler(name)))
          dispatch(change(REDUX_FORM_NAME, 'description', `Las mejores imagenes del ${homeTeam} - ${awayTeam} de la ${gameweek.name} de ${competition}`))
          dispatch(change(REDUX_FORM_NAME, 'state', 'draft'))
          dispatch(change(REDUX_FORM_NAME, 'category', category))
          dispatch(change(REDUX_FORM_NAME, source, images))
          dispatch(change(REDUX_FORM_NAME, 'metadatas', this.convertCompetitionDataToMetadata(formData)))

          this.closeDialog()
        } else {
          dispatch(showNotification('There are no images with this metadata', 'error'))
        }
      }
    }
    this.setButtonActive(true)
    dispatch(fetchEnd())
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <InputLabel className={classes.label}>Create automatic gallery</InputLabel>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              this.getCompetitionData(ID_PRIMERA_DIVISION)
            }}
          >
            {competitions.laligaSantander.name}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              this.getCompetitionData(ID_SEGUNDA_DIVISION)
            }}
          >
            {competitions.laliga123.name}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              this.getCompetitionData(ID_PRIMERA_DIVISION_FEMENINA)
            }}
          >
            {competitions.ligaFemenina.name}
          </Button>
        </div>
        <ConfirmationDialog title="Select the metadata" open={this.state.showDialog} maxWidth="md" dialogActions={false} onCancel={this.closeDialog}>
          <SubForm
            form="magicgallery-form"
            record={this.state.initialMatchInputData}
            onClose={this.closeDialog}
            toolbar={
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData) {
                    return (
                      <Button
                        className={classes.button}
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={!this.state.buttonActive}
                        onClick={() => {
                          this.getImages(formData)
                        }}
                      >
                        Load images
                      </Button>
                    )
                  }
                }}
              </FormDataConsumer>
            }
          >
            <>
              <MatchInput
                disabledSeason
                disabledCompetition
                disabledSubscription
                sourceSeason="season"
                sourceCompetition="competition"
                sourceSubscription="subscription"
                sourceRound="round"
                sourceGameweek="gameweek"
                sourceMatch="match"
                validate={[required()]}
              />
            </>
          </SubForm>
        </ConfirmationDialog>
      </>
    )
  }
}

export default withStyles(styles)(MagicGallery)
