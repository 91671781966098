import React from 'react'
import { ListItem, Grid, ListItemText } from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'

class MovableItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      checked: this.props.item.active,
    }
  }

  render() {
    const { item, isDragged } = this.props

    const active = item.showInApp || item.active

    return (
      <Grid item xs={12}>
        <ListItem style={{ backgroundColor: 'white', width: '100%' }}>
          <ListItemText primary={item.name} secondary={active ? 'active' : 'inactive'} />
          <Grid item xs={1} data-movable-handle style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
            <DragHandleIcon />
          </Grid>
        </ListItem>
      </Grid>
    )
  }
}

export default MovableItem
