import React from 'react'
import { Edit } from 'react-admin'

import PromisesForm from './form'

export const PromisesEdit = (props) => (
  <Edit undoable={false} {...props}>
    <PromisesForm isEdit={true} {...props} />
  </Edit>
)

export default PromisesEdit
