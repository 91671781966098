import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Datagrid, EditButton, Filter, List, ReferenceField, Responsive, SimpleList, TextField, TextInput, FunctionField } from 'react-admin'

import { ListActions, TableRowList } from '../../../components'
import { DateField, ImageField } from '../../../components/field'
import { getResource } from '../../../config/resources'

const AmbassadorsFilter = (props) => {
  return (
    <Filter {...props}>
      <Grid container alwaysOn>
        <Grid item xs={12}>
          <TextInput source="name" label="Name" alwaysOn fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}

const Actions = (props) => {
  return <ListActions {...props} filters={<AmbassadorsFilter />} />
}

export const AmbassadorsList = (props) => (
  <List bulkActions={false} actions={<Actions />} title="Ambassadors" {...props}>
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => (
            <ReferenceField
              source="photo"
              reference={getResource('assets', 'images')}
              basePath={getResource('assets', 'images')}
              linkType={false}
              record={record}
              allowEmpty
            >
              <ImageField sizes={['small', 'medium', 'large', 'xlarge']} />
            </ReferenceField>
          )}
          primaryText={(record) => record.name}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField label="Image" source="photo" reference={getResource('assets', 'images')} linkType={false} sortable={false} allowEmpty>
            <ImageField sizes={['small', 'medium', 'large', 'xlarge']} />
          </ReferenceField>
          <TextField label="Name" source="name" fullWidth />
          <FunctionField label="Active" render={(record) => (record.active ? 'Yes' : 'No')} />
          <DateField label="Created At" source="createdAt" />
          <TableRowList>
            <EditButton />
          </TableRowList>
        </Datagrid>
      }
    />
  </List>
)

export default AmbassadorsList
