import Grid from '@material-ui/core/Grid'
import React, { Fragment } from 'react'
import { BooleanInput, FormDataConsumer, LongTextInput, maxLength, NumberInput, REDUX_FORM_NAME, required, SelectInput, TextInput } from 'react-admin'
import { change } from 'redux-form'

import { ClipboardField } from '../../../../components/formFields'
import { CategoryInput, ClientUuidsInput, DateTimeInput, ImageInput, LanguageInput, MarketsInput, MetadataInput } from '../../../../components/inputs'
import { checkboxRequired } from '../../../../components/inputs/clientUuidsInput/validate'
import { getResource } from '../../../../config/resources'
import { choicesStates } from '../commons/states'
import { slugifyFiler } from './../../../../config/utils'
import UploadVideo from './UploadVideo'

const basePublicUrl = {
  es: `${process.env.REACT_APP_PUBLIC_FRONT}/videos/`,
  en: `${process.env.REACT_APP_PUBLIC_FRONT}/en-ES/videos/`,
}

const basePremiumUrl = {
  es: `${process.env.REACT_APP_PUBLIC_FRONT}/videos/exclusivo/`,
  en: `${process.env.REACT_APP_PUBLIC_FRONT}/en-ES/videos/exclusive/`,
}

const generateSlug = (value, form, dispatch) => {
  dispatch(change(form, 'slug', slugifyFiler(value.name)))
}

const changeIsLive = (form, dispatch) => {
  dispatch(change(form, 'startedAt', null))
  dispatch(change(form, 'duration', null))
}

export const Form = ({ recordForm, form = 'record-form', dir, isPremium, ...props }) => {
  return (
    <FormDataConsumer form={form}>
      {({ formData, dispatch }) => {
        const myFormData = recordForm || formData
        const myForm = form || REDUX_FORM_NAME

        let urlClipboard = ''
        if (myFormData) {
          if (isPremium) {
            urlClipboard = `${basePremiumUrl[myFormData.lang ? myFormData.lang : 'es']}${myFormData.slug}`
          } else {
            urlClipboard = `${basePublicUrl[myFormData.lang ? myFormData.lang : 'es']}${myFormData.slug}`
          }
        }

        return (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <LanguageInput source="videoLanguages" />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ClientUuidsInput key={myFormData?.lang || 'es'} label="Clients" source="clientUuids" isEdit={props.isEdit} validate={[checkboxRequired()]} />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MarketsInput label="Market" source="markets" select />
              </Grid>
            </Grid>
            <div dir={dir} style={{ width: '100%' }}>
              <Grid item xs={12}>
                <TextInput fullWidth label="Orientation" source="orientation" disabled />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <DateTimeInput wullWidth label="Published At" source="publishedAt" date time />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ImageInput source="imageVideo" label="image" title="imagen" getObject fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      fullWidth
                      label="Name"
                      source="name"
                      validate={[required(), maxLength(255)]}
                      onChange={() => {
                        if (myFormData.slugAutogenerate) {
                          generateSlug(myFormData, myForm, dispatch)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BooleanInput
                      label="Autogenerate slug"
                      source="slugAutogenerate"
                      onChange={(value) => {
                        if (value.target.checked) {
                          generateSlug(myFormData, myForm, dispatch)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      disabled={myFormData ? myFormData.slugAutogenerate : false}
                      fullWidth
                      label="Slug"
                      source="slug"
                      validate={[required(), maxLength(255)]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                {myFormData && (
                  <Grid item xs={12}>
                    <ClipboardField urlShortener label="Url" value={urlClipboard} fullWidth />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <LongTextInput source="description" label="Description" validate={[required()]} fullWidth />
                </Grid>
              </Grid>
            </div>

            <UploadVideo myForm={myForm} myFormData={myFormData} dispatch={dispatch} isPremium={isPremium} />

            <Grid container>
              <Grid item xs={12}>
                <BooleanInput
                  label="Is live"
                  source="isLive"
                  onChange={() => {
                    changeIsLive(myForm, dispatch)
                  }}
                />
              </Grid>
              {myFormData && myFormData.isLive && (
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <DateTimeInput wullWidth label="Started At" source="startedAt" date time validate={[required()]} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <NumberInput label="Duration (min)" source="duration" validate={[required()]} />
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <SelectInput source="state" disabled={myFormData && !myFormData.id} defaultValue="draft" validate={[required()]} choices={choicesStates} />
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <BooleanInput source="isForApp" label="Is For App" />
              </Grid>
              <CategoryInput label="Category" source="category" resource={getResource('assets', 'videocategories')} validate={[required()]} />
              <Grid item xs={12} sm={12} md={12}>
                <MetadataInput source="metadatas" label="Metadata" />
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }}
    </FormDataConsumer>
  )
}

export default Form
