import React from 'react'
import { Edit } from 'react-admin'
import PageForm from '../../pages/pages/form'

export const PageEdit = (props) => (
  <Edit undoable={false} {...props}>
    <PageForm {...props} buttonBack={false} />
  </Edit>
)

export default PageEdit
