import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import _ from 'lodash'
import React, { Component } from 'react'
import { Field } from 'redux-form'

import AddPlugin from './addPlugin'
import DndList from './dndList'
import DndItem from './dndList/dndItem'
import { ContainerHeaderStyled, DndContainerStyled, GrowStyled } from './style'
import equal from 'fast-deep-equal/es6'

export class ComponentDnDBlockEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
    this.idBase = 0
  }

  static defaultProps = {
    id: '1',
  }

  componentDidMount() {
    const { value } = this.props.input ? this.props.input : this.props.children
    const OrderValue = _.orderBy(value, ['position'], ['asc'])
    this.setState({
      items: OrderValue,
    })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props.input ? this.props.input : this.props.children
    const { value: prevValue } = prevProps.input ? prevProps.input : prevProps.children

    if (!equal(value, prevValue)) {
      this.setState({ items: value })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps.input ? nextProps.input : nextProps
    if (value.length > 0 && prevState.items.length <= 0) {
      const OrderValue = _.orderBy(value, ['position'], ['asc'])
      return {
        items: OrderValue,
      }
    }
  }

  getItem(id, plugin, value) {
    const el = {
      id: id,
      plugin: plugin,
      ...value,
    }
    return el
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const items = this.reorder(this.state.items, source, destination, draggableId)

    this.setState({ items: items }, () => {
      this.props.input.onChange(this.state.items)
    })
  }

  reorder(list, source, destination, draggableId) {
    const result = Array.from(list)
    const [removed] = result.splice(source.index, 1)
    result.splice(destination.index, 0, removed)

    return this.reorderPosition(result)
  }

  reorderPosition(list) {
    var newList = list
    _.map(newList, (item, index) => {
      newList[index].position = index
    })
    return newList
  }

  onClickMenu(item) {
    const { options } = this.props
    const reverse = options && options.reverse ? options.reverse : false
    const newList = _.cloneDeep(this.state.items)
    this.idBase++

    if (reverse) {
      newList.push(this.getItem('plugin-' + this.idBase, item, null))
    } else {
      newList.unshift(this.getItem('plugin-' + this.idBase, item, null))
    }
    const limit = options ? options.limit : null
    if (limit && limit.type === 'fifo' && this.state.items.length >= limit.numItems) {
      if (reverse) {
        newList.shift()
      } else {
        newList.splice(-1, 1)
      }
    }

    this.setState({
      items: this.reorderPosition(newList),
    })
  }

  handleChange(value) {
    let newValue = []
    _.map(value, (item, index) => {
      let newItem = item
      newItem.position = index
      newValue.push(newItem)
    })
    this.setState({ items: newValue }, () => {
      this.props.input.onChange(this.state.items)
    })
  }

  handleChangePlugin(value, index) {
    let newList = [...this.state.items]
    newList[index] = { ...newList[index], ...value }
    this.setState(
      {
        items: newList,
      },
      () => {
        this.props.input.onChange(this.state.items)
      }
    )
  }

  handleDelete(index) {
    const newList = [...this.state.items]
    newList.splice(index, 1)
    this.setState({ items: newList })
    this.props.input.onChange(newList)
  }

  handlehighlight(index) {
    const list = [...this.state.items]
    let newList = []
    list.map((item) => {
      let newItem = item
      newItem.prominent = false
      newList.push(newItem)
      return null
    })
    newList[index].prominent = true
    this.setState({ items: newList }, () => {
      this.props.input.onChange(this.state.items)
    })
  }

  render() {
    const { label, meta, input, plugins, id, options } = this.props
    const source = input ? input.name : null
    const limit = options ? options.limit : null
    const reverse = options ? options.reverse : false
    const txtInfoFIFO = reverse ? 'First element will be removed' : 'Last element will be removed'

    return (
      <FormControl fullWidth error={meta && meta.touched && meta.error}>
        <DndContainerStyled>
          <ContainerHeaderStyled>
            <InputLabel>{label ? label : source}</InputLabel>
            <GrowStyled />
          </ContainerHeaderStyled>
          {plugins && (
            <AddPlugin
              disabled={limit && !limit.type && this.state.items.length >= limit.numItems}
              info={limit && limit.type === 'fifo' && this.state.items.length >= limit.numItems ? txtInfoFIFO : ''}
              plugins={plugins}
              onClick={(item) => this.onClickMenu(item)}
              option={options}
              items={this.state.items}
            />
          )}
          <DndList id={`${this.idBase}-${id}`} list={this.state.items} onChange={(list) => this.handleChange(list)} onDragEnd={this.onDragEnd}>
            {this.state.items &&
              this.state.items.map((item, index) => {
                const Plugin = plugins[item.plugin].plugin
                return (
                  <DndItem
                    {...item}
                    highlightBlock={() => this.handlehighlight(index)}
                    deteleBlock={() => this.handleDelete(index)}
                    index={index}
                    name={plugins[item.plugin].options.name}
                    key={index}
                  >
                    <Plugin
                      id={item.id}
                      options={plugins[item.plugin].options}
                      value={item}
                      onChange={(value) => this.handleChangePlugin(value, index)}
                      meta={this.props.meta}
                      source={source}
                    />
                  </DndItem>
                )
              })}
          </DndList>
        </DndContainerStyled>
        {meta && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )
  }
}

const DndBlockEditor = ({ ...props }) => {
  return <Field {...props} component={ComponentDnDBlockEditor} name={props.name} />
}

export default DndBlockEditor
