import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles, Button, Paper } from '@material-ui/core'

import React, { Component } from 'react'
import { GET_LIST, fetchEnd, fetchStart, showNotification, SimpleForm, ReferenceField, TextField } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { change, formValueSelector, initialize, isSubmitting } from 'redux-form'

import dataProvider from '../../../../providers'
import { getResource } from '../../../../config/resources'
import { MatchField } from '../../../../components/field'
import { MatchInput } from '../../../../components/inputs'
import { PATCH } from '../../../../providers/dataProvider/types'

const FORM = 'brand-day-form'
const selector = formValueSelector(FORM)

const styles = (theme) => ({
  form: {
    width: '100%',
    '& > div:first-child': {
      padding: '0px',
    },
    '& > div': {
      minWidth: '100%',
      boxSizing: 'border-box',
    },
  },
  matchPaper: {
    marginBottom: '15px',
    display: 'flex',
  },
  addMatch: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnTrash: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      background: theme.palette.error.light,
    },
  },
})

class BrandDayMatches extends Component {
  constructor(props) {
    super(props)

    this.getMatches = this.getMatches.bind(this)
    this.handleAddMatch = this.handleAddMatch.bind(this)
    this.handleDeleteMatch = this.handleDeleteMatch.bind(this)
  }
  componentDidMount() {
    this.getMatches()
  }

  getMatches() {
    const { fetchEnd, fetchStart, change } = this.props
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'matches'), {
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 0, perPage: 50 },
      filter: { isBrandDay: true },
    })
      .then(({ data }) => {
        change(FORM, 'brandDayMatches', data)
      })
      .finally(() => {
        fetchEnd()
      })
  }

  handleAddMatch() {
    const { field } = this.props
    const idMatch = field('add.match')
    if (idMatch) {
      this.updateMatch(idMatch, true)
    }
  }

  handleDeleteMatch(idMatch) {
    if (idMatch) {
      this.updateMatch(idMatch, false)
    }
  }

  updateMatch(idMatch, isBrandDay) {
    const { change, fetchStart, fetchEnd, showNotification } = this.props

    fetchStart()
    dataProvider(PATCH, getResource('competiciones', 'matches'), {
      id: idMatch,
      data: {
        isBrandDay: isBrandDay,
      },
    })
      .then(() => {
        this.getMatches()
        if (isBrandDay) {
          change(FORM, 'add', {})
        }
        showNotification('Match BrandDay updated', 'success')
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  render() {
    const { classes, field } = this.props
    const brandDayMatches = field('brandDayMatches')

    return (
      <SimpleForm form={FORM} toolbar={false} className={classes.form}>
        <h2>Brand Day Matches</h2>
        <div className={classes.addMatch}>
          <MatchInput
            sourceSeason="add.season"
            sourceCompetition="add.competition"
            sourceSubscription="add.subscription"
            sourceRound="add.round"
            sourceGameweek="add.gameweek"
            sourceMatch="add.match"
          />
          <div>
            <Button disabled={!field('add.match')} variant="contained" size="small" color="primary" onClick={this.handleAddMatch}>
              <AddIcon />
            </Button>
          </div>
        </div>
        {brandDayMatches &&
          brandDayMatches.map((match, index) => {
            return (
              <Paper className={classes.matchPaper} fullWidth key={index}>
                <div>
                  <ReferenceField
                    source={`brandDayMatches[${index}].subscription.id`}
                    reference={getResource('competiciones', 'subscriptions')}
                    basePath={getResource('competiciones', 'subscriptions')}
                    linkType={false}
                    record={{ brandDayMatches: brandDayMatches }}
                  >
                    <TextField source="season.name" />
                  </ReferenceField>
                  <TextField source={`brandDayMatches[${index}].competition.name`} record={{ brandDayMatches: brandDayMatches }} />
                </div>
                <MatchField
                  baseSource={`brandDayMatches[${index}].`}
                  record={{ brandDayMatches: brandDayMatches }}
                  basePath={getResource('assets', 'images')}
                />
                <div>
                  <Button className={classes.btnTrash} variant="fab" mini onClick={() => this.handleDeleteMatch(match.id)}>
                    <DeleteIcon />
                  </Button>
                </div>
              </Paper>
            )
          })}
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      field: (source) => selector(state, source),
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(BrandDayMatches)
