import React from 'react'
import { Create } from 'react-admin'

import EventsForm from './form'

export const EventsCreate = (props) => (
  <Create {...props}>
    <EventsForm {...props} />
  </Create>
)

export default EventsCreate
