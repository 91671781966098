import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { BooleanInput, FormDataConsumer, GET_LIST, LongTextInput, maxLength, NumberInput, ReferenceInput, required, SelectInput, TextInput } from 'react-admin'

import { AutocompleteInput, DateSingleInput, DateTimeInput, ImageInput, MarketsInput, RichTextInput } from '../../../../components/inputs'
import LanguagesNav from '../../../../components/languagesNav'
import { getResource } from '../../../../config/resources'
import dataProvider from '../../../../providers'
import BMAssetInput from './BMAssetInput'
import Sponsorings from './Sponsorings'
import { BackgroundGreyStyled, LabelStyled } from './styles'

const styles = (theme) => ({
  label: {
    display: 'block',
    marginBottom: '10px',
    transform: 'translate( -12.5%, 0px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
})

export class BrandForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabActive: 0,
      tabActiveAssets: 0,
      tabActiveUrl: 0,

      bMAGkinds: [],
      bMAGkindActive: 0,
    }
  }

  componentDidMount() {
    dataProvider(GET_LIST, getResource('digitalAssets', 'brand-market-asset-group-kinds'), {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {},
    })
      .then(({ data }) => {
        this.setState({
          bMAGkinds: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {})
  }

  render() {
    const { isEdit, ...props } = this.props
    const { tabActive, tabActiveAssets, tabActiveUrl, bMAGkinds, bMAGkindActive } = this.state

    return (
      <LanguagesNav {...props}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <MarketsInput allowAdd fullWidth label="Markets" source="brandMarkets" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ReferenceInput label="Type" source="brandGroup.id" reference={getResource('digitalAssets', 'brand-groups')} validate={[required()]}>
              <SelectInput fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} sm={4}>
            <ReferenceInput label="Sector" source="brandSector.id" reference={getResource('digitalAssets', 'brand-sectors')} validate={[required()]}>
              <AutocompleteInput fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <TextInput label="Name" source="name" fullWidth />
          </Grid>

          <Grid item xs={12}>
            <BackgroundGreyStyled>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DateTimeInput label="Inicio del patrocinio" source="startedAt" date time />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimeInput label="Fin del patrocinio" source="endAt" date time />
                </Grid>
                <Grid item xs={12}>
                  <LabelStyled>Fecha de creación</LabelStyled>
                  <DateSingleInput label={false} source="createdAt" disabled={true} />
                </Grid>
              </Grid>
            </BackgroundGreyStyled>
          </Grid>
          <Grid item xs={12}>
            <LabelStyled>Sponsorings</LabelStyled>
            <BackgroundGreyStyled>{isEdit && <Sponsorings idBrand={props.id} />}</BackgroundGreyStyled>
          </Grid>

          <Grid item xs={12}>
            <LabelStyled>Assets</LabelStyled>

            <Tabs
              value={bMAGkindActive}
              onChange={(event, newValue) => this.setState({ bMAGkindActive: newValue })}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {bMAGkinds.map((eachType) => (
                <Tab label={eachType.name} key={eachType.id} />
              ))}
            </Tabs>
            {bMAGkinds.length > 0 && (
              <BackgroundGreyStyled>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    if (!formData) return null
                    if (!formData.brandMarkets) return null
                    return (
                      <Grid container>
                        <Grid item xs={12}>
                          <Tabs
                            value={tabActiveAssets}
                            onChange={(event, newValue) => this.setState({ tabActiveAssets: newValue })}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            {formData.brandMarkets.map((item, key) => (
                              <Tab label={item.market} key={key} />
                            ))}
                          </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                          {formData.brandMarkets[tabActiveAssets] && (
                            <Paper>
                              {!formData.brandMarkets[tabActiveAssets].id && (
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="body1">You must save to add assets to this market.</Typography>
                                  </Grid>
                                </Grid>
                              )}
                              {formData.brandMarkets[tabActiveAssets].id && (
                                <BMAssetInput idBrandMarket={formData.brandMarkets[tabActiveAssets].id} slugBMAGKind={bMAGkinds[bMAGkindActive].slug} />
                              )}
                            </Paper>
                          )}
                        </Grid>
                      </Grid>
                    )
                  }}
                </FormDataConsumer>
              </BackgroundGreyStyled>
            )}
          </Grid>

          <Grid item xs={12}>
            <LabelStyled>Url</LabelStyled>
            <BackgroundGreyStyled>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (!formData) return null
                  if (!formData.brandMarkets) return null
                  return (
                    <Grid container>
                      <Grid item xs={12}>
                        <Tabs
                          value={tabActiveUrl}
                          onChange={(event, newValue) => this.setState({ tabActiveUrl: newValue })}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {_.map(formData.brandMarkets, (item, key) => (
                            <Tab label={item.market} key={key} />
                          ))}
                        </Tabs>
                      </Grid>
                      <Grid item xs={12}>
                        {formData.brandMarkets[tabActiveUrl] && (
                          <Paper>
                            <LongTextInput label={false} source={`brandMarkets.${tabActiveUrl}.url`} validate={[maxLength(1000)]} />
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  )
                }}
              </FormDataConsumer>
            </BackgroundGreyStyled>
          </Grid>

          <Grid item xs={12}>
            <LabelStyled>Footer</LabelStyled>
            <BackgroundGreyStyled>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (!formData) return null
                  return (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <BooleanInput label="Show In Footer" source="showInFooter" />
                      </Grid>
                      {formData.showInFooter && (
                        <Fragment>
                          <Grid item xs={12} sm={6}>
                            <NumberInput label="Position" source="positionFooter" validate={[required()]} fullWidth />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <ImageInput
                              source="imageFooter"
                              label="Image Color"
                              title="imagen"
                              options={{
                                remove: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <ImageInput
                              source="imageHover"
                              label="Image Black"
                              title="imagen"
                              options={{
                                remove: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextInput source="brandMarkets[0].footerName" label="Footer Name" />
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  )
                }}
              </FormDataConsumer>
            </BackgroundGreyStyled>
          </Grid>

          <Grid item xs={12}>
            <LabelStyled>Página patrocinadores</LabelStyled>
            <BackgroundGreyStyled>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (!formData) return null
                  return (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <BooleanInput label="Show In Page" source="showInPage" />
                      </Grid>
                      {formData.showInPage && (
                        <Fragment>
                          <Grid item xs={12} sm={6}>
                            <NumberInput label="Position" source="positionPage" validate={[required()]} fullWidth />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <ImageInput
                              source="imagePage"
                              label="Image"
                              title="imagen"
                              options={{
                                remove: true,
                              }}
                            />
                          </Grid>
                        </Fragment>
                      )}
                      <Grid item xs={12}>
                        {formData.brandMarkets && (
                          <Grid container>
                            <Grid item xs={12}>
                              <Tabs
                                value={tabActive}
                                onChange={(event, newValue) => this.setState({ tabActive: newValue })}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                {_.map(formData.brandMarkets, (item, key) => (
                                  <Tab label={item.market} key={key} />
                                ))}
                              </Tabs>
                            </Grid>
                            <Grid item xs={12}>
                              {formData.brandMarkets[tabActive] && (
                                <>
                                  <label className={props.classes.label}>Description</label>
                                  <div style={{ backgroundColor: '#F5F5F5' }}>
                                    <RichTextInput source={`brandMarkets.${tabActive}.description`} />
                                  </div>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                }}
              </FormDataConsumer>
            </BackgroundGreyStyled>
          </Grid>
        </Grid>
      </LanguagesNav>
    )
  }
}

export default withStyles(styles)(BrandForm)
