import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { ContainerStanding, ItemTableStyled, ItemTdPosition, ItemTdName, ItemTdInput } from './style'
import { DndList } from '../../../../components/inputs'
import { fetchStart, fetchEnd, required, showNotification, SimpleForm, NumberInput, GET_LIST } from 'react-admin'
import { initialize, isSubmitting, change } from 'redux-form'
import { getResource } from '../../../../config/resources'
import { POST_MANY } from '../../../../providers/dataProvider/types'
import dataProvider from '../../../../providers'

const FORM = 'record-form'

const styles = (theme) => ({
  arrayInput: {},
  simpleFormIterator: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
})

class StandingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupSelector: 0,
    }
  }

  componentDidMount() {
    const { record } = this.props
    const filter = {
      round: record.id,
    }
    if (record.groups) filter.aggregate = record.aggregates[this.state.groupSelector].id

    this.getStanding(filter)
  }

  changePositions(items, standingOthers) {
    let standingOthersOrdered = _.cloneDeep(standingOthers)
    _.map(items, (item, index) => {
      standingOthersOrdered[item.positionSource].position = index + 1
    })
    standingOthersOrdered = _.orderBy(standingOthersOrdered, 'position', 'asc')
    this.props.initialize('record-form', { standingOthers: standingOthersOrdered })
  }

  StandingForm = ({ standingOthers }) => {
    const { genuine } = this.props

    if (standingOthers && standingOthers.length > 0) {
      const inputs = _.map(standingOthers, (item, index) => {
        return {
          id: item.id,
          positionSource: index,

          content: (
            <ItemTableStyled key={item.id}>
              <ItemTdPosition>{item.position}</ItemTdPosition>
              <ItemTdName>{item.team.name}</ItemTdName>
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].points`} label="Puntos" fullWidth validate={required()} />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].played`} label="Partidos jugados" fullWidth validate={required()} />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].won`} label="Partidos ganadas" fullWidth validate={required()} />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].drawn`} label="Partidos empatados" fullWidth validate={required()} />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].lost`} label="Partidos perdidos" fullWidth validate={required()} />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput
                  source={`standingOthers[${index}].goalsFor`}
                  label={genuine ? 'Partes ganadas' : 'Goles a favor'}
                  fullWidth
                  validate={required()}
                />
              </ItemTdInput>
              <ItemTdInput>
                <NumberInput
                  source={`standingOthers[${index}].goalsAgainst`}
                  label={genuine ? 'Partes empatadas' : 'Goles en contra'}
                  fullWidth
                  validate={required()}
                />
              </ItemTdInput>
              {genuine && (
                <Fragment>
                  <ItemTdInput>
                    <NumberInput source={`standingOthers[${index}].lostHalf`} label="Partes perdidas" fullWidth validate={required()} />
                  </ItemTdInput>
                  <ItemTdInput>
                    <NumberInput source={`standingOthers[${index}].fairplay`} label="Puntos Fairplay" fullWidth validate={required()} />
                  </ItemTdInput>
                </Fragment>
              )}
              <ItemTdInput>
                <NumberInput source={`standingOthers[${index}].fine`} label="Fine" fullWidth validate={required()} />
              </ItemTdInput>
            </ItemTableStyled>
          ),
        }
      })
      return (
        <ContainerStanding>
          <ItemTableStyled className="head">
            <ItemTdPosition className="head"></ItemTdPosition>
            <ItemTdName className="head">
              <Tooltip title="Equipo" placement="top">
                <span>Equipo</span>
              </Tooltip>
            </ItemTdName>
            <ItemTdInput className="head">
              <Tooltip title="Puntos" placement="top">
                <span>Puntos</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title="Partidos jugados" placement="top">
                <span>PJ</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title="Partidos ganados" placement="top">
                <span>PG</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title="Partidos empatados" placement="top">
                <span>PE</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title="Partidos perdidos" placement="top">
                <span>PP</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title={genuine ? 'Partes ganadas' : 'Goles a favor'} placement="top">
                <span>{genuine ? 'PTG' : 'GF'}</span>
              </Tooltip>
            </ItemTdInput>
            <ItemTdInput className="head">
              <Tooltip title={genuine ? 'Partes empatadas' : 'Goles en contra'} placement="top">
                <span>{genuine ? 'PTE' : 'GC'}</span>
              </Tooltip>
            </ItemTdInput>
            {genuine && (
              <Fragment>
                <ItemTdInput className="head">
                  <Tooltip title="Partes perdidas" placement="top">
                    <span>PTP</span>
                  </Tooltip>
                </ItemTdInput>
                <ItemTdInput className="head">
                  <Tooltip title="Puntos Fairplay" placement="top">
                    <span>PFP</span>
                  </Tooltip>
                </ItemTdInput>
              </Fragment>
            )}
            <ItemTdInput className="head">
              <Tooltip title="Fine" placement="top">
                <span>Fine</span>
              </Tooltip>
            </ItemTdInput>
          </ItemTableStyled>
          <DndList onChange={(items) => this.changePositions(items, standingOthers)} list={inputs} />
        </ContainerStanding>
      )
    }
    return null
  }

  getStanding(filter) {
    const { fetchStart, fetchEnd, showNotification } = this.props
    fetchStart()
    dataProvider(GET_LIST, getResource('competiciones', 'standingOthers'), {
      sort: {
        field: 'position',
        order: 'ASC',
      },
      pagination: { page: 1, perPage: 50 },
      filter: { ...filter },
    })
      .then(({ data }) => {
        const newData = {
          standingOthers: data,
        }
        this.props.initialize(FORM, newData)
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  gupdateStanding(data) {
    const { fetchStart, fetchEnd, showNotification } = this.props
    fetchStart()
    dataProvider(POST_MANY, getResource('competiciones', 'otherstandings/multiples'), {
      data: {
        arrayData: data,
      },
    })
      .then((response) => {
        showNotification('Save success', 'success')
      })
      .catch((error) => {
        showNotification('Format field error', 'warning')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  handleSaveClick() {
    const { submit, fetchStart } = this.props
    fetchStart()
    submit('standing-others')
  }

  renderTabs() {
    const tabs = _.map(this.props.record.aggregates, (group) => {
      return <Tab label={`Group ${group.name}`} />
    })
    return (
      <Tabs
        fullWidth
        indicatorColor="primary"
        value={this.state.groupSelector}
        onChange={(event, value) => {
          this.setState({ groupSelector: value }, () => {
            this.getStanding({
              round: this.props.record.id,
              aggregate: this.props.record.aggregates[value].id,
            })
          })
        }}
      >
        {tabs}
      </Tabs>
    )
  }

  render() {
    const { isSubmitting, standingOthers } = this.props
    const { StandingForm } = this

    return (
      <div>
        <SimpleForm
          resource={getResource('competiciones', 'standingOthers')}
          saving={isSubmitting}
          save={() => this.gupdateStanding(standingOthers ? standingOthers.values.standingOthers : null)}
        >
          {this.props.record.groups && this.renderTabs()}
          {standingOthers && standingOthers.values && <StandingForm standingOthers={standingOthers.values.standingOthers} />}
        </SimpleForm>
      </div>
    )
  }
}

const enhance = compose(
  connect(
    (state) => ({
      isSubmitting: isSubmitting(FORM)(state),
      standingOthers: state.form[FORM],
    }),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
      initialize: (form, array) => dispatch(initialize(form, array)),
      change: (form, field, value) => dispatch(change(form, field, value)),
    })
  ),
  withStyles(styles)
)

export default enhance(StandingForm)
