import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { UPDATE, DELETE, GET_ONE, fetchEnd, fetchStart, showNotification } from 'react-admin'
import { getResource } from '../../../../../../config/resources'
import { dataProvider } from '../../../../../../providers'
import { ConfirmationDialog } from '../../../../../../components'
import { RowTeamsStyled, ListTeamsStyled, ControlsTeamsStyled } from '../styles'
import { DictionaryInput } from '../../../../../../components/inputs'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LeftIcon from '@material-ui/icons/ArrowBack'
import RightIcon from '@material-ui/icons/ArrowForward'

import { Typography } from '@material-ui/core'

const styles = (theme) => {
  return {
    buttonPanel: {
      textAlign: 'right',
      '& button': {
        marginLeft: '10px',
      },
    },
    trashIcon: {
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.error.dark,
        cursor: 'pointer',
      },
    },
    deleteButtom: {
      background: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      '&:hover': {
        background: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
      },
    },
  }
}

class Group extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      subscriptionTeams: null,
      addTeam: [],
      deleteTeam: [],
      dialogOpen: false,
    }
  }

  componentDidMount() {
    let { data, idSubscription } = this.props
    this.getSubscriptionsTeams(idSubscription)
    this.setState({
      data: data,
    })
  }

  getSubscriptionsTeams(idSubscription) {
    dataProvider(GET_ONE, getResource('competiciones', 'subscriptions'), {
      id: idSubscription,
    }).then((response) => {
      let subscriptionTeams = _.orderBy(response.data.subscriptionTeams, ['team.nickname'], ['asc'])
      this.setState({
        subscriptionTeams: subscriptionTeams,
      })
    })
  }

  handleChangeInput = (name) => (event) => {
    let data = this.state.data
    data[name] = event.target.value
    this.setState({
      data: data,
    })
  }

  handleChangeDictionaries(values) {
    let newData = { ...this.state.data, ...values }

    this.setState({
      data: newData,
    })
  }

  handleClickSaveGroup() {
    const { fetchStart, fetchEnd, showNotification } = this.props
    const { data } = this.state

    if (data.name && data.letter) {
      fetchStart()
      dataProvider(UPDATE, getResource('competiciones', 'aggregates'), {
        id: data.id,
        data: data,
      })
        .then((response) => {
          this.setState({
            data: response.data,
          })
          this.props.closeGroup()
          showNotification('Elements updated', 'success')
        })
        .catch((error) => {
          showNotification(error.message, 'error')
        })
        .finally(() => {
          fetchEnd()
        })
    }
  }

  deleteGroup() {
    const { fetchEnd, fetchStart, showNotification } = this.props
    let { data } = this.state

    fetchStart()
    dataProvider(DELETE, getResource('competiciones', 'aggregates'), {
      id: data.id,
    })
      .then((response) => {
        this.props.closeGroup()
        showNotification('Element deleted', 'success')
        return null
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  handleClickTeam(type, idTeam) {
    let teams = this.state[type]
    if (teams.includes(idTeam)) {
      teams.splice(teams.indexOf(idTeam), 1)
    } else {
      teams.push(idTeam)
    }
    this.setState({
      [type]: teams,
    })
  }

  handleClickAddTeams() {
    let { data, addTeam, subscriptionTeams } = this.state

    if (addTeam.length > 0) {
      addTeam.map((item) => {
        let each = subscriptionTeams[_.findIndex(subscriptionTeams, ['team.id', item])]
        let team = {
          team: {
            id: each.team.id,
            nickname: each.team.nickname,
          },
        }
        data.aggregateTeams.push(team)
        return null
      })
      this.setState({
        data: data,
        addTeam: [],
      })
    }
  }

  handleClickDeleteTeams() {
    let { data, deleteTeam } = this.state

    if (deleteTeam.length > 0) {
      deleteTeam.map((item) => {
        let index = _.findIndex(data.aggregateTeams, ['team.id', item])
        data.aggregateTeams.splice(index, 1)
        return null
      })
      this.setState({
        data: data,
        deleteTeam: [],
      })
    }
  }

  render() {
    const { classes } = this.props
    const { data, subscriptionTeams } = this.state

    if (data !== null) {
      return (
        <Fragment>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                id="name"
                label="Name *"
                fullWidth={true}
                error={data.name ? false : true}
                value={data.name}
                onChange={this.handleChangeInput('name')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="letter"
                label="Letter *"
                fullWidth={true}
                error={data.letter ? false : true}
                value={data.letter}
                onChange={this.handleChangeInput('letter')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField id="slug" label="Slug" fullWidth={true} disabled={true} value={data.slug} />
            </Grid>
            <Grid item xs={12}>
              <DictionaryInput data={data} sources={['performId']} onChange={(values) => this.handleChangeDictionaries(values)} isControlled />
            </Grid>
            <Grid item xs={12}>
              <Typography>Teams</Typography>

              <RowTeamsStyled>
                <ListTeamsStyled>
                  {subscriptionTeams &&
                    subscriptionTeams.map((item, key) => {
                      if (_.findIndex(data.aggregateTeams, ['team.id', item.team.id]) === -1)
                        return (
                          <div
                            key={key}
                            onClick={() => this.handleClickTeam('addTeam', item.team.id)}
                            className={this.state.addTeam.includes(item.team.id) ? 'selected' : ''}
                          >
                            {item.team.nickname}
                          </div>
                        )
                      return null
                    })}
                </ListTeamsStyled>
                <ControlsTeamsStyled>
                  <Button
                    className={classes.deleteButtom}
                    onClick={() => this.handleClickAddTeams()}
                    variant="contained"
                    size="small"
                    disabled={this.state.addTeam.length === 0}
                    aria-label="Add"
                  >
                    <RightIcon />
                  </Button>
                  <Button
                    onClick={() => this.handleClickDeleteTeams()}
                    variant="contained"
                    size="small"
                    disabled={this.state.deleteTeam.length === 0}
                    aria-label="Less"
                  >
                    <LeftIcon />
                  </Button>
                </ControlsTeamsStyled>
                <ListTeamsStyled>
                  {data.aggregateTeams.map((item, key) => {
                    return (
                      <div
                        key={key}
                        onClick={() => this.handleClickTeam('deleteTeam', item.team.id)}
                        className={this.state.deleteTeam.includes(item.team.id) ? 'selected' : ''}
                      >
                        {item.team.nickname}
                      </div>
                    )
                  })}
                </ListTeamsStyled>
              </RowTeamsStyled>
            </Grid>
            <Grid item xs={12} className={classes.buttonPanel}>
              <Button
                className={classes.deleteButtom}
                onClick={() => this.setState({ dialogOpen: true })}
                variant="contained"
                color="secondary"
                size="small"
                aria-label="Save"
              >
                Delete
              </Button>
              <Button onClick={() => this.handleClickSaveGroup()} variant="contained" color="primary" size="small" aria-label="Save">
                Save
              </Button>
            </Grid>
          </Grid>
          <ConfirmationDialog
            open={this.state.dialogOpen}
            title="Are you sure?"
            onCancel={() => {
              this.setState({
                dialogOpen: false,
              })
            }}
            onSuccess={() => {
              this.deleteGroup()
            }}
          />
        </Fragment>
      )
    } else {
      return false
    }
  }
}

const enhance = compose(
  connect(
    (state) => ({}),
    (dispatch) => ({
      fetchEnd: () => dispatch(fetchEnd()),
      fetchStart: () => dispatch(fetchStart()),
      showNotification: (msg, error) => dispatch(showNotification(msg, error)),
    })
  ),
  withStyles(styles)
)
export default enhance(Group)
